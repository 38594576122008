.competitor-callout {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 100px;

  .what-is-hash {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 30%;
    background: black;
    color: white;
    padding: 50px;

    h3 {
      font-size: 26px;
      margin: 0;

      .what-is-header {
        font-weight: 900;
      }

      .what-is-subheader {
        font-weight: 400;
        color: rgb(99, 97, 102);
        line-height: $leading-snug;
      }
    }

    p {
      font-size: $font-sm;
      line-height: $leading-snug;
      margin-bottom: 0;
    }
  }

  .hash-key-features {
    width: 40%;
    background: $blue11;
    padding: 30px 35px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .key-feature {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .feature-no {
      font-weight: 900;
      color: #6de5ff;
      font-size: 26px;
      width: 40px;
    }

    p {
      color: white;
      margin: 0 0 0 15px;
      font-size: $font-sm;
      line-height: $leading-snug;
    }
  }

  .free-for-life {
    width: 30%;
    background: #eaeef0;
    color: black;
    padding: 50px;

    .free-header {
      display: flex;
      align-items: center;
      margin-top: 0;
      margin-bottom: 18px;
    }

    .rainbow-free {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        linear-gradient(
          90deg,
          #ff0500 2.26%,
          #fbbb2a 20.14%,
          #2ab967 36.58%,
          #509bff 53.39%,
          #8b79ff 70.9%
        );
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 42px;
      font-weight: 900;
      box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.2);
    }

    span {
      margin-left: 10px;
    }

    > div {
      font-weight: 300;
      font-size: $font-sm;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    a {
      color: black;
      font-weight: 600;
      transition: color 0.2s;

      &:hover {
        color: $blue11;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .what-is-hash {
      width: 50%;
      order: 1;
    }

    .free-for-life {
      width: 50%;
      order: 2;
    }

    .hash-key-features {
      width: 100%;
      order: 3;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 80px;

    .what-is-hash,
    .free-for-life {
      width: 100%;
      padding: 40px;
    }
  }

  @include media-breakpoint-down(xs) {
    &.container {
      padding: 0;
    }

    .what-is-hash,
    .free-for-life,
    .hash-key-features {
      padding: 30px 25px;
    }

    .hash-key-features .key-feature:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}
