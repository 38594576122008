.project-dataset-preview {
  margin-bottom: 50px;
  max-width: calc(100vw - 30px);
  width: 100%;

  .dataset-preview pre {
    background: #2d2d2d;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .data-table-container {
    max-height: 400px;

    table {
      margin-bottom: 1px;
      border-bottom: none;
    }
  }

  .dropdown-wrapper,
  pre {
    max-width: 80%;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  .dropdown-wrapper {
    margin-bottom: 20px;
  }

  .dataset-preview {
    margin-bottom: 15px;
  }

  .disclaimer {
    padding-left: 5px;
    font-style: italic;
    font-size: $font-xs;
    font-weight: 300;
  }

  table {
    position: relative;
  }

  pre:after,
  table:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100px;
  }

  pre:after {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #2d2d2a 80%);
  }

  table:after {
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.3),
      #ffffff 100%
    );
    height: 25%;
  }

  .loading-indicator {
    margin-top: 40px;
  }

  pre {
    margin: 40px 0;
  }
}
