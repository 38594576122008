p {
  &.light {
    color: $gray11;
    font-weight: 600;
  }
  &.uppercase {
    text-transform: uppercase;
  }
}

input,
textarea {
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  padding-left: 0;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  outline: none;
  &::-ms-clear {
    display: none;
  }
}

.color-primary {
  color: $color-primary;
}

p,
li {
  line-height: $leading-base;
  font-weight: 300;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
  font-weight: 200;
}

h3 {
  font-size: 1.5rem;
  margin: 1.5rem 0;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
  text-transform: uppercase;
}

pre {
  margin: 20px 0;
  padding: 15px;
  overflow: auto;

  &:not([class*="language-"]) {
    background-color: $gray3;
  }
}

code {
  white-space: pre-wrap;
  background: $gray4;
  padding: 0.1em 0.3em;
  border-radius: 4px;
  font-size: 85%;
}

a {
  color: inherit;
  text-decoration: inherit;
  &:hover {
    color: inherit;
    text-decoration: inherit;
  }
}

blockquote {
  border-left: 0.3em solid $gray14;
  margin-left: 0px;
  padding-left: 28px;
}

b,
strong {
  font-weight: 600;
}

a .dataSet.name.text {
  text-decoration: none;
  font-size: 1rem;
  &:hover {
    color: $gray12;
  }
}

.wp-block-table {
  width: 100%;
  &.bold-col-1 {
    tbody > tr:not(:first-child) {
      :first-child {
        font-weight: 600;
      }
    }
  }
}

.mainsite-header {
  padding: 80px 0;
  background-color: $gray2;
  color: black;

  p {
    margin-bottom: 5px;
  }

  h1 {
    margin-top: 0;
  }

  h1,
  h2 {
    margin-bottom: 20px;
  }

  @include media-breakpoint-down(xs) {
    padding: 30px 0 40px 0;

    h1 {
      margin-bottom: 0;

      &.heading-1 {
        font-size: 46px;
      }
    }

    h2 {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }

  &.with-breadcrumb h1 {
    margin-top: 5px;
  }

  .mainsite-breadcrumb {
    font-size: $font-sm;
    text-transform: uppercase;
    margin-top: 30px;
    transition: opacity 0.2s;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .mainsite-breadcrumb-item {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(xs) {
        &:not(:last-child) {
          display: none;
        }
      }
    }

    a {
      opacity: 0.8;
    }

    svg {
      margin-bottom: -2px;
    }

    span {
      color: #ccc;
    }

    a:hover {
      opacity: 1;
    }
  }

  .mainsite-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    h1 {
      margin-bottom: 0;
      margin-right: 40px;
      line-height: $leading-tight;
    }

    p {
      margin-top: 10px;
      margin-bottom: 0;
    }

    @include media-breakpoint-down(md) {
      p {
        width: 100%;
      }
      h1 {
        margin-right: 0;
      }
    }

    @include media-breakpoint-down(xs) {
      p {
        margin-bottom: 0;
      }
    }
  }

  .title-with-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    button {
      font-size: $font-sm;
      height: 50px;
      font-weight: 300;
      padding: 0 20px;

      @include media-breakpoint-down(lg) {
        margin-top: 15px;
      }

      span {
        font-weight: 600;
      }

      svg {
        margin-left: 10px;

        path {
          fill: white;
        }
      }

      &:not(.engine) svg {
        height: 24px;
        width: 24px;
      }

      &.engine svg {
        height: 17px;
        width: 17px;
        margin-bottom: 3px;
      }
    }
  }

  .breadcrumbs {
    text-transform: uppercase;
    font-weight: 600;

    a {
      transition: opacity 0.3s;
      &:hover {
        opacity: 0.75;
      }
    }

    > span {
      opacity: 0.75;
      a:hover {
        opacity: 0.5;
      }
    }
  }
}

// Animations

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.alert-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

@keyframes click_circle {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(0);
  }
}

.anim-bounce {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  .circle {
    z-index: -5;
    position: absolute;
    left: -50%;
    top: -50%;
    height: 200%;
    width: 200%;
    background-color: #f1f2f5;
    opacity: 0.5;
    border-radius: 50%;
    display: inline-block;
    transition: all 0.15s;
    transform: scale(0);
  }
  &:hover {
    cursor: pointer;
    .circle {
      transform: scale(1);
    }
  }
  &.clicked {
    &:hover {
      .circle {
        transform: scale(0);
      }
    }
    .circle {
      animation-name: click_circle;
      animation-duration: 0.3s;
    }
  }
}

.message {
  position: absolute;
}

.message-enter {
  opacity: 0.01;
}

.message-enter.message-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.message-leave {
  opacity: 1;
}

.message-leave.message-leave-active {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}

@keyframes popup {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.popup-enter {
  transform: scale(0);
  transform-origin: top right;
  @include media-breakpoint-down(sm) {
    transform-origin: 0% center;
  }
}

.popup-enter.popup-enter-active {
  animation-name: popup;
  animation-duration: 0.3s;
  transform-origin: top right;
}

.popup-leave {
  transform: scale(1);
  transform-origin: top right;
  @include media-breakpoint-down(sm) {
    transform-origin: 0% center;
  }
}

.popup-leave.popup-leave-active {
  transform: scale(0);
  transition: all 100ms ease-in;
  transform-origin: top right;
}

// Wordpress

// Main

button,
input[type="submit"] {
  white-space: nowrap;
  height: max-content;
  padding: 1rem;

  &.narrow {
    padding: 0.5rem 1rem;
  }
  transition: all 0.3s;

  &.white.transparent {
    color: $white;
    background-color: transparent;
    border-color: $white;
    &:hover {
      background-color: $white;
      color: $color-primary;
      transition: all 0.3s;
    }
  }
  color: $white;
  background-color: $color-primary;
  border: 0.1rem solid $color-primary;
  &:hover {
    background-color: $white;
    color: $color-primary;
    transition: all 0.3s;
  }
  border-radius: 0.25rem;
  &:focus {
    outline: 0;
  }
  &:not(:disabled):hover {
    cursor: pointer;
  }
  &.image {
    display: flex;
    align-items: center;
    img {
      width: 1rem;
      margin-right: 0.5rem;
      &.left-arrow {
        transform: rotate(180deg);
      }
    }
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.svg {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      width: 1rem;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      &.left-arrow {
        transform: rotate(180deg);
      }
      fill: $white;
      transition: fill 0.2s;
    }
    &:hover {
      svg {
        fill: $color-primary;
        transition: fill 0.2s;
      }
    }
  }
  .text,
  p {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
  }

  &.red {
    background-color: $hot-pink;
    border-color: $hot-pink;
    color: $white;
    &:hover {
      color: $white;
      background-color: $hot-pink-hover;
      border-color: $hot-pink-hover;
    }
  }

  &.grey {
    color: $white;
    background-color: #b5b5b5;
    border-color: #b5b5b5;
    &:hover {
      background-color: #d0d0d0;
      border-color: #d0d0d0;
    }
  }

  &.small {
    > span {
      font-weight: 600;
      font-size: $font-xs;
    }

    padding: 0.75rem 0.5rem;
    svg {
      margin: 0 0 0 0.5rem;
      position: relative;
      top: -1px;
    }

    border-width: 0;
  }

  &.purple {
    background-color: $purple10;
    border-color: $purple10;

    &:hover {
      background-color: $purple9;
      border-color: $purple9;
      color: $white;
      svg {
        fill: $white;
      }
    }
  }

  &.green {
    color: $white;
    background-color: $green;
    border-color: $green;
    &:hover {
      background-color: $green-hover;
      border-color: $green-hover;
    }
  }

  &.unclickable {
    color: $white;
    background-color: #d0d0d0;
    border-color: #d0d0d0;
    &:hover {
      background-color: #d0d0d0;
      border-color: #d0d0d0;
      cursor: unset;
    }
  }
}

body {
  font-family: Inter, Helvetica, sans-serif;
  overflow-y: scroll;
}

.static-content-container {
  padding: 60px 0;

  @include media-breakpoint-down(xs) {
    padding-top: 30px;
  }
}

input {
  &:focus {
    outline: 0;
  }
}

.margin-bottom {
  margin-bottom: 4rem;
  @include media-breakpoint-down(sm) {
    margin-bottom: 2.5rem;
  }
}

.margin-top {
  margin-top: 4rem;
  @include media-breakpoint-down(sm) {
    margin-top: 2.5rem;
  }
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.flex {
  display: flex;

  &.space-between {
    justify-content: space-between;
  }
  &.align-center {
    align-items: center;
  }
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  align-items: center;
}

.relative {
  position: relative;
}

.page-content {
  padding-top: 5rem;
  padding-bottom: 5rem;
  @include media-breakpoint-down(sm) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  > :first-child > div > :first-child {
    margin-top: 0;
    > :first-child {
      margin-top: 0;
    }
  }
}

ul.check-list {
  list-style: none;
  padding-left: 0;

  li {
    padding-bottom: 0.5rem;
    line-height: $leading-base;
    padding-left: calc(1rem + 18px);
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 18px;
      height: 10px;
      background-image: url(/assets/check-green.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      top: 7px;
      left: 0;
    }

    > span {
      font-size: 0.8rem;
      font-weight: 800;
      text-transform: uppercase;
    }
  }
}

.top-bg-image {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
