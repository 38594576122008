.job-listing-page {
  .mainsite-header {
    h4 {
      text-transform: uppercase;
      font-weight: 300;
      margin: 0;
    }
    h1 {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }
  .white-container-wrapper {
    background-color: $white;
    border-top: 0.05rem solid #f3efef;
    box-shadow: 0 -1rem 1rem rgba(0, 0, 0, 0.05);
    margin-top: 3rem;
    padding-top: 2rem;
    padding-bottom: 4rem;
    @include media-breakpoint-down(md) {
      padding-bottom: 2rem;
    }
  }
  .content {
    @include media-breakpoint-up(lg) {
      display: flex;
      margin-left: calc((100% - 1140px) / 2);
      @include media-breakpoint-down(lg) {
        margin-left: calc((100% - 950px) / 2);
      }
    }
    @include media-breakpoint-down(md) {
      padding-top: 2rem;
    }
    ul.requirements {
      list-style: none;
      padding-left: 0;
      li {
        padding-bottom: 0.5rem;
        display: flex;
        svg {
          fill: $green;
          min-height: 14px;
          min-width: 14px;
          margin-top: 4px;
        }
        > span {
          margin-left: 1rem;
        }
      }
    }
    .links {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        margin-top: 2rem;
        button {
          margin-bottom: 2rem;
        }
      }
    }
    .content-item {
      margin-bottom: 4rem;
      @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
      }
      > :first-child {
        margin-top: 0;
      }
      > :last-child {
        margin-bottom: 0;
      }
    }
    > .left-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 4rem;
      padding-bottom: 4rem;
      padding-right: 4rem;
      padding-left: 15px;
      width: 700px;
      @include media-breakpoint-down(lg) {
        width: 600px;
      }
    }
    > .right-content-wrapper {
      border-left: 0.05rem solid #f3efef;
      box-shadow: -1rem 0 1rem rgba(0, 0, 0, 0.01);
      padding-top: 4rem;
      padding-left: 4rem;
      padding-bottom: 4rem;
      > .right-content {
        max-width: calc(1140px - 700px - 4rem - 30px);
        @include media-breakpoint-down(lg) {
          max-width: calc(950px - 600px - 4rem - 30px);
        }
      }
    }
  }
}
