.project-issue-link {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;

  &.closed {
    background: #fafafa;
  }

  .issue-state {

    svg {
      margin-right: 8px;
      height: 26px;
      width: 26px;
    }

  }

  .issue-title {
    font-weight: 600;
    transition: color 0.2s;
    display: flex;
    align-items: center;
    margin-left: 5px;
  }

  .issue-details {
    font-weight: 300;
    font-size: $font-xs;
    margin-top: 10px;
    margin-left: 5px;
    color: #777;
  }

  &:hover {
    .issue-title {
      color: $blue11;
    }
  }
}

.opened .issue-state svg path {
  fill: $green;
}

.closed .issue-state svg path {
  fill: #aaa;
}

.conflict .issue-state svg path {
  fill: $red11;
}

.issue-state svg.fill-warning-red path {
  fill: $red11
}

.issue-state svg.fill-gray path {
  fill: $gray11;
}

.issue-state svg.fill-green path {
  fill: $green;
}