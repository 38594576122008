.get-started-banner {
  width: 100%;
  background: linear-gradient(90deg, #0052cc 50%, #581fa0 50%);

  .container.both-banners {
    display: flex;

    @include media-breakpoint-down(lg) {
      max-width: 100%;
      flex-direction: column;
      padding: 0;
    }
  }

  .container.get-started,
  .container.container.popular-sims {
    @include media-breakpoint-up(xl) {
      padding: 0;
    }
  }

  .content-left {
    flex-basis: 60%;
    background: linear-gradient(90deg, #0052cc, #2482ff);
    padding: 80px 80px 80px 0;

    .buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      width: 100%;

      > :last-child button {
        margin-bottom: 0;
      }
    }

    .get-started-message {
      font-weight: 900;
      font-size: $font-xxl;
      line-height: $leading-tight;
      text-align: left;
      margin-top: 0;
      margin-bottom: 40px;
      padding-right: 30px;
      background-image: linear-gradient(90deg, #00e0ff, #00ffc2);
      background-size: 100%;
      background-repeat: repeat;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;

      @include media-breakpoint-down(xs) {
        font-size: 40px;
        padding-right: 0;
      }
    }

    @media (max-width: 500px) {
      .desktop-only {
        display: none;
      }
    }

    .open-getting-started {
      @include media-breakpoint-up(sm) {
        margin-right: 20px;
      }

      span.light {
        font-weight: 400;
      }

      img {
        margin-left: 16px;
        margin-bottom: 2px;
        height: 15px;
        width: 15px;
      }
    }

    .read-docs {
      margin-top: 15px;
      margin-right: 20px;

      img {
        margin-left: 15px;
        height: 18px;
        width: 18px;
        margin-bottom: 2px;
      }
    }

    .read-docs {
      background: rgba(255, 255, 255, 0.85);
      border: 1px solid transparent;
      color: black;

      &:hover {
        background: white;
      }
    }

    .open-getting-started {
      background: rgba(0, 0, 0, 0.25);
      border: 1px solid transparent;
      color: white;

      svg {
        path {
          fill: white;
        }
        margin-left: 15px;
      }

      &:hover {
        background: black;
      }
    }

    .open-getting-started,
    .read-docs {
      display: flex;
      align-items: center;
      padding: 0;
      border-radius: 10px;
      padding: 0 25px;
      height: 60px;
    }
  }

  .content-right {
    flex-grow: 1;
    background: linear-gradient(90deg, #2b37a3 11.61%, #581fa0 97.17%);
    padding: 80px 0 80px 80px;

    .container.popular-sims {
      height: 100%;
    }

    .popular-simulations {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;

      h2 {
        white-space: no-wrap;
        margin-top: 7px;
        font-weight: 900;
        margin: 0;
        span {
          background-size: 100%;
          background-repeat: repeat;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-background-clip: text;
          -moz-text-fill-color: transparent;
          &.popular {
            font-size: $font-xxl;
            background-image: linear-gradient(
              90deg,
              #ff0500,
              #fbbb2a,
              #2ab967,
              #509bff,
              #7a66ff
            );
          }

          &.simulations {
            font-size: 50px;
            background-image: linear-gradient(90deg, #8e84ff, #ff7ce2);

            @include media-breakpoint-down(xs) {
              font-size: 40px;
            }
          }
        }
      }

      p {
        margin-top: 25px;
        margin-bottom: 0;
        font-size: $font-lg;
        color: #bb71e8;
        transition: color 0.2s;
        @include media-breakpoint-down(lg) {
          margin-top: 0px;
        }
        @include media-breakpoint-down(sm) {
          font-size: $font-lg;
        }
        span {
          position: relative;
          transition: left 0.2s;
          left: 0px;
        }
      }

      &:hover {
        p {
          color: white;
          span {
            left: 8px;
          }
        }
      }
    }
  }

  .content-left,
  .content-right {
    @include media-breakpoint-down(lg) {
      padding: 75px 0;
    }
    @include media-breakpoint-down(xs) {
      padding: 60px 0;
    }
  }
}
