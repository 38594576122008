.banner {
  padding: 4rem 0;
  transition: background 0.3s;
  > div {
    h1,
    h5 {
      margin: 0;
      color: $white;
    }
    h5 {
      font-weight: 300;
    }
    h1 {
      font-weight: 400;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @include media-breakpoint-down(sm) {
        font-size: 2rem;
      }

      svg {
        position: relative;
        top: 4px;
        left: -12px;
        transition: left ease-in-out 0.1s;
        display: inline-block;
        width: 4.5rem;
        height: 4.5rem;
        fill: $white;

        @include media-breakpoint-down(sm) {
          width: 3.1rem;
          height: 3.1rem;
          top: 1px;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    &:hover {
      cursor: pointer;
      h1 > svg {
        left: 12px;

        @include media-breakpoint-down(sm) {
          left: -20px;
        }
      }
    }
  }
}

.bright-blue.banner {
  background-color: $blue11;
  &:hover {
    background-color: $blue10;
  }
}

.black-almost.banner {
  background-color: $gray13;
  &:hover {
    background-color: $gray13;
  }
}
