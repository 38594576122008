div.radio-select {
  label.radio-select-label {
    display: block;
    position: relative;
    padding: 10px 0 10px 30px;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: $font-sm;
    text-transform: none;
    font-weight: 400;
    user-select: none;
  }

  label.radio-select-label input {
    position: absolute;
    opacity: 0;
    width: 0;
  }

  .radio-circle {
    position: absolute;
    top: 10px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: $gray3;
    border-radius: 50%;
    border: 1px solid $gray8;
  }

  label.radio-select-label:hover input ~ .radio-circle {
    background-color: $gray7;
  }

  label.radio-select-label input:checked ~ .radio-circle {
    background-color: $blue11;
  }

  .radio-circle:after {
    content: "";
    position: absolute;
    display: none;
  }

  label.radio-select-label input:checked ~ .radio-circle:after {
    display: block;
  }

  label.radio-select-label input:checked ~ .radio-circle {
    border: 1px solid $blue11;

    &:after {
      top: 3px;
      left: 3px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
  }
}
