.sort-dropdown {
  width: clamp(300px, 3vw, 380px);
  display: flex;
  grid-gap: $space3;
  align-items: center;
  .input-label {
    flex-shrink: 0;
    font-size: $font-xs;
    color: $gray10;
    z-index: 3;
    font-weight: 400;
  }

  div.react-select-container {
    div.react-select__control {
      cursor: pointer;
      background-color: white;
      font-size: $font-xs;
    }
    div.react-select__option {
      cursor: pointer;
    }
  }

  @include media-breakpoint-down(sm) {
    .input-label {
      display: none;
    }
    div.react-select-container {
      div.react-select__control {
        padding-left: 0;
      }

      div.react-select__value-container {
        justify-content: center;
      }
    }
  }
}
