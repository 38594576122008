.index-profile-page {
  height: 100%;
  font-family: Inter, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
}

.index-profile-placeholder {
  height: 100%;
  padding: 80px 15px;
}
