.heading-1 {
  font-family: "Open Sauce Two", Inter, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: $leading-tight;

  @include media-breakpoint-down(xs) {
    font-size: 52px;
  }
}

.heading-2 {
  font-family: "Open Sauce Two", Inter, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: $leading-tight;
}

.heading-3 {
  font-family: "Open Sauce Two", Inter, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: $leading-snug;
}

.heading-4 {
  font-family: Inter, Helvetica, sans-serif;
  font-weight: 700;
  font-size: $font-lg;
  line-height: $leading-snug;
}

.heading-5 {
  font-size: $font-sm;
  line-height: $leading-snug;
  font-weight: 600;
  text-transform: uppercase;
}

.large-text {
  font-family: Inter, Helvetica, sans-serif;
  font-size: $font-lg;
  line-height: $leading-snug;
}

.medium-text {
  font-family: Inter, Helvetica, sans-serif;
  font-size: $font-md;
  line-height: $leading-snug;
}

.normal-text,
p {
  font-family: Inter, Helvetica, sans-serif;
  font-size: $font-base;
  line-height: $leading-base;
}

.small-text {
  font-family: Inter, Helvetica, sans-serif;
  font-size: $font-sm;
  line-height: $leading-base;
}

.caption {
  font-size: $font-xs;
  letter-spacing: 0.05rem;
  line-height: $leading-base;
  text-transform: uppercase;
}

.black {
  color: $gray14;
}

.white {
  color: #ffffff;
}

.bright-blue {
  color: $blue11;
}

.bold {
  font-weight: 600;
}

p a,
li a,
a.text-link,
textarea.mde-text a,
.mde-preview-content a {
  color: inherit;
  text-decoration: inherit;
  border-bottom: 0.2rem solid $gray7;
  transition: color 0.2s, border-color 0.3s;
  font-weight: 600;

  &:hover {
    color: $blue11;
    border-color: $blue11;
    text-decoration: inherit;
    cursor: pointer;
  }

  &.no-underline {
    border-bottom: none;

    &:hover {
      border-bottom: none;
    }
  }
}

.ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two-lines-max {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.break-together {
  display: inline-block;
}

.light-namespace {
  font-weight: 400;
}
