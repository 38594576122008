.scraped-datasets {
  background-color: $gray13;
  padding: 2rem;
  color: white;
  height: 100%;

  .count-and-sort {
    font-size: $font-sm;
    margin-bottom: $space4;

    .result-count {
      height: 32px;

      .loading-indicator {
        height: 20px;
        width: 20px;
      }
    }
  }

  .page-numbers {
    margin-bottom: 15px;
  }

  .loading {
    height: 140px;
    margin: 0 -32px 15px -32px;
    padding: 20px 30px;
    background-color: $gray14;
  }

  .scraped-queue-filter {
    height: 140px;
    margin: 0 -32px 15px -32px;
    padding: 20px 30px;
    background-color: $gray14;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .dropdown-wrapper,
    .text-search {
      height: 42px;
    }

    .text-search {
      display: flex;
      margin-top: 15px;

      .input,
      button {
        height: 42px;
      }

      .input {
        width: 100%;
        margin-right: 10px;
      }
    }

    .filter-options {
      display: flex;
    }

    .file-formats {
      margin-left: 80px;

      .dropdown-wrapper {
        width: 200px;
      }
    }

    .scraped-queue-checkboxes {
      display: flex;
      justify-content: space-between;

      .filter-checkboxes {
        display: flex;

        > div {
          margin-right: 50px;
        }
      }

      .checkbox {
        margin-bottom: 10px;

        .text {
          margin-left: 24px;
          font-size: $font-xs;
          margin-bottom: 3px;
        }
      }

      svg {
        margin-right: 6px;
      }

      .exclude {
        margin-left: -1px;
      }

      .check svg {
        fill: rgba(241, 243, 246, 0.2);

        path {
          fill: white;
        }
      }

      .legend {
        font-size: $font-xs;

        .legend-item {
          font-weight: bold;
          display: flex;
          align-items: center;
          margin-top: 5px;
        }
      }
    }
  }
}
