.data-table-container {
  overflow: scroll;
  width: 100%;

  table.data-table {
    border: 1px solid rgb(240, 240, 240);
    width: 100%;
    font-size: $font-xs;
    font-weight: 400;
    text-align: left;
    border-collapse: collapse;

    td,
    th {
      padding: 10px 15px;
      max-height: 60px;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    th {
      font-weight: 600;
    }

    th:not(:first-child) {
      border-left: 1px solid rgb(230, 230, 230);
    }

    thead {
      border-bottom: 1px solid rgb(210, 210, 210);
    }

    td {
      border-bottom: 1px solid rgb(240, 240, 240);
    }
  }
}
