.markdown-display,
div.react-mde .mde-preview-content {
  width: 100%;
  margin-bottom: 30px;
  position: relative;

  .loading-indicator {
    position: absolute;
    top: 40px;
    right: 15px;
  }

  @include media-breakpoint-down(xs) {
    flex-wrap: wrap;
  }

  table {
    display: block;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    thead {
      th {
        font-weight: bold;
      }
    }
    th,
    td {
      padding: 6px 13px;
      border: 1px solid #c8ccd0;

      code {
        white-space: nowrap !important;
      }
    }
  }

  video {
    width: 100%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 5px;

    &:focus {
      outline: none;
    }
  }

  hr {
    border: none;
    margin: 20px 0;
    border-top: 1px solid #ededed;
  }

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 10px;
    line-height: $leading-snug;
    font-weight: 600;
    margin-top: 30px;
  }

  .value > :first-child {
    margin-top: 0;
  }

  p {
    font-weight: 400;
    line-height: $leading-base;
  }

  ul,
  ol {
    padding-inline-start: 20px;

    li {
      font-weight: 400;
    }
  }

  h1 {
    font-size: 1.7rem;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1.3rem;
  }

  h5 {
    font-size: 1.2rem;
  }

  h6 {
    font-size: 1.1rem;
  }
}

.markdown-editor-container {
  position: relative;

  &.with-label {
    .mde-header {
      padding-left: 130px;
    }

    .markdown-editor-label {
      position: absolute;
      top: 12px;
      left: 12px;
      font-size: $font-xs;
      font-weight: 600;
      font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
      color: #525659;
      z-index: 2;
      display: flex;
      align-items: center;

      svg {
        path {
          fill: #babfc2;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      .markdown-editor-label {
        display: none;
      }

      .mde-header {
        padding-left: 0;
      }
    }
  }
}

div.react-mde {
  color: $gray13;
  border: 1px solid $gray6;
  font-size: $font-sm;
  font-family: Inter, Helvetica, sans-serif;
  border-radius: 8px;

  .mde-preview .mde-preview-content h1,
  .mde-preview .mde-preview-content h2,
  .mde-preview .mde-preview-content h3 {
    border-bottom: 0;
    padding-bottom: 0;
  }

  textarea.mde-text,
  .mde-preview .mde-preview-content {
    padding: 35px 45px;
    line-height: $leading-base;

    @include media-breakpoint-down(sm) {
      padding: 25px 15px;
    }

    pre {
      font-size: $font-sm;
      white-space: pre-wrap;
    }

    code {
      white-space: pre-wrap;
    }

    p {
      line-height: $leading-base;
    }

    a {
      color: inherit;

      &:hover {
        color: $blue11;
        text-decoration: none;
      }
    }
  }
}

div.mde-header {
  border-color: $gray3;
  background: $gray3;
  align-items: center;
  position: relative;
  min-height: 45px;
  border-radius: 8px 8px 0 0;

  ul.mde-header-group {
    align-items: center;
    padding: 0 9px;

    li.mde-header-item {
      position: initial;
    }

    li.mde-header-item button {
      &[data-name="video"] svg {
        padding-top: 1px;
      }

      &:hover svg {
        fill: $blue11;

        path {
          fill: $blue11;
        }
      }
    }
  }

  div.mde-tabs {
    button:nth-child(2),
    button.selected {
      display: none;
    }
  }

  div.mde-tabs button,
  li.mde-header-item button[data-name="save"] {
    padding: 0 25px !important;
    height: 100% !important;
    margin: 0;
    top: 0;
    right: 0;
    position: absolute;
    background: #e1e5ea !important;
    color: $gray13;
    font-weight: 700;
    border: none;
    border-radius: 0 8px 0 0;
    border-bottom: 1px solid transparent;
    transition: all 0.2s;

    &:hover {
      background-color: white !important;
    }

    &.selected {
      border: none;
      border-bottom: 2px solid $blue11;
    }
  }
}
