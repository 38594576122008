.schema-page {
  * {
    box-sizing: border-box;
  }

  .soft-break {
    display: inline-block;
  }

  .project-title {
    margin-top: 0px;
    font-weight: 400;

    strong {
      font-weight: 700;
    }
  }

  .metadata-field .schema-link {
    font-weight: bold;
    transition: color 0.3s;

    &:hover {
      color: $blue;
    }
  }

  .search-for-usages,
  .new-schema-button,
  .fork-subject-form {
    display: flex;
    justify-content: flex-end;
    position: relative;

    .secondary-button {
      height: 52px;
      font-size: $font-sm;
      font-weight: 600;
    }

    svg {
      margin-top: 0 !important;

      path {
        fill: white;
      }
    }
  }

  .header-flex-container {
    display: flex;

    > * {
      &:not(:last-child) {
        margin-right: 30px;
      }

      &:not(:first-child):last-child {
        margin-left: auto;
      }
    }

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;

      .schema-summary {
        margin-bottom: 30px;
      }
    }
  }
}

.project-header .schema-header {
  .listing-title {
    margin-bottom: 0.25em;
    font-weight: 200;

    strong {
      font-weight: 800;
    }
  }

  p {
    margin: 0;
    line-height: $leading-base;
    font-size: $font-base;
  }
}

.schema-breadcrumbs {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.66);
  margin-bottom: 25px !important;

  li {
    display: flex;

    a {
      font-weight: normal;

      &:not(:hover) {
        border-bottom-color: transparent;
      }
    }

    &:first-child {
      font-weight: bold;
    }

    &:not(:last-child)::after {
      content: ">";
      margin: 0 0.5em;
      display: block;
      font-weight: normal;
    }
  }
}

.schema-body-left {
  width: auto;
  flex: 1;
  margin-right: 50px;

  @include media-breakpoint-down(md) {
    margin-right: 0;
    margin-bottom: 40px;
  }
}

.schema-property-tables {
  width: 100%;

  .own-properties {
    margin-bottom: 50px;
  }
}

.schema-property-table {
  border-collapse: separate;
  border-spacing: 0;

  max-width: 100%;
  width: 100%;
  font-size: $font-sm;
  line-height: $leading-loose;

  .markdown-display {
    position: initial;
  }

  &:not(:last-child):not(.primary) {
    margin-bottom: 40px;
  }

  tbody td,
  thead th {
    border: 0 solid #e5e5e5;

    &:first-child {
      border-left-width: 1px;
    }

    &:last-child {
      border-right-width: 1px;
    }
  }

  thead th {
    background-color: #efefef;
    position: sticky;
    top: -1px;
    border-top-width: 1px;
    border-bottom-width: 1px;
  }

  tbody td {
    border-bottom-width: 1px;
  }

  th,
  td {
    text-align: left;
    width: 30%;
    max-width: 0;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    hyphens: manual;

    &:last-child {
      width: 40%;
    }
  }

  th {
    padding: 15px 20px;
    font-weight: 600;

    a:not(.no-underline),
    .schema-link-underline {
      color: $blue;
      border-bottom-color: transparent;

      &:hover {
        border-bottom-color: $blue;
      }
    }
  }

  td {
    padding: 12px 20px;
    font-size: $font-xs;
    letter-spacing: 0.05rem;
  }

  a {
    text-decoration: inherit;
    font-weight: bold;
  }

  a:not(.no-underline),
  .schema-link-underline {
    border-bottom: 0.2rem solid $gray7;
    transition: border-color 0.3s, color 0.3s;

    &:hover {
      color: $blue;
      border-color: $blue11;
    }

    ~ .external-link-icon {
      margin-top: 0;
    }
  }

  a:not(.no-underline):hover,
  a:hover .schema-link-underline {
    color: $blue;
  }

  button.delete-property {
    background: none;
    border: none;
    padding: 2px;
    margin-left: 12px;

    svg {
      fill: $red11;
    }
  }
}

.schema-link {
  .external-link-icon {
    height: 1.3em;
    vertical-align: middle;
    margin-top: -0.2em;
  }
}

.schema-children {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.schema-type-select {
  position: relative;

  select {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.schema-license-logo {
  margin-top: 5px;
  margin-left: -2px;
  width: 24px;
  height: 24px;
}

.property-body {
  .section-with-title {
    margin-bottom: 30px;
  }

  .property-meta-link {
    font-size: $font-sm;
    margin-bottom: 10px;
  }
}

.create-schema {
  .project-title {
    margin-bottom: 40px;
    font-weight: 500;

    strong {
      font-weight: 700;
    }
  }

  .schema-subtype-of {
    width: 400px;
  }

  .new-schema-name {
    width: 400px;
  }

  .new-schema-description {
    width: 600px;
  }
}

.fork-subject-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 25px;
  min-height: 52px;

  .project-owner-dropdown {
    margin-left: 15px;
    margin-top: 5px;
    justify-content: flex-end;
  }

  .project-owner-dropdown div.dropdown-wrapper {
    width: 180px;
  }

  .error-text {
    width: 100%;
    margin-top: 5px;
    text-align: right;
  }
}

.add-property-form-container {
  padding: 15px 20px;
  border: 1px solid #e5e5e5;
  border-top: none;

  .input,
  div.react-select__control {
    min-height: 48px;
  }

  .create-property-form {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .create-property-inputs {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .new-property-field {
      width: 30%;
      margin-right: 15px;

      &:last-child {
        width: 40%;
        margin-right: 0;
      }

      .input,
      div.react-select__control {
        width: 100%;
      }
    }

    button {
      margin-top: 20px;
    }
  }
}

.white-fill {
  svg path {
    fill: white;
  }
}

.schema-simple-dropdown {
  position: inherit;
}
