.profile-project-link {
  width: 49%;
  min-width: 300px;

  @include media-breakpoint-down(lg) {
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    min-width: 280px;
  }

  @media (max-width: 380px) {
    min-width: 100%;
  }
}

.profile-project-card {
  display: flex;
  align-items: center;
  position: relative;
  height: 150px;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  font-family: Inter, Helvetica, sans-serif;
  margin-bottom: 30px;
  cursor: pointer;
  transition: box-shadow, opacity 0.2s;
  user-select: none;

  .visibility-indicator {
    position: absolute;
    top: 10px;
    right: 15px;
  }

  .details {
    width: 100%;
    padding: 25px 35px;
    max-width: calc(100% - 40px);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  @include media-breakpoint-down(lg) {
    height: auto;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 20px;

    .project-avatar {
      display: none;
    }

    .details {
      max-width: 100%;
    }
  }

  @include media-breakpoint-up(sm) {
    &:hover {
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

      .project-title {
        color: $blue11;
      }

      .project-desc {
        opacity: 0.8;
      }

      .project-type {
        opacity: 0.8;
      }
    }
  }

  .project-avatar {
    height: 100%;

    video,
    img {
      border-radius: 8px;
    }
  }

  .project-info {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: $font-xs;
    font-weight: 400;
    margin-bottom: 2px;

    .project-type {
      text-transform: uppercase;
      transition: opacity 0.2s;
    }

    .fork-count,
    .dependents-count,
    .star-badge {
      display: flex;
      align-items: center;
      margin-left: 10px;

      svg {
        height: 18px;
        width: 18px;
        margin-top: 1px;
        margin-right: 2px;

        path {
          fill: #979797;
        }
      }
    }

    .star-badge svg {
      margin-top: -1px;
      margin-right: 1px;
    }
  }
  .project-title {
    font-size: $font-base;
    font-weight: bold;
    margin-bottom: 6px;
    transition: color 0.2s;
    margin-bottom: 10px;
  }

  .project-shortname {
    opacity: 0.33;
    font-size: $font-xs;
    line-height: $leading-snug;
    margin-bottom: 6px;
  }

  .project-desc {
    font-weight: 300;
    width: 100%;
    min-width: 100%;
    min-height: 36px;
    transition: opacity 0.2s;
  }
}

.profile-project-card-loading {
  display: flex;
  align-items: center;
  position: relative;
  height: 150px;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  font-family: Inter, Helvetica, sans-serif;
  margin-bottom: 30px;
  transition: box-shadow, opacity 0.2s;
  user-select: none;

  .visibility-indicator {
    position: absolute;
    top: 10px;
    right: 15px;
  }

  .details {
    width: 100%;
    padding: 25px 35px;
    max-width: calc(100% - 40px);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 20px;

    .project-avatar {
      display: none;
    }

    .details {
      max-width: 100%;
    }
  }

  .project-avatar {
    height: 100%;

    video,
    img {
      border-radius: 8px;
    }
  }

  .project-info {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: $font-xs;
    font-weight: 400;
    margin-bottom: 2px;

    .project-type {
      text-transform: uppercase;
      transition: opacity 0.2s;
    }

    .fork-count,
    .dependents-count,
    .star-badge {
      display: flex;
      align-items: center;
      margin-left: 10px;

      svg {
        height: 18px;
        width: 18px;
        margin-top: 1px;
        margin-right: 2px;

        path {
          fill: #979797;
        }
      }
    }

    .star-badge svg {
      margin-top: -1px;
      margin-right: 1px;
    }
  }
  .project-title {
    font-size: $font-base;
    font-weight: bold;
    margin-bottom: 6px;
    transition: color 0.2s;
    margin-bottom: 10px;
  }

  .project-shortname {
    opacity: 0.33;
    font-size: $font-xs;
    line-height: $leading-snug;
    margin-bottom: 6px;
  }

  .project-desc {
    font-weight: 300;
    width: 100%;
    min-width: 100%;
    min-height: 36px;
    transition: opacity 0.2s;
  }
}
