.merge-request-actions-container {
  border-radius: 0.5rem;
  border: 1px solid $gray8;
};

.merge-request-submit-buttons-container {
  border-top: 1px solid $gray8;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem 0 2rem 0;

  button:last-child {
    margin-right: 0;
  }

  button.close-or-reopen {
    background: transparent;
    border: 1px solid #c1c1c1;
    border-radius: 8px;
    padding: 0.8rem;
    color: #000;
    display: flex;
    align-items: center;
  }

  button.close-or-reopen:hover {
    background: $blue10;
    color: $white;
    fill: $white;
  }

  button.merge {
    background: $blue10;
    border: 0;
    border-radius: 8px;
    padding: 1rem;
    color: $white;
    margin-right: 1rem;
  }

  button.merge:hover {
    background: $blue9;
    color: #000;
  }

  button {
    margin-left: 1rem;
    font-weight: 600;
  }

  button:last-child {
    margin-right: 1rem;
  }

  button:disabled, button:disabled:hover {
    background-color: $gray9;
    cursor: not-allowed;
    color: $gray13;
  }

  button > .alert-circle-icon {
    margin-right: 0.25rem;
  }

  button > .alert-circle-icon path {
    fill: $red11;
  }

  button:hover > .alert-circle-icon path {
    fill: $white;
  }
};