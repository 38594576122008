.profile-listing-cards {
  width: 100%;
  padding-bottom: 30px;

  .loading-indicator {
    margin-top: 15px;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    flex-wrap: wrap;
    max-width: calc(100vw - 30px);
  }

  .profile-project-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .account-section-box {
      width: 100%;

      th {
        text-align: left;
      }
    }
  }

  .type-and-sort {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    height: 39px;

    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
      height: auto;
      margin-bottom: 20px;
    }
  }

  .tab-header {
    border-bottom: 2px solid #ededed;
    padding-bottom: 13px;
    margin-right: 40px;
    user-select: none;
    width: 100%;

    @include media-breakpoint-down(sm) {
      margin-right: 20px;
    }

    @include media-breakpoint-down(xs) {
      margin-right: 0;
      padding-bottom: 8px;
      margin-bottom: 20px;
    }

    .tab {
      border-bottom: 2px solid #ededed;
      font-size: $font-sm;
      padding: 12px 0;
      margin-right: 25px;
      cursor: pointer;
      transition: color 0.2s, border-color 0.2s;
      text-transform: uppercase;

      &:not(.active):hover {
        color: $blue11;
      }

      @include media-breakpoint-down(xs) {
        font-size: $font-xs;
        padding: 8px 0;
        margin-right: 15px;
      }
    }

    .tab.active {
      font-weight: 600;
      border-color: $blue11;
    }
  }

  .tab-header-loading {
    border-bottom: 0px;
    padding-bottom: 0px;
    height: 44px;
    margin-right: 0px;

    .MuiSkeleton-text {
      transform: inherit;
    }
  }

  .paginator {
    width: 100%;
    margin-bottom: 15px;

    .page-numbers {
      justify-content: flex-end;
    }
  }
}
