.project-owner-dropdown {
  display: flex;
  align-items: center;

  div.dropdown-wrapper {
    margin-top: 2px;
    margin-left: 12px;
    width: 200px;
    font-weight: 500;

    .react-select__menu {
      right: 0;
      min-width: auto;
    }
  }

  .yourself-label {
    margin-left: 8px;
  }

  @media (max-width: 500px) {
    width: 100%;

    div.dropdown-wrapper {
      width: calc(100% - 40px);
    }
  }
}
