.MuiTooltip-popper {
  .MuiTooltip-tooltip.subject-option-label {
    margin-left: 25px;
  }
  .MuiTooltip-tooltip.MuiTooltip-tooltipArrow {
    padding: 10px 20px;
    box-sizing: border-box;
    font-size: $font-xs;
    line-height: $leading-snug;
    background: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15), inset 0px 2px 20px $gray3;
    border-radius: 10px;
    min-height: 40px;
    color: black;
    opacity: 0.9;
    font-size: $font-xs;

    ul {
      margin-top: 0;
    }

    .MuiTooltip-arrow {
      color: #ffffff;
    }
  }
  &.MuiTooltip-popperArrow {
    &[x-placement*="bottom"] .MuiTooltip-arrow {
      margin-top: -1.25em;
      height: 1.25em;
      width: 2.5em;
    }
    &[x-placement*="right"] .MuiTooltip-arrow {
      margin-top: -0.9em;
      margin-left: -1.25em;
      height: 2.5em;
      width: 1.25em;
    }
  }
}
