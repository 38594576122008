.logo-component {
  display: flex;
  align-items: center;

  .container {
    width: 8rem;
    height: 8rem;
  }

  .vertical1 {
    opacity: 0.8;
    background: linear-gradient(-180deg, #00b8ff, #0010ff, #00b8ff, #0010ff);
    background-size: 100% 300%;
    width: 28%;
    height: 100%;
    position: relative;
    left: 16%;
  }

  .vertical2 {
    opacity: 0.8;
    background: linear-gradient(0deg, #00bbff, #0046ff, #00bbff, #0046ff);
    background-size: 100% 300%;
    width: 28%;
    height: 100%;
    position: relative;
    left: 56%;
    top: -100%;
  }

  .horizontal1 {
    opacity: 0.8;
    background: linear-gradient(to right, #0050ff, #ff00ee, #0050ff, #ff00ee);
    background-size: 300% 100%;
    width: 100%;
    height: 28%;
    position: relative;
    top: -184%;
  }

  .horizontal2 {
    opacity: 0.8;
    background: linear-gradient(to right, #ff00e8, #0080ff, #ff00e8, #0080ff);
    background-size: 300% 100%;
    width: 100%;
    height: 28%;
    position: relative;
    top: -172%;
  }

  .hash-letters div,
  .hash-letters svg {
    height: 100%;
    width: unset;
  }

  &.mono {
    .vertical1 {
      background: rgba(0, 0, 0, 0.95);
      opacity: 0.9;
    }

    .vertical2 {
      background: rgba(0, 0, 0, 0.85);
      opacity: 0.89;
    }

    .horizontal1 {
      background: rgba(0, 0, 0, 0.9);
      opacity: 0.88;
    }

    .horizontal2 {
      background: #000000;
      opacity: 0.87;
    }
  }
}
