.project-license {
  .license-field {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;

    svg,
    img {
      max-height: 26px;
      max-width: 80px;
      margin-top: 8px;
    }

    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
      margin-bottom: 30px;
    }

    .label {
      min-width: 100px;
      max-width: 100px;
      margin-right: 40px;

      @include media-breakpoint-down(xs) {
        max-width: 100%;
        padding-bottom: 5px;
      }
    }

    .value {
      width: 100%;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}
