form.contact-form {
  background-color: $white;
  padding: 1.5rem;
  border: 0.05rem solid #f3efef;
  border-radius: 0.5rem;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  label {
    display: flex;
    align-items: center;
    &.big {
      align-items: flex-start;
    }
    justify-content: space-between;
    margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 1.5rem;
    }
    @include media-breakpoint-down(sm) {
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
    }
    > p {
      opacity: 1;
      transition: all 0.3s;
      &.submitted {
        opacity: 0.75;
        transition: all 0.3s;
      }
      margin: 0;
    }
    > input,
    textarea {
      width: 25rem;
      padding: 0.5rem;
      margin-left: 1.5rem;
      border-width: 0;
      border-radius: 0.25rem;
      background-color: #f5f9fc;
      transition: all 0.3s;
      opacity: 1;
      @include media-breakpoint-down(sm) {
        margin-left: 0;
        width: 100%;
      }
      &:focus {
        background-color: #dde4ff;
        transition: all 0.3s;
      }
      &.valid {
        background-color: #e7ecff;
        transition: all 0.3s;
      }
      &.invalid {
        background-color: #f3b2b2;
        transition: all 0.3s;
      }
      &.submitted {
        opacity: 0.75;
        transition: all 0.3s;
      }
    }

    > textarea {
      resize: none;
      transition: height 300ms;
      font-family: inherit;
    }
  }

  > input[type="submit"] {
    width: fit-content;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    opacity: 1;
    transition: all 0.3s;
    &.submitted {
      opacity: 0.75;
      transition: all 0.3s;
      &:hover {
        background-color: $color-primary;
        color: $white;
        cursor: default;
      }
    }
  }
}
