.shortname {
  .heading-5 {
    margin-bottom: 2px;
  }

  .shortname-display {
    padding: 50px;
    background-color: $gray2;
    padding-bottom: 60px;
    margin-bottom: -20px;

    @include media-breakpoint-down(md) {
      padding: 30px 30px 50px 30px;
    }

    .links {
      display: flex;
      flex-wrap: wrap;

      label {
        display: block;
        padding-bottom: 4px;
      }

      .id-link {
        margin-right: 50px;
        margin-bottom: 20px;

        @include media-breakpoint-down(xs) {
          margin-right: 0;
        }
      }

      a {
        max-width: calc(100vw - 110px);
        letter-spacing: 0.05rem;
        font-size: $font-xs;
        text-transform: uppercase;
        word-break: break-word;
      }
    }

    a {
      font-weight: bold;
      cursor: pointer;
      font-size: $font-sm;
      color: $blue11;
      text-decoration: none;
      transition: all 0.1s;
      border-bottom: 3px solid transparent;

      &:hover {
        border-bottom: 3px solid $blue11;
      }
    }
  }

  .shortname-edit {
    padding: 30px 50px;
    background-color: rgb(231, 231, 250);
    position: relative;

    @include media-breakpoint-down(xs) {
      padding: 30px;
    }

    .loading-indicator {
      position: absolute;
      top: 16px;
      right: 16px;
    }

    .error-text {
      height: 14px;
      font-size: $font-sm;
      margin-bottom: 5px;
    }
  }

  .shortname-display,
  .shortname-edit {
    border-radius: 16px;
    border: 1px solid $gray6;
  }

  .shortname-explainer {
    font-size: $font-sm;
    line-height: $leading-base;
    color: $gray13;
    font-weight: 300;
    margin-top: 30px;
  }

  .shortname-input {
    margin-bottom: 4px;

    .shortname-input-header {
      font-size: $font-xs;
      font-weight: 600;
      margin-bottom: 8px;
      margin-left: 3px;
      height: 12px;
    }

    .shortname-success {
      color: $blue11;
      font-size: $font-xs;
      margin-left: 8px;
      line-height: $leading-tight;
    }

    .shortname-form {
      display: flex;
      align-items: center;

      @media (max-width: 470px) {
        flex-wrap: wrap;

        input {
          width: 100%;
          max-width: 100%;
          margin-bottom: 20px;
          margin-right: 0;
        }

        button {
          width: 100%;
          height: 42px;
        }
      }
    }

    .input {
      width: 50%;
      max-width: 320px;
      margin-right: 20px;
    }
  }
}
