.merge-request-summary {
  padding-top: 2px;
}
.opened .merge-request-state svg path {
  fill: $green;
}

.closed .merge-request-state svg.call-merge-icon path,
.closed .merge-request-state svg.alert-circle-icon path {
  fill: #aaa;
}

.conflict .merge-request-state svg path {
  fill: $red11;
}

.merge-request-state svg.fill-warning-red {
  fill: $red11
}

.merge-request-state svg.fill-gray path {
  fill: $gray11;
}

.merge-request-state svg.fill-green path {
  fill: $green;
}