.public-info.profile-section .account-section-box {
  padding: 0;
  min-height: 300px;
  position: relative;

  .loading-indicator {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  @include media-breakpoint-down(sm) {
    .loading-indicator {
      position: fixed;
    }
  }
}

.public-user-info-edit {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding: 50px;

  @include media-breakpoint-down(md) {
    padding: 30px;
  }

  .file-picker {
    display: none;
  }

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    justify-content: center;

    .loading-indicator {
      position: fixed;
      top: 1rem;
      right: 1rem;
      left: auto;
    }
  }

  .user-profile-text-inputs {
    width: 100%;

    @include media-breakpoint-down(md) {
      margin-left: 0;
    }

    .givenName-edit,
    .familyName-edit,
    .language-edit,
    .location-edit,
    .website-edit {
      width: 48%;
    }

    @include media-breakpoint-down(md) {
      .givenName-edit,
      .familyName-edit,
      .language-edit,
      .location-edit,
      .website-edit {
        width: 100%;
      }
    }

    .user-profile-name-edit,
    .bio-edit {
      max-width: 500px;
    }

    .user-profile-name-edit,
    .location-website {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }
    }

    textarea {
      height: 140px;
      resize: none;
    }

    .language-edit {
      margin-bottom: 0;
    }

    .react-select__control {
      min-height: 48px;
    }

    .react-select__input input {
      margin-top: 0;
    }

    .language-edit label {
      margin-bottom: 5px;
    }
  }

  .role {
    margin-bottom: 5px;
  }
  .language-role-user-edit {
    display: flex;
    flex-wrap: wrap;
  }
  .language-edit {
    margin-right: 2.1rem;
  }
}
