.project-main-content {
  padding: 50px 15px;
  display: flex;
  justify-content: space-between;

  .MuiSkeleton-text {
    transform: inherit;
  }

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  @include media-breakpoint-down(sm) {
    padding: 30px 15px;
  }

  .project-tabs {
    width: calc(100% - 280px);

    .tab-header-loading {
      padding-bottom: 13px;
      width: 100%;
      margin-bottom: 40px;
      user-select: none;
    }

    .tab-header {
      border-bottom: 2px solid #ededed;
      padding-bottom: 13px;
      width: 100%;
      margin-bottom: 40px;
      user-select: none;

      .tab {
        border-bottom: 2px solid #ededed;
        font-size: $font-sm;
        padding: 12px 0;
        margin-right: 25px;
        cursor: pointer;
        transition: color 0.2s, border-color 0.2s;

        &:not(.active):hover {
          color: $blue11;
        }
      }

      .tab.active {
        font-weight: 600;
        border-color: $blue11;
      }
    }

    @include media-breakpoint-down(lg) {
      .tab-header-loading {
        border: none;
        margin-bottom: 30px;
      }

      .tab-header {
        border: none;
        margin-bottom: 30px;

        .tab {
          display: block;
          margin-right: 0;
        }
      }
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}
