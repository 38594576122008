.merge-request-source-and-target-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .arrow-left-icon {
    fill: #000;
    margin: 0 1rem 0.5rem 1rem;
  }

  .label-input {
    flex: 1;
    width: auto;
  }

}

@media screen and (max-width: 470px) {
  .merge-request-source-and-target-group {
    flex-direction: column;

    .arrow-left-icon {
      display: none;
    }

    .label-input {
      width: 100% !important;
    }
  }
}

.type-dropdown-container {
  max-width: 25.1rem; 
  width: 100% !important;
}

.title-container input#title {
  width: 100% !important;
}

.merge-request-fork-project-before-creating-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.merge-request-label-badge {
  border-radius: 12px;
  margin-left: 8px;
  color: white !important;
  padding: 5px 7px;
  font-size: $font-xs;
  font-weight: 500;

  &.bugfix {
    background-color: $red11;
  }

  &.feature {
    background-color: $blue8;
  }

  &.enhancement, &.draft {
    background-color: $gray13;
  }

  &.comment {
    background-color: $green;
  }
}


// TODO: This could be removed and in turn the "Mark as ready" notification could change
// to turn to draft
.merge-request-turn-to-draft-and-back {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #888;
}

.merge-request-source-label {
   border-radius: 12px;
   color: white;
   background: $gray11;
   padding: 5px 7px;
   font-size: $font-xs;
   font-weight: 500;
   margin: 0 0.4rem 0 0.4rem;

   &:hover {
     background: $gray11;
     color: $gray12;
   }
}

.merge-request-activity {
  .note:first-child {
    margin-bottom: 1.5rem;
  }
  .note, .note .markdown-display {
    margin-bottom: 0;
  }

  .close-icon {
    height: 2.5rem;
    width: 2.5rem;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }

  .close-icon path {
    fill: $red11;
  }

  &.merge-request-is-draft-mark-ready-for-review {
    justify-content: space-between;
    padding: 1rem 0 1rem 0;

    .alert-circle-icon {
      height: 4rem;
      width: 4rem;
      margin-left: 0.3rem;
      margin-right: 0.5rem;
    }

    .alert-circle-icon path {
      fill: $gray10;
    }
  }

  .text-container {
    flex: 1;
  }

  .text-container > h4 {
    margin: 0;
    margin-bottom: 0.25rem;
  }

  .text-container > p {
    margin: 0;
  }

  button {
    margin-right: 1rem;
  }
}

.merge-request-error-notification {
  .alert-circle-icon {
    width: 7rem;
    height: 7rem;
    margin: 0 1rem 0 0;
  }
  .alert-circle-icon path {
    fill: $red11;
  }
}

.merge-request-turn-to-draft-and-back {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #888;
}

.merge-request-source-label {
   border-radius: 12px;
   color: white;
   background: $gray11;
   padding: 5px 7px;
   font-size: $font-xs;
   font-weight: 500;
   margin: 0 0.4rem 0 0.4rem;
}

.merge-request-change-display-mode {
  cursor: pointer;
  font-weight: bold;
}