.loading-indicator,
.loading-indicator > div {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.loading-indicator {
  display: block;
  font-size: 0;
  color: #c3c3c3;
}
.loading-indicator > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}
.loading-indicator {
  width: 32px;
  height: 32px;
}
.loading-indicator > div {
  width: 32px;
  height: 32px;
  background: transparent;
  border-width: 4px;
  border-bottom-color: transparent;
  border-radius: 100%;
  -webkit-animation: ball-clip-rotate 0.75s linear infinite;
  -moz-animation: ball-clip-rotate 0.75s linear infinite;
  -o-animation: ball-clip-rotate 0.75s linear infinite;
  animation: ball-clip-rotate 0.75s linear infinite;
}
/*
 * Animation
 */
@-webkit-keyframes ball-clip-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes ball-clip-rotate {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes ball-clip-rotate {
  0% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes ball-clip-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
