.search-results-sidebar {
  height: 100%;
  padding: 50px 0;
  font-family: Inter, Helvetica, sans-serif;
  color: #4b4b4b;
  font-size: $font-xs;
  font-weight: 300;
  position: relative;
  width: 260px;

  > :last-child {
    margin-bottom: 100px;
  }

  @include media-breakpoint-down(md) {
    padding: 30px 0;
  }

  .search-results-banner {
    width: 100%;
    text-align: center;
    font-size: $font-xs;
    font-weight: 300;
    line-height: $leading-snug;
    padding: 6px 20px;
    opacity: 0;
    height: 0;
    color: black;
    animation: 8s fadein-greyout;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  @keyframes fadein-greyout {
    0% {
      height: 0;
      opacity: 0;
      background-color: rgb(255, 255, 0);
    }

    8% {
      height: 40px;
    }

    20% {
      opacity: 1;
    }

    40% {
      background-color: rgb(255, 255, 0);
      opacity: 1;
    }

    90% {
      background-color: rgba(126, 126, 126, 0.1);
      height: 40px;
      opacity: 0.8;
    }

    98% {
      opacity: 0;
    }

    100% {
      height: 0;
    }
  }

  .filter-title {
    font-weight: 500;
    margin-bottom: 12px;
    letter-spacing: 0.05rem;
  }

  div.react-select__control {
    font-size: $font-xs;
  }

  .footnote {
    font-size: $font-xs;
    margin-top: 10px;
    font-style: italic;
    color: rgb(130, 130, 130);
  }

  .type-select {
    border-bottom: 1px solid $gray8;
    padding: 0 50px 30px 0;

    @include media-breakpoint-down(md) {
      padding: 0 30px 30px 30px;
    }

    .type-filter {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      padding: 10px 25px 8px 10px;
      cursor: pointer;

      &.active {
        background-color: rgba(126, 126, 126, 0.12);
        border-radius: 3px;
        font-weight: 600;

        svg {
          fill: $blue11;

          path,
          polygon {
            fill: $blue11;
          }
        }
      }

      svg {
        margin-right: 8px;
        width: 18px;
        height: 18px;
      }

      &.Behavior {
        svg {
          height: 16px;
          width: 16px;
          margin-right: 10px;
        }
      }
    }
  }

  .subject-select {
    padding: 15px 50px 10px 0;
  }

  .dataset-filter-select {
    padding: 15px 50px 20px 0;
  }

  .subject-select,
  .dataset-filter-select {
    width: 260px;

    @include media-breakpoint-down(md) {
      width: 250px;
      padding: 30px;
    }
  }
}

.search-label-tooltip {
  span {
    display: flex;
  }

  svg {
    width: 12px;
    height: 12px;
    margin-bottom: 1.5px;

    path {
      fill: #a8a8a8;
    }
  }
}
