.login-page {
  background-color: $gray2;
  padding: 10px 0 60px 0;

  div.large-text {
    text-align: center;
    padding: 40px 15px;
  }

  .login-window {
    margin: 0 auto;
    min-width: 300px;
    min-height: 400px;
    background-image: url("/assets/img/logo@2x.png");
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 480px) {
      width: 100%;
      height: 100%;
    }
  }

  .login-frame {
    height: 100%;
    min-width: 300px;
    min-height: 520px;
    border: 1px solid #dfdfdf;
    overflow-y: hidden;
  }
}
