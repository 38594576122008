.competitor-page {
  padding-top: 40px;

  .competitor-header {
    margin-bottom: 60px;
  }

  h1 {
    display: flex;
    flex-wrap: wrap;

    .hash-logo {
      margin-right: 20px;
    }

    > div {
      display: flex;
      flex-wrap: wrap;
      line-height: $leading-tight;
    }

    .versus {
      margin-right: 15px;
      font-weight: 300;
    }

    @include media-breakpoint-down(sm) {
      .hash-logo {
        margin-bottom: 15px;
      }
    }
  }

  .strapline {
    font-size: $font-lg;

    .sub-strapline {
      margin-top: 5px;
      font-size: $font-base;
      font-weight: 300;
    }
  }
}
