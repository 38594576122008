form.early-access {
  div.signup-status {
    font-size: $font-sm;
  }
  > div {
    margin-top: $space4;
    display: flex;
    flex-direction: row;
    grid-gap: $space2;
    .input-wrapper {
      input {
        height: 50px;
        padding: $space3 $space4;
        border-radius: $border-radius2;
        width: 100%;
        font-size: $font-base;
      }
    }
  }
  button {
    height: 50px;
  }
}
