.fade-enter {
  opacity: 0.01 !important;
}

.fade-enter.fade-enter-active {
  opacity: 1 !important;
  transition: opacity 1s ease-in;
}

.fade-leave {
  opacity: 1 !important;
}

.fade-leave.fade-leave-active {
  opacity: 0.01 !important;
  transition: opacity 1s ease-in;
}

.inspect-items .item.fade-enter.fade-enter-active,
.inspect-items .item.fade-leave.fade-leave-active {
  transition: opacity 0.2s ease-in;
}

.inspect-items .item.fade-leave.fade-leave-active {
  position: absolute;
  top: 2rem;
  left: 1.5rem;
}

.spinner {
  width: 40px;
  height: 40px;

  position: relative;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.testautoprefix2 {
  display: grid;
  display: flex;
}

.fade-enter {
  opacity: 0.01;
  position: relative;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-out;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 500ms ease-out;
}

.slide-fade-enter {
  opacity: 0.01;
  position: relative;
  bottom: -1rem;
}

.slide-fade-enter.slide-fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-out, bottom 500ms ease-out;
  bottom: 0;
}

.slide-fade-leave {
  opacity: 1;
  bottom: 0;
}

.slide-fade-leave.slide-fade-leave-active {
  opacity: 0.01;
  bottom: -1rem;
  transition: opacity 500ms ease-out, bottom 500ms ease-in;
}

.animated-ellipsis {
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

  span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  span:nth-child(2) {
    animation-delay: 0.2s;
  }
  span:nth-child(3) {
    animation-delay: 0.4s;
  }
}
