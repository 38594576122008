.add-scraper-form {
  padding: 60px;

  h2 {
    margin-bottom: 20px;
  }

  .repo-chooser.project-form {
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;

    label {
      display: flex;
      align-items: center;

      .error-text {
        margin-left: 10px;
      }
    }

    .input-group {
      margin-bottom: 0;

      .input {
        width: 300px;
      }

      .input.error {
        width: 300px;
      }
    }

    button {
      margin-left: 30px;
      height: 48px;
    }
  }

  form .input-group label {
    color: white;
    text-transform: none;
    font-size: $font-base;
    font-weight: 400;
  }

  .input-group textarea {
    height: 300px;
  }

  .input-groups {
    max-width: 650px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .input {
      height: 50px;
      width: 300px;

      &.description {
        max-width: 100%;
      }
    }
  }

  .input-group div.dropdown-wrapper {
    width: 300px;
  }

  .add-scraper-files {
    margin-bottom: 60px;

    .MuiTreeView-root {
      padding: 15px 20px;
      border: 1px solid #32343a;
      border-radius: 8px;

      .MuiTypography-body1 {
        font-size: $font-sm;
      }

      .MuiTreeItem-root {
        padding: 6px;
        min-width: 300px;

        &.Mui-expanded {
          padding-bottom: 0;
        }
      }

      .MuiTreeItem-content {
        max-width: 600px;
      }

      .Mui-selected {
        background: green;

        > .MuiTreeItem-content .MuiTreeItem-label {
          background-color: inherit;
        }
      }

      .MuiTreeItem-iconContainer {
        align-items: center;
        margin-right: 10px;
      }
    }
  }

  .frequency-and-submit {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
  }
}
