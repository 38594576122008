.project-changelog {
  .version-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 40px;

    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
      margin-bottom: 30px;

      .release-and-date {
        margin-bottom: 10px;
      }
    }

    .label {
      min-width: 100px;
      max-width: 100px;
      margin-right: 40px;
      margin-bottom: 4px;

      @include media-breakpoint-down(xs) {
        max-width: 100%;
        padding-bottom: 5px;
      }
    }

    .changelog-note {
      font-weight: 400;
      text-align: left;
      width: 100%;
    }

    .changelog-note > .markdown-display > *:first-child {
      margin-top: 0px;
    }
  }
}
