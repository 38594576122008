.private-user-info-edit {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  .loading-indicator {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  label {
    display: block;
    margin-bottom: 6px;
  }

  .email-addresses {
    width: 50%;
    margin-bottom: 30px;

    .email-address {
      font-weight: 200;
      font-size: $font-sm;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      order: 0;
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
      margin-bottom: 25px;
    }
  }

  .phone-numbers {
    width: 45%;
    margin-bottom: 40px;

    @include media-breakpoint-down(md) {
      order: 2;
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
      margin-bottom: 25px;
    }
  }
}
