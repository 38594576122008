.card {
  background-color: $white;
  padding: 1.5rem;
  border: 0.05rem solid #f3efef;
  border-radius: 0.5rem;
  > :first-child {
    margin-top: 0;
  }

  > h3:first-child {
    display: inline;
  }

  transform: scale(1);
  transition: all 200ms;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.05);
  &:hover {
    background-color: $white;
    cursor: pointer;
    transform: scale(1.05);
    transition: all 200ms;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.05);
  }
  &:active,
  &.selected {
    background-color: #f5f9fc;
    transition: all 100ms;
    transform: scale(0.975);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.05);
  }

  .triangle {
    display: inline-block;
    top: -1rem;
    right: -0.25rem;
    position: relative;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-top: 0.5rem solid transparent;
    transition: border-color 0.2s;
    transform: rotate(-135deg);
    &.purple {
      border-top: 0.5rem solid $purple10;
    }
    &.pink {
      border-top: 0.5rem solid $pink9;
    }
    &.blue {
      border-top: 0.5rem solid $blue11;
    }
  }

  &.job-card {
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.05);
    &:hover {
      cursor: unset;
      transform: unset;
      box-shadow: 0 0 2rem rgba(0, 0, 0, 0.05);
    }

    &:active {
      background-color: inherit;
      transform: inherit;
    }

    h5 {
      margin-bottom: 0.75rem;
    }

    > .job {
      display: flex;

      > .location {
        margin-left: 1rem;
      }

      > p {
        margin: 0.25rem 0;
      }
    }
  }
}
