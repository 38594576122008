// ––––––––––– Typography sizes ––––––––––––– //

$font-xs: clamp(0.8rem, calc(0.17vw + 0.76rem), 0.85rem);
$font-sm: clamp(0.85rem, calc(0.17vw + 0.76rem), 0.9rem);
$font-base: clamp(0.95rem, calc(0.34vw + 0.91rem), 1.05rem);
$font-md: clamp(1.15rem, calc(0.7vw + 1rem), 1.33rem);
$font-lg: clamp(1.33rem, calc(1.22vw + 1.14rem), 2rem);
$font-xl: clamp(1.73rem, calc(1.97vw + 1.23rem), 2.81rem);
$font-xxl: clamp(2.07rem, calc(3.05vw + 1.31rem), 3.75rem);
$font-xxxl: clamp(2.49rem, calc(4.56vw + 1.35rem), 5rem);

// ––––––––––– Line heights ––––––––––––– //

$leading-tight: 1;
$leading-snug: 1.25;
$leading-base: 1.5;
$leading-loose: 1.75;

// ––––––––––– Spacing ––––––––––––– //

$space1: calc(1rem * 0.25);
$space2: calc(1rem * 0.5);
$space3: calc(1rem * 0.75);
$space4: 1rem;
$space5: calc(1rem * 1.5);
$space6: calc(1rem * 2);
$space7: calc(1rem * 3);
$space8: calc(1rem * 4.5);
$space9: calc(1rem * 6);
$space10: calc(1rem * 10);

// ––––––––––– Transitions ––––––––––––– //

$transition-base: all 0.33s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.45s ease-in-out;

// ––––––––––– Colors ––––––––––––– //

// Gray
$white: #fff;
$gray1: hsl(206, 32%, 98.8%);
$gray2: hsl(210, 20%, 98%);
$gray3: hsl(209, 18%, 95.3%);
$gray4: hsl(209, 16%, 93.2%);
$gray5: hsl(208, 13%, 91.1%);
$gray6: hsl(208, 13%, 88.9%);
$gray7: hsl(207, 13%, 85.9%);
$gray8: hsl(205, 13%, 68%);
$gray9: hsl(206, 13%, 56.1%);
$gray10: hsl(206, 13%, 52.3%);
$gray11: hsl(206, 13%, 43.5%);
$gray12: hsl(200, 17%, 19%);
$gray13: hsl(206, 19%, 12%);
$gray14: hsl(210, 23%, 9%);

// Blue
$blue1: hsl(206, 100%, 99.2%);
$blue2: hsl(210, 100%, 98%);
$blue3: hsl(209, 100%, 96.5%);
$blue4: hsl(210, 98.8%, 94%);
$blue5: hsl(209, 95%, 90.1%);
$blue6: hsl(209, 81.2%, 84.5%);
$blue7: hsl(208, 77.5%, 76.9%);
$blue8: hsl(206, 81.9%, 65.3%);
$blue9: hsl(206, 100%, 50%);
$blue10: hsl(208, 100%, 47.3%);
$blue11: hsl(211, 100%, 43.2%);
$blue12: hsl(211, 100%, 15%);

// Purple
$purple1: hsl(255, 65%, 99.4%);
$purple2: hsl(252, 100%, 99%);
$purple3: hsl(252, 96.9%, 97.4%);
$purple4: hsl(252, 91.5%, 95.5%);
$purple5: hsl(252, 85.1%, 93%);
$purple6: hsl(252, 77.8%, 89.4%);
$purple7: hsl(252, 71%, 83.7%);
$purple8: hsl(252, 68.6%, 76.3%);
$purple9: hsl(252, 71%, 61%);
$purple10: hsl(251, 59%, 55%);
$purple11: hsl(250, 54%, 43%);
$purple12: hsl(254, 60%, 18.5%);

// Red
$red1: hsl(359, 100%, 99.4%);
$red2: hsl(359, 100%, 98.6%);
$red3: hsl(360, 100%, 96.8%);
$red4: hsl(360, 97.9%, 94.8%);
$red5: hsl(360, 90.2%, 91.9%);
$red6: hsl(360, 81.7%, 87.8%);
$red7: hsl(359, 74.2%, 81.7%);
$red8: hsl(359, 69.5%, 74.3%);
$red9: hsl(358, 75%, 59%);
$red10: hsl(358, 69.4%, 55.2%);
$red11: hsl(358, 65%, 48.7%);
$red12: hsl(354, 50%, 14.6%);

// Orange
$orange1: hsl(39, 70%, 99%);
$orange2: hsl(40, 100%, 96.5%);
$orange3: hsl(44, 100%, 91.7%);
$orange4: hsl(43, 100%, 86.8%);
$orange5: hsl(42, 100%, 81.8%);
$orange6: hsl(38, 99.7%, 76.3%);
$orange7: hsl(36, 86.1%, 67.1%);
$orange8: hsl(35, 85.2%, 55.1%);
$orange9: hsl(39, 100%, 57%);
$orange10: hsl(35, 100%, 55.5%);
$orange11: hsl(30, 100%, 34%);
$orange12: hsl(20, 80%, 17%);

// Green
$green1: hsl(85, 50%, 98.7%);
$green2: hsl(85, 66.7%, 96.5%);
$green3: hsl(85, 76%, 92.3%);
$green4: hsl(84, 75.3%, 87.5%);
$green5: hsl(84, 71.5%, 81.9%);
$green6: hsl(82, 65%, 74.6%);
$green7: hsl(79, 53.2%, 61.8%);
$green8: hsl(76, 61.7%, 45.1%);
$green9: hsl(81, 67%, 50%);
$green10: hsl(80, 68.3%, 46.9%);
$green11: hsl(75, 80%, 26%);
$green12: hsl(78, 70%, 11.5%);

// Pink
$pink1: hsl(332, 100%, 99.4%);
$pink2: hsl(330, 100%, 98.4%);
$pink3: hsl(331, 85.6%, 96.6%);
$pink4: hsl(331, 78.1%, 94.2%);
$pink5: hsl(332, 72.1%, 91.1%);
$pink6: hsl(333, 67%, 86.7%);
$pink7: hsl(335, 63.5%, 80.4%);
$pink8: hsl(336, 62.3%, 72.9%);
$pink9: hsl(336, 80%, 57.8%);
$pink10: hsl(336, 73.7%, 53.5%);
$pink11: hsl(336, 75%, 47.2%);
$pink12: hsl(340, 65%, 14.5%);

// ––––––––––– Border Radius ––––––––––––– //

$border-radius1: 0.25rem;
$border-radius2: 0.33rem;
$border-radius3: 0.5rem;
$border-radius4: 1rem;

// ––––––––––– Box shadows ––––––––––––– //

$shadow-low: 0.3px 2.2px 2px hsla(206, 8%, 74%, 0.19),
  0.7px 4px 5px hsl(206, 8%, 84%, 0.29);
$shadow-medium: 0.3px 2.2px 2.7px hsla(206, 8%, 84%, 0.096),
  0.7px 5.6px 6.9px hsl(206, 8%, 84%, 0.18),
  1.4px 11.5px 14.2px hsl(206, 8%, 84%, 0.25);
$shadow-high: 0.3px 2.2px 2.7px hsla(206, 8%, 84%, 0.066),
  0.7px 5.6px 6.9px hsl(206, 8%, 84%, 0.085),
  1.4px 11.5px 14.2px hsl(206, 8%, 84%, 0.1),
  2.9px 23.7px 20px hsl(206, 8%, 84%, 0.18),
  4px 20px 34px hsl(206, 8%, 84%, 0.2);
$shadow-low-blue: 0.3px 2.2px 2.7px hsla(206, 6%, 54%, 0.19),
  0.7px 5.6px 6.9px hsl(211, 89%, 54%, 0.29);
$shadow-medium-blue: 0.3px 2.2px 2.7px hsla(211, 89%, 54%, 0.096),
  0.7px 5.6px 6.9px hsl(211, 89%, 54%, 0.18),
  1.4px 11.5px 14.2px hsl(211, 89%, 54%, 0.25);
$shadow-high-blue: 0.3px 2.2px 2.7px hsla(211, 89%, 54%, 0.066),
  0.7px 5.6px 6.9px hsl(211, 89%, 54%, 0.085),
  1.4px 11.5px 14.2px hsl(211, 89%, 54%, 0.1),
  2.9px 23.7px 17px hsl(211, 89%, 54%, 0.18),
  4px 16px 30px hsl(211, 89%, 54%, 0.2);

// ––––––––––– Legacy styles ––––––––––––– //

// Colors from Figma design system
$color-primary: #060f2c;

$green-main: #4bb84d;
$blue-dark: #2f444e;
$blue-mute: #3a404e;
$grey: hsl(202, 13%, 87%);

$hot-pink: #f10040;
$hot-pink-hover: #ff4476;

$green: #0fac33;
$green-hover: #33cd56;

:export {
  // ––––––––––– Typography sizes ––––––––––––– //
  fontXs: $font-xs;
  fontSm: $font-sm;
  fontBase: $font-base;
  fontMd: $font-md;
  fontLg: $font-lg;
  fontXl: $font-xl;
  fontXxl: $font-xxl;
  fontXxxl: $font-xxxl;
  // ––––––––––– Line heights ––––––––––––– //
  leadingTight: $leading-tight;
  leadingSnug: $leading-snug;
  leadingBase: $leading-base;
  leadingLoose: $leading-loose;
  // ––––––––––– Spacing ––––––––––––– //
  space1: $space1;
  space2: $space2;
  space3: $space3;
  space4: $space4;
  space5: $space5;
  space6: $space6;
  space7: $space7;
  space8: $space8;
  space9: $space9;
  space10: $space10;
  // ––––––––––– Transitions ––––––––––––– //
  transitionBase: $transition-base;
  transitionFast: $transition-fast;
  transitionSlow: $transition-slow;
  // ––––––––––– Colors ––––––––––––– //

  // Gray
  gray1: $gray1;
  gray2: $gray2;
  gray3: $gray3;
  gray4: $gray4;
  gray5: $gray5;
  gray6: $gray6;
  gray7: $gray7;
  gray8: $gray8;
  gray9: $gray9;
  gray10: $gray10;
  gray11: $gray11;
  gray12: $gray12;
  gray13: $gray13;
  gray14: $gray14;

  // Blue
  blue1: $blue1;
  blue2: $blue2;
  blue3: $blue3;
  blue4: $blue4;
  blue5: $blue5;
  blue6: $blue6;
  blue7: $blue7;
  blue8: $blue8;
  blue9: $blue9;
  blue10: $blue10;
  blue11: $blue11;
  blue12: $blue12;

  // Purple
  purple1: $purple1;
  purple2: $purple2;
  purple3: $purple3;
  purple4: $purple4;
  purple5: $purple5;
  purple6: $purple6;
  purple7: $purple7;
  purple8: $purple8;
  purple9: $purple9;
  purple10: $purple10;
  purple11: $purple11;
  purple12: $purple12;

  // Red
  red1: $red1;
  red2: $red2;
  red3: $red3;
  red4: $red4;
  red5: $red5;
  red6: $red6;
  red7: $red7;
  red8: $red8;
  red9: $red9;
  red10: $red10;
  red11: $red11;
  red12: $red12;

  // Orange
  orange1: $orange1;
  orange2: $orange2;
  orange3: $orange3;
  orange4: $orange4;
  orange5: $orange5;
  orange6: $orange6;
  orange7: $orange7;
  orange8: $orange8;
  orange9: $orange9;
  orange10: $orange10;
  orange11: $orange11;
  orange12: $orange12;

  // Green
  green1: $green1;
  green2: $green2;
  green3: $green3;
  green4: $green4;
  green5: $green5;
  green6: $green6;
  green7: $green7;
  green8: $green8;
  green9: $green9;
  green10: $green10;
  green11: $green11;
  green12: $green12;

  // Pink
  pink1: $pink1;
  pink2: $pink2;
  pink3: $pink3;
  pink4: $pink4;
  pink5: $pink5;
  pink6: $pink6;
  pink7: $pink7;
  pink8: $pink8;
  pink9: $pink9;
  pink10: $pink10;
  pink11: $pink11;
  pink12: $pink12;

  // ––––––––––– Border Radius ––––––––––––– //

  borderRadius1: $border-radius1;
  borderRadius2: $border-radius2;
  borderRadius3: $border-radius3;
  borderRadius4: $border-radius4;

  // ––––––––––– Box shadows ––––––––––––– //

  shadowLow: $shadow-low;
  shadowMedium: $shadow-medium;
  shadowHigh: $shadow-high;
  shadowLowBlue: $shadow-low-blue;
  shadowMediumBlue: $shadow-medium-blue;
  shadowHighBlue: $shadow-high-blue;
}
