.competitor-footer-banner {
  width: 100%;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.02) 50%, $gray3 50%);

  @include media-breakpoint-down(lg) {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 50%, $gray3 50%);
  }

  @include media-breakpoint-down(xs) {
    background: none;
  }

  .container.both-banners {
    display: flex;

    @include media-breakpoint-down(lg) {
      max-width: 100%;
      flex-direction: column;
      padding: 0;
    }
  }

  .container.learn-more,
  .container.thinking-about-switching {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;

    @include media-breakpoint-up(xl) {
      padding: 0;
    }
  }

  h4 {
    width: 100%;
    font-size: 26px;
    font-weight: 800;
    margin: 0;
  }

  p {
    font-weight: 400;
    max-width: 450px;
    margin-top: 25px;
    margin-bottom: 20px;
    line-height: $leading-base;
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    button {
      height: 60px;
      padding: 0 20px;
      font-size: $font-base;

      svg {
        margin-right: 0;
        margin-left: 15px;
      }
    }

    .black-button {
      margin-right: 25px;

      &:not(:hover) {
        background: black;
      }
    }

    .white-button {
      box-shadow: 0px 0px 20px $gray3;
      border: 1px solid #eeeeee;
      margin-right: 15px;
    }

    .black-button,
    .white-button {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(xs) {
      display: block;

      .black-button,
      .white-button {
        margin-bottom: 15px;
      }
    }
  }

  .content-left {
    width: 47%;
    padding: 60px 60px 60px 0;

    @include media-breakpoint-down(xs) {
      background: rgba(0, 0, 0, 0.02);
    }
  }

  .content-right {
    width: 53%;
    background: $gray3;
    padding: 60px 0 60px 60px;

    .container.thinking-about-switching {
      height: 100%;
    }
  }

  .content-left,
  .content-right {
    @include media-breakpoint-down(lg) {
      padding: 60px 0;
      width: 100%;
    }
    @include media-breakpoint-down(xs) {
      padding: 60px 0;
    }
  }

  @media (max-width: 370px) {
    a:not(.text-link),
    button {
      width: 100%;
    }

    .desktop-only {
      display: none;
    }
  }
}
