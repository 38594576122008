.coronavirus-page {
  .mainsite-header {
    background-image: linear-gradient(to bottom, black 5%, rgba(0, 0, 0, 0) 35%),
      url("/assets/coronavirus-banner.jpg");
    color: white;
    background-size: cover;
    @include media-breakpoint-down(md) {
      background-position-x: -40rem;
    }
    h1 {
      margin-top: 0;
    }
  }
  .page-content {
    > video,
    img {
      width: 100%;
      margin-bottom: 30px;
    }
    .intro-content {
      > div {
        @include media-breakpoint-up(lg) {
          &.left {
            flex-basis: 30%;
            max-width: 30%;
            padding-right: 30px;
          }
          &.middle {
            flex-basis: 30%;
            max-width: 30%;
            padding-left: 30px;
            padding-right: 30px;
          }
          &.right {
            padding-left: 30px;
            flex-basis: 40%;
            max-width: 40%;
          }
        }
        > :first-child {
          margin-top: 0;
        }
        position: relative;
        video,
        img {
          width: 100%;
        }
      }
    }
    .model {
      position: relative;
      margin-top: 5rem;
      display: flex;
      align-items: center;
      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: flex-start;
      }
      @include media-breakpoint-down(sm) {
        margin-top: 2.5rem;
      }
      > video,
      img {
        max-width: 25rem;
        @include media-breakpoint-down(md) {
          max-width: unset;
          width: 100%;
          margin-bottom: 15px;
        }
      }
      &.left-layout > div {
        margin-left: 5rem;
        @include media-breakpoint-down(md) {
          margin-left: 0;
        }
      }
      &.right-layout > div {
        margin-right: 5rem;
        @include media-breakpoint-down(md) {
          margin-right: 0;
        }
      }
      > div {
        h2 {
          margin-top: 0;
        }
        flex-grow: 1;
      }
    }
    h2 {
      text-transform: uppercase;
      font-weight: 800;
      > span {
        font-size: 1.5rem;
        font-weight: 400;
        font-style: italic;
        color: #425369;
        margin-left: 1.5rem;
        position: relative;
        top: -2.5px;
      }
    }

    p a:hover {
      border-color: $blue11;
      color: $blue11;
    }

    a {
      button {
        margin-top: 15px;
      }
    }
  }
}
