.make-payment {
  padding: 0 10px;

  .input,
  .secondary-button,
  div.react-select__control {
    height: 48px;
  }

  .small-text {
    margin-bottom: 15px;
  }

  .select-existing {
    display: flex;
    flex-wrap: wrap;

    .dropdown-wrapper {
      width: 260px;
    }

    .secondary-button {
      margin-left: 15px;
      width: 120px;
    }

    .small-text {
      display: flex;
      width: 100%;

      .checkbox .text {
        font-weight: 600;
        margin-left: 25px;
      }
    }
  }

  .register-new-card {
    margin-top: 40px;
    max-width: 320px;

    .secondary-button {
      margin-top: 30px;
    }
  }

  button {
    font-weight: 600;
  }

  @include media-breakpoint-down(xs) {
    .select-existing .secondary-button {
      width: 100%;
      margin-top: 20px;
      margin-left: 0;
    }

    .select-existing .dropdown-wrapper {
      width: 100%;
    }
  }
}
