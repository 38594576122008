.roadmap-page {
  .col-md-6 {
    @include media-breakpoint-up(lg) {
      &:first-child {
        padding-right: 60px;
      }
      &:last-child {
        padding-left: 60px;
      }
    }
  }

  h3 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}
