.primary-button {
  background: $blue10;
  border-radius: $border-radius2;
  font-size: $font-base;
  font-weight: 500;
  line-height: 1.2;
  color: $white;
  transition: $transition-base;
  padding: $space4 $space5;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;

  svg,
  svg path {
    fill: $white;
  }

  &.gradient-bg {
    background: linear-gradient(168deg, $blue9 12%, $blue11 154.83%);
  }

  &:disabled {
    cursor: not-allowed;
    background: $gray7;

    &:hover {
      color: $white;
    }
  }

  &:not([disabled]):not(.red):hover {
    box-shadow: $shadow-medium-blue;
    color: $white;
    opacity: 0.95;
    svg,
    svg path {
      fill: $white;
    }
  }

  &:not(.gradient-bg):not([disabled]):not(.red):hover {
    background: $purple9;
  }

  &.svg {
    svg {
      margin-left: 30px;
      margin-right: 0;
      height: 16px;
      width: 16px;
    }
  }

  @media (max-width: 400px) {
    padding: 0 25px;

    svg {
      margin-left: 20px;
    }
  }
}

.secondary-button {
  background: $blue11;
  border-radius: 6px;
  height: 40px;
  font-size: $font-xs;
  color: white;
  transition: $transition-base;
  padding: 0 20px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not([disabled]):hover {
    background: $purple10;
    box-shadow: $shadow-high-blue;
    color: white;
  }

  &:disabled {
    background: #f0f3f5;
    color: #cbd2d6;
    border: 1px solid $gray8;
    cursor: not-allowed;
  }
}

.green-button {
  background: linear-gradient(180deg, #14a953 0%, #2ab967 100%);
  color: white;
  transition: $transition-base;
  padding: 0 25px;
  font-weight: 500;
  font-size: $font-base;

  svg {
    fill: white;

    path {
      fill: white;
    }
  }

  &:not([disabled]):hover {
    background: linear-gradient(180deg, #14a953 0%, #2ab967 100%);
    box-shadow: none;
    opacity: 0.85;
  }
}

.white-button {
  background-color: white;
  color: $gray14;
  border-radius: 6px;
  border: 1px solid transparent;
  height: 60px;
  padding: 0 25px;
  transition: $transition-base;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: not-allowed;
  }

  &:not(.discord) svg {
    fill: $gray14;
    transition: $transition-base;

    path {
      fill: $gray14;
      transition: $transition-base;
    }
  }

  &:not([disabled]):hover {
    color: white;
    border: 1px solid white;
    background-color: $gray14;

    svg {
      fill: white;

      path {
        fill: white;
      }
    }
  }

  &.svg {
    svg {
      margin-left: 20px;
      height: 16px;
      width: 16px;
    }
  }

  @media (max-width: 400px) {
    padding: 0 25px;
  }
}

.black-button {
  background-color: $gray14;
  color: $white;
  border-radius: 6px;
  border: 1px solid transparent;
  height: 60px;
  padding: 0 40px;
  transition: $transition-base;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: not-allowed;
  }

  &.svg {
    svg {
      margin-left: 20px;
      height: 16px;
      width: 16px;
      fill: white;
      transition: $transition-base;

      path {
        fill: white;
        transition: $transition-base;
      }
    }
  }

  &:not([disabled]):hover {
    color: $gray14;
    border: 1px solid $gray8;
    background-color: white;

    svg {
      fill: $gray14;

      path {
        fill: $gray14;
      }
    }
  }

  @media (max-width: 400px) {
    padding: 0 25px;
  }
}

.red-button {
  border: 1px solid transparent;
  background-color: $red11;
  color: white;
  border-radius: 6px;
  height: 42px;
  padding: 0 25px;
  transition: all 0.2s;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  font-size: $font-xs;
  text-transform: uppercase;

  &.dark {
    background: rgb(165, 14, 3);
  }

  &:not([disabled]):hover {
    color: white;
    background-color: $red11;
  }

  &:disabled,
  &.disabled,
  &.disabled:hover {
    background: $gray7;
    color: gray12;
    cursor: not-allowed;
  }

  svg {
    fill: white;

    path {
      fill: white;
    }
  }
}

.blue-button {
  border-radius: 8px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 25px;
  height: 48px;
  transition: all 0.2s;
  color: $white;

  @include media-breakpoint-down(xs) {
    padding: 6px 20px;
  }

  svg {
    transition: $transition-base;
    fill: white;
    margin-left: 10px;

    path {
      fill: white;
      transition: 0.2s;
    }
  }
}

button.blue,
.blue-button {
  background-color: $blue11;

  &:hover {
    color: $blue11;
    background-color: $white;

    svg path {
      fill: $blue11;
    }
  }
}

button.blue-outline {
  border: 1px solid $blue11;
  color: $blue11;
  background-color: white;
  transition: color 0.2s, background-color 0.2s;
  font-weight: 600;
  font-size: $font-sm;
  padding: 0 12px;

  svg {
    fill: $blue11;
    transition: fill 0.1s;
    width: 11px;
    height: 11px;
    margin-right: 0;

    path {
      fill: $blue11;
      transition: fill 0.1s;
    }
  }

  &:not(:disabled):hover {
    background-color: $blue11;
    box-shadow: none;
    color: white;

    svg {
      fill: white;

      path {
        fill: white;
      }
    }
  }
}

button.grey-outline {
  border: 1px solid #575e62;
  color: #575e62;
  background-color: white;
  transition: color 0.2s, background-color 0.2s;
  font-weight: 600;
  font-size: $font-sm;
  padding: 0 12px;

  svg {
    fill: #575e62;
    transition: fill 0.1s;
    width: 11px;
    height: 11px;
    margin-right: 0;

    path {
      fill: #575e62;
      transition: fill 0.1s;
    }
  }

  &:not(:disabled):hover {
    background-color: #575e62;
    box-shadow: none;
    color: white;

    svg {
      fill: white;

      path {
        fill: white;
      }
    }
  }
}

.full-width-blue-button {
  width: 100%;
  color: $blue11;
  background: white;
  padding: 25px 15px;
  border-radius: 12px;
  font-size: $font-md;
  border: 1px solid $blue11;
  transition: opacity 0.2s;
  margin: 20px 0;

  &:hover {
    opacity: 0.7;
    color: $blue11;
  }
}
