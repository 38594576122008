.about-page {
  .about-content {
    > :first-child {
      margin-top: 5rem;
      @include media-breakpoint-down(sm) {
        margin-top: 2.5rem;
      }
      > :first-child {
        margin-top: 0;
        > :first-child {
          margin-top: 0;
        }
      }
    }
    > :last-child {
      margin-bottom: 5rem;
      @include media-breakpoint-down(sm) {
        margin-bottom: 2.5rem;
      }
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include media-breakpoint-up(xl) {
      padding-right: 60px;
    }
  }

  .right {
    @include media-breakpoint-up(xl) {
      padding-left: 60px;
    }
  }

  .addresses {
    display: flex;
    > .address {
      &:first-child {
        margin-right: 5rem;
      }
    }
  }

  .platform-item {
    display: flex;
    align-items: flex-start;

    &:first-child {
      margin-top: 1em;
    }

    img {
      position: relative;
      width: 2rem;
      margin-right: 15px;
    }

    > span {
      margin-left: 0.5rem;
      p.title {
        font-weight: 600;
        color: $blue11;
        transition: color 0.3s;
        margin: 0;
      }

      p {
        margin-top: 0;
        a {
          transition: border-color 0.3s;
        }
      }
    }
  }

  .link.platform-item:hover {
    cursor: pointer;
    p.title {
      color: $blue11;
    }
    a {
      color: $blue11;
      border-color: $blue11;
    }
  }
}
