.featured-projects-wrapper {
  overflow: hidden;
  .featured-projects {
    width: 100%;
    display: flex;
  }
  &.carousel {
    .featured-projects {
      position: relative;
      left: calc(-1 * (100vw / 4));
      width: calc(100% + (100vw / 4));
      @include media-breakpoint-down(lg) {
        left: calc(-1 * (100vw / 3));
        width: calc(100% + (100vw / 3));
      }
      @include media-breakpoint-down(sm) {
        left: calc(-1 * (100vw / 2));
        width: calc(100% + (100vw / 2));
      }
      @media screen and (max-width: 350px) {
        left: -100vw;
        width: calc(100% + 100vw);
      }
    }
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.featured-project {
  &:nth-child(odd) {
    background: #1d243a;
  }
  &:nth-child(even) {
    background: #10172e;
  }
  height: calc(100vw / 4);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    height: calc(100vw / 3);
  }

  @include media-breakpoint-down(sm) {
    height: calc(100vw / 2);
  }

  @media screen and (max-width: 350px) {
    height: 100vw;
  }

  .shadow {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 90px 60px -60px #000, inset 0px -100px 60px -60px #000;
  }

  > .content {
    > .description {
      z-index: 2;
      position: relative;
      padding: 1rem;
      color: white;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 50%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  &:hover {
    .project-avatar {
      transform: scale(1.1);
    }
  }

  .project-avatar {
    transition: transform 0.3s;
    transform: scale(1);
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
    height: 100%;
    > video {
      object-fit: cover;
    }
  }

  span {
    position: relative;
    transition: left 0.2s;
    left: 0;
  }
}

.featured-projects-mobile {
  margin-bottom: 30px;

  h4 {
    font-size: $font-lg;
    font-weight: 600;
    margin-top: 0;
  }

  > div:not(:last-child) {
    margin-bottom: 20px;
  }

  .featured-project-title {
    font-weight: 600;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }
}

.featured-project,
.featured-projects-mobile {
  .featured-project-title.turquoise {
    color: #0eb3de;
  }

  .featured-project-title.bright-blue {
    color: $blue11;
  }

  .featured-project-title.purple {
    color: #816fff;
  }

  .featured-project-title.pink {
    color: #db73ff;
  }
}
