.project-source {
  margin-bottom: 50px;

  .dropdown-wrapper,
  .version-source {
    max-width: 100%;

    @include media-breakpoint-down(sm) {
      max-width: calc(100vw - 30px);
    }
  }

  p {
    font-size: $font-sm;
    font-weight: 400;
    margin-bottom: 30px;
  }

  .file-select {
    margin: 10px 0;
  }

  .loading-indicator {
    margin-top: 40px;
  }

  pre {
    margin: 40px 0;
  }
}
