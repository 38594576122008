.account-dropdown,
.create-dropdown {
  position: absolute;
  top: -23px;
  right: 0;
  z-index: 15;
  color: black;

  .dropdown-container {
    position: relative;
  }

  img.avatar {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: 1px solid $gray8;
    object-fit: cover;
    margin-right: 10px;
  }

  .account-dropdown-menu,
  .create-dropdown-menu {
    padding-top: 30px;
    width: auto;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: transparent;
    background-color: white;
    border-color: $gray7;
  }

  .account-autoheight {
    position: absolute;
    right: -7px;
  }

  .create-autoheight {
    position: absolute;
    right: -2px;
  }

  .account-button {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    border-radius: 0;
    background: transparent;
    font-weight: 600;
    display: flex;
    line-height: $leading-base;
    align-items: center;
    transition: opacity 0.2s;
    padding: 5px 10px;
    z-index: 15;

    &:hover {
      opacity: 0.8;
      color: black;
    }

    &:focus {
      outline: 0;
    }

    svg {
      height: 10px;
      width: 10px;
      margin-left: 5px;
      transition: opacity 0.2s;
      fill: black;
    }
  }

  span {
    color: black;
  }

  .logout-row,
  .create-org-row {
    padding: 10px 15px;
    border-top: 1px solid #e5e5e5;
    background: $gray2;

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;

      opacity: 0.8 !important;

      &:hover {
        // Needs important because it conflicts with _NavHeader.scss
        opacity: 1 !important;
        color: black;
      }

      svg {
        path {
          fill: #86888d;
        }
      }
    }
  }

  .logout-row {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .create-org-row {
    svg {
      margin-top: -1px;
      margin-right: -2px;
    }
  }

  .account-dropdown-links,
  .create-dropdown-links {
    margin-top: 15px;
    padding: 0px 15px 6px 25px;
    text-align: right;

    .account-dropdown-link {
      font-weight: 400;
      display: inline-block;

      margin-bottom: 10px;
      opacity: 0.8;
      transition: border 0.2s, opacity 0.2s;
      cursor: pointer;

      &:hover {
        opacity: 1;
        color: black;
      }
    }
  }

  .account-dropdown-links {
    padding: 0px 40px 6px 15px;
    text-align: left;
  }
}

.create-dropdown {
  right: 150px;

  // needed to display the modal correctly
  z-index: 15;
}
