.simple-dropdown {
  position: absolute;
  right: 0;

  button {
    height: 40px;
    border-radius: 8px;
    padding: 0;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: $font-sm;

    .main-button {
      display: flex;
      align-items: center;
      padding: 15px;
    }

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      outline: 0;
    }
  }

  &.onMainClick {
    button:hover {
      opacity: 1;
    }

    .main-button:hover {
      opacity: 0.8;
    }

    .dropdown-icon:hover {
      opacity: 0.8;
    }
  }

  &.blue {
    button {
      background-color: $blue11;
    }

    .simple-dropdown-menu {
      background-color: #e8eff8c7;
    }
  }

  &.white {
    button {
      background-color: $blue11;
    }

    .simple-dropdown-menu {
      background-color: white;
      border: 1px solid $gray6;
      color: black;
    }
  }

  .dropdown-icon {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 12px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);

    svg {
      height: 10px;
      width: 10px;
      fill: white;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .icon svg {
    height: 30px;
    width: 30px;
    margin-right: 6px;
    fill: initial;
  }

  .simple-dropdown-menu {
    width: 100%;
    left: 0;
    border-radius: 6px;
    border: 1px solid white;
    backdrop-filter: blur(8px);
  }

  .simple-dropdown-links {
    z-index: 100;
    max-height: 130px;
    overflow-y: auto;

    strong {
      font-weight: 600;
    }

    .simple-dropdown-item {
      font-size: $font-xs;
      font-weight: 300;

      &.separator {
        margin-top: 6px;
        border-top: 1px solid white;
      }

      a {
        cursor: pointer;
        transition: color 0.2s;
        display: block;
        padding: 9px 15px;

        &:hover {
          color: $blue11;
        }
      }

      &:first-child a {
        padding-top: 12px;
      }

      &:last-child a {
        padding-bottom: 12px;
      }
    }
  }
}
