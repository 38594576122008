.billing {
  .billing-summary {
    display: flex;
    padding: 0 10px;
    margin-bottom: 40px;

    .billing-headline {
      &:not(:last-child) {
        margin-right: 90px;
      }

      .label {
        font-size: $font-xs;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 5px;
      }

      .payment-card {
        margin-top: 8px;
      }

      .text-link {
        margin-left: 5px;
      }

      .secondary-button {
        font-weight: 600;
      }
    }

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;

      .billing-headline {
        margin-bottom: 30px;
      }
    }

    @include media-breakpoint-down(xs) {
      justify-content: space-between;

      .billing-headline:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .normal-text {
    padding: 0 10px;
  }

  .invoices {
    padding: 0 10px;

    .small-text {
      margin-bottom: 20px;
      text-transform: uppercase;
    }
  }
}

.card-logo {
  height: 24px;
  width: 38px;
  object-fit: contain;

  svg {
    height: 25px;
    width: 40px;
  }
}
