.profile-header {
  background-color: #fdfdfd;
  border-bottom: 1px solid $gray6;
  width: 100%;

  .profile-header-content {
    padding: 50px 15px;
    display: grid;
    grid-template-columns: 1fr auto;

    @include media-breakpoint-down(sm) {
      padding: 30px 15px;
      flex-wrap: wrap;
      grid-template-columns: auto;
    }

    .profile-avatar-info {
      display: flex;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }

    .profile-avatar {
      min-width: 120px;
      min-height: 120px;
      position: relative;
      border-radius: 7px;
      border: 1px solid #ededed;

      img,
      svg {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-position: top;
        border-radius: 7px;
        object-fit: contain;
      }

      &.user {
        img,
        svg {
          object-fit: cover;
        }
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
        margin: 0 auto;
      }
    }

    .profile-info {
      margin-left: 40px;
      width: 100%;

      .profile-name {
        margin-top: 0;
        margin-bottom: 0;

        @include media-breakpoint-down(sm) {
          margin-top: 15px;
        }
      }

      .profile-name-loading {
        width: 30%;

        @include media-breakpoint-down(sm) {
          margin: 0 auto;
        }
      }

      @include media-breakpoint-down(sm) {
        margin-left: 0;

        .profile-name {
          text-align: center;
        }
      }

      .header-and-edit-button {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .header {
          width: 100%;
        }

        .blue-button {
          height: 48px;
          padding: 0 30px;
          margin-left: 15px;

          &:hover {
            box-shadow: 0px 5px 15px rgba(36, 130, 255, 0.25);
          }

          svg {
            height: 20px;
            width: 20px;
          }
        }

        @include media-breakpoint-down(sm) {
          flex-wrap: wrap;

          > a {
            width: 100%;
          }

          .blue-button {
            width: 100%;
            margin-top: 20px;
            margin-left: 0;
          }
        }
      }
    }

    .location-website-parent {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;

      @include media-breakpoint-down(sm) {
        justify-content: center;
      }
    }

    .location-website-parent-loading {
      display: block;
      width: 60%;

      @include media-breakpoint-down(sm) {
        margin: 0 auto;
      }
    }

    .profile-location,
    .profile-website,
    .part-of {
      font-size: $font-sm;
      font-weight: 400;
      margin-top: 10px;
      margin-right: 25px;
      display: flex;
    }

    .profile-website a,
    .part-of a {
      font-weight: 600;
      transition: color 0.2s;

      &:hover {
        color: $blue11;
      }
    }
  }

  .members-list {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    .container {
      display: flex;
      flex-wrap: wrap;
    }

    .sidebar-field {
      margin-bottom: 30px;
      font-weight: 300;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      .field-label {
        text-align: right;

        @include media-breakpoint-down(sm) {
          text-align: center;
        }
      }

      &.part-of,
      &.sub-org {
        ul {
          padding-inline-start: 0;
          margin-block-start: 0;
          list-style: none;
          font-size: $font-sm;
        }

        .sub-org-item {
          margin-bottom: 6px;
          max-width: 90vw;
        }

        a,
        .sub-org-item li a {
          text-decoration: none;
          border-bottom: none;
          transition: color 0.2s;
          font-weight: 600;
          color: black;

          &:hover {
            text-decoration: none;
            color: $blue11;
          }
        }
      }
    }

    .show-excess-members-count {
      font-size: $font-sm;
      margin-left: 7.5px;
      width: 37px;
      padding-top: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      height: min-content;
      text-align: center;

      &:hover {
        opacity: 0.7;
      }
    }

    .org-members,
    .user-org-links {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      width: 100%;

      a {
        height: 37px;
        width: 37px;
        margin-left: 7.5px;
        margin-bottom: 15px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        font-weight: 500;
        font-size: $font-xs;
        cursor: pointer;

        @include media-breakpoint-down(sm) {
          margin: 0 auto;
          margin-bottom: 15px;
        }

        &.no-avatar {
          background-color: $gray7;
        }

        &:hover {
          opacity: 0.8;
        }

        img,
        svg {
          height: 100%;
          width: 100%;
          border-radius: 100%;
          object-fit: cover;
          border: 1px solid #ededed;
        }
      }
    }
  }
}
