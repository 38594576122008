.pricing-page {
  background-color: $gray3;
  .page-content {
    .row:first-child {
      margin-bottom: 3rem;
    }
    h3 {
      margin-bottom: 1rem;
      &.price {
        font-weight: 600;
        span {
          margin-left: 0.25rem;
          position: relative;
          bottom: 3px;
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }
    button:disabled {
      background: #e0e0e0;
      color: #9f9f9f;
      border: none;
      cursor: not-allowed;
    }
    .individual-plans {
      position: relative;
      display: flex;
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }
    .individual-plans-wrapper {
      @include media-breakpoint-only(xl) {
        padding-right: 40px;
      }
    }
    .h-cloud-wrapper {
      padding-left: 40px;
    }

    .group-wrapper {
      align-items: center;
    }

    .price-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      h3 {
        margin: 0;
      }
      h6 {
        font-weight: 300;
        font-size: 0.8rem;
      }
      h6,
      p {
        margin: 0 0 15px 0;
      }
    }

    .plan,
    .card {
      border-radius: 14px;
      background: $white;
    }

    .h-cloud {
      height: 100%;
      display: flex;
      flex-direction: column;
      .cards {
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include media-breakpoint-down(lg) {
          flex-direction: row;
        }
        @include media-breakpoint-down(xs) {
          flex-direction: column;
          .card {
            margin-bottom: 15px;
          }
        }
      }
      .card {
        font-size: 0.9rem;
        padding: 37px;
        h3 {
          margin-bottom: 0;
        }
        p {
          margin-top: 0;
          &:last-child {
            margin-bottom: 0;
          }
        }
        &:hover {
          transform: none;
          cursor: default;
          box-shadow: none;
        }
        &:first-child {
          @include media-breakpoint-only(lg) {
            margin-right: 40px;
          }
          @include media-breakpoint-down(md) {
            margin-right: 7.5px;
          }
          @include media-breakpoint-down(xs) {
            margin-right: 0;
          }
        }
        &:last-child {
          @include media-breakpoint-only(lg) {
            margin-left: 40px;
          }
          @include media-breakpoint-down(md) {
            margin-left: 7.5px;
          }
          @include media-breakpoint-down(xs) {
            margin-left: 0;
          }
        }
      }
    }

    .plan {
      display: flex;
      flex-direction: column;

      .header {
        padding: 30px 15px;
      }

      .purchase {
        padding: 15px;
        position: relative;
        button {
          width: 100%;
        }
      }

      .header,
      .purchase {
        p {
          font-size: 0.9rem;
        }
      }

      .purchase {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 0;
        text-align: center;
        h6 {
          margin: 0;
          font-weight: 300;
          font-size: 0.8rem;
        }
        button {
          margin-top: 15px;
        }
      }

      .features {
        background-color: #f8f9fb;
      }

      ul {
        margin: 0;
        font-size: 0.9rem;
        position: relative;
        list-style: none;
        padding: 15px 30px;
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        li {
          width: 100%;
          display: flex;
          align-items: flex-start;
          padding-bottom: 0.25rem;
          &.greyed-out {
            opacity: 0.4;
          }
          .arrow-left {
            height: 24px;
            width: 14px;
            svg {
              position: relative;
              width: 1.5rem;
              height: 1.5rem;
              left: -4px;
              margin-top: 0;
            }
          }
          svg {
            fill: $green;
            margin-top: 7px;
          }
          > span {
            margin-left: 1rem;
          }
        }
      }

      h3,
      p {
        margin: 0;
      }

      &.group {
        margin-bottom: 30px;
        .header {
          padding: 30px 60px;
          @include media-breakpoint-down(sm) {
            padding: 30px 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 0;
            text-align: center;
          }
        }
        .features {
          position: relative;
          display: flex;
          padding-left: 60px;
          padding-right: 60px;
          @include media-breakpoint-up(lg) {
            border-bottom-left-radius: 14px;
            border-bottom-right-radius: 14px;
          }
          @include media-breakpoint-down(sm) {
            padding: 15px 30px;
          }
          ul {
            &:first-child {
              padding-left: 0;
              padding-right: 7.5px;
            }
            &:last-child {
              padding-right: 0;
              padding-left: 7.5px;
            }
            width: 50%;
          }
        }
        .purchase {
          @include media-breakpoint-only(md) {
            button {
              width: unset;
            }
          }
        }
      }

      &.individual {
        width: 50%;

        .header {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 0;
          text-align: center;
        }

        @include media-breakpoint-down(sm) {
          width: 100%;
          margin-bottom: 15px;
        }

        &:first-child {
          @include media-breakpoint-up(md) {
            margin-right: 7.5px;
          }
          @include media-breakpoint-only(lg) {
            margin-right: 40px;
          }
        }
        &:last-child {
          @include media-breakpoint-up(md) {
            margin-left: 7.5px;
          }
          @include media-breakpoint-only(lg) {
            margin-left: 40px;
          }
        }
      }
    }
  }
}
