.contributor-tooltip {
  line-height: $leading-snug;

  .contributor-name {
    color: $blue11;
    font-size: $font-md;
  }

  img {
    height: 38px;
    width: 38px;
    border-radius: 100%;
    margin-right: 8px;

    &:hover {
      opacity: 0.8;
    }
  }
}

.project-metadata {
  width: 180px;

  .category,
  .subject {
    margin-bottom: 5px;
  }

  .contributors {
    display: block;

    .MuiTooltip-tooltipArrow {
      padding: 10px !important;
    }

    .contributors-list {
      display: flex;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    img {
      max-height: 30px;
      max-width: 30px;
      border-radius: 6px;
      margin-right: 7.5px;

      &:hover {
        opacity: 0.8;
      }
    }

    .show-excess-members-count {
      font-size: $font-sm;
      padding-top: 4px;
    }
  }

  .license svg,
  .license img {
    max-height: 26px;
    max-width: 80px;
    margin-top: 8px;
  }

  .license svg {
    margin-left: -2px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;

    .metadata-field:not(.keywords):not(.languages) {
      display: inline-block;
      margin-right: 20px;
      vertical-align: top;
    }
  }
}

.project-metadata-loading {
  width: 180px;

  .MuiSkeleton-text {
    transform: inherit;
  }

  .category,
  .subject {
    margin-bottom: 5px;
  }

  .license svg,
  .license img {
    max-height: 26px;
    max-width: 80px;
    margin-top: 8px;
  }

  .license svg {
    margin-left: -2px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    margin-top: 1em;

    .metadata-field:not(.keywords):not(.languages) {
      display: inline-block;
      margin-right: 20px;
      vertical-align: top;
    }
  }
}

.metadata-field {
  font-size: $font-xs;
  margin-bottom: 20px;
  font-weight: 300;

  .field-label {
    font-weight: 500;
    padding-bottom: 3px;
  }
}

.section-with-title .section-title {
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 2px solid #ededed;
  margin-bottom: 20px;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.section-with-title {
  .section-title {
    font-weight: bold;
    white-space: nowrap;
    line-height: $leading-tight;
    padding: 10px 0;
    margin-bottom: 30px;
    font-size: $font-sm;
  }

  &:not(:first-child) .section-title {
    margin-bottom: 15px;
  }
}

.project-keyword {
  display: inline-block;
  background-color: $gray2;
  border: 1px solid $gray8;
  border-radius: 6px;
  padding: 4px 10px;
  margin-bottom: 8px;
  margin-right: 8px;
  cursor: pointer;
  vertical-align: top;

  /**
   * Necessary for calculating container size
   * @see IndexListingKeywords
   */
  .auto-width & {
    width: auto !important;
  }

  &:hover {
    opacity: 0.8;
    color: $blue11;
  }
}
