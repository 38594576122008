.project-contributors {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;

  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
  }

  .contributor-card {
    border-radius: 6px;
    border: 1px solid #e5e5e5;
    margin-bottom: 8px;
    display: flex;
    padding: 8px;

    .contributor-name {
      color: $blue11;
      font-size: $font-md;
    }

    .contributor-commits {
      font-size: $font-xs;
    }
  }

  img {
    height: 38px;
    width: 38px;
    border-radius: 100%;
    margin-right: 8px;

    &:hover {
      opacity: 0.8;
    }
  }
}
