.using-hash-banner {
  background: $gray3;
  font-size: $font-xs;
  font-weight: 700;
  text-transform: uppercase;

  .for-styles {
    text-transform: none;
    font-style: italic;
    font-weight: 400;
    padding: 0px;
    width: auto;
  }

  .container > :not(:last-child) {
    margin-right: 20px;
  }

  span,
  a {
    padding: 15px 0 12px 0;
    border-bottom: 3px solid transparent;
    display: inline-block;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }

  span {
    color: $gray11;
  }

  a {
    transition: border-color 0.2s;

    &:hover {
      border-color: $blue9;
    }

    &.active-case {
      border-color: $blue11;
    }
  }
}

.learning-hash-banner {
  background: $gray3;
  font-size: $font-xs;
  font-weight: 700;
  text-transform: uppercase;

  span {
    color: #646c74;
  }

  .desktop {
    a {
      padding: 11px 0 12px 0;
      display: inline-block;
    }

    a {
      border-bottom: 3px solid transparent;

      transition: border-color 0.2s;

      &:hover {
        border-color: $blue9;
      }

      &.active-case {
        border-color: $blue11;
      }
    }

    .learn-styles {
      margin: 15px 20px 12px 0px;
      display: inline-block;
    }

    & > :not(:last-child) {
      margin-right: 20px;
    }

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  .mobile {
    @include media-breakpoint-up(sm) {
      display: none;
    }

    a {
      padding: 5px 0 10px 0;
      display: inline-block;

      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }

    .learn-styles {
      padding: 13px 0px 10px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .link {
      padding-top: 0px;
      padding-bottom: 10px;

      &:last-child {
        padding-bottom: 12px;
      }
    }

    .active-mobile-link.link:not(first-child) {
      padding-top: 3px;
    }

    .active-mobile-link {
      border-bottom: 3px solid $blue11;

      padding-bottom: 7px;
      margin-bottom: 10px;
    }

    svg {
      transition: all 0.1s;
    }

    .open {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  a > span {
    color: $gray14;
    display: flex;
    align-items: center;
  }
}
