.platform-explore-banner {
  background: #10101b;

  .platform-explore-header {
    color: white;
    font-size: 26px;
    font-weight: 800;
    padding: 25px 15px;
    display: flex;
    flex-wrap: wrap;

    span.light-grey {
      opacity: 0.33;
      font-weight: 400;
      margin-left: 15px;
    }
  }

  .platform-links-wrapper {
    background: linear-gradient(
      90deg,
      #1b1d24 0%,
      #1b1d24 50%,
      #363b43 50%,
      #363b43 100%
    );
    padding: 0 30px;
  }

  .platform-explore-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: white;
    max-width: 1170px;
    margin: 0 auto;

    .platform-explore-link {
      width: 32%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 60px 25px;

      &:first-child {
        background: #1b1d24;
        padding-left: 0;
      }

      &:nth-child(2) {
        background: #2c323a;
        padding: 60px 30px;
        width: 36%;
      }

      &:last-child {
        background: #363b43;
        padding-right: 0;

        @include media-breakpoint-up(lg) {
          .platform-explore-text {
            width: 180px;
          }
        }
      }

      .platform-explore-text {
        margin-left: 35px;
        max-width: 200px;

        .platform-name {
          font-size: 36px;
          font-weight: 600;
          margin-bottom: 10px;
        }

        p {
          font-weight: 100;
          font-size: $font-md;
          margin: 0;
          line-height: $leading-snug;
        }

        strong {
          font-weight: 500;
        }
      }

      &.Cloud {
        img {
          width: 110px;
        }
      }

      &.Core {
        img {
          width: 110px;
        }
      }

      &.Engine {
        img {
          width: 135px;
        }

        .platform-explore-text {
          margin-left: 10px;
        }
      }

      &.Index {
        img {
          width: 90px;
        }
      }

      @include media-breakpoint-down(lg) {
        &:nth-child(2) {
          width: 35%;
        }
      }

      @include media-breakpoint-up(md) {
        &:hover .platform-explore-text,
        &:hover img {
          transition: opacity 0.2s;
          opacity: 0.7;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .platform-links-wrapper {
      padding: 0;
    }

    .platform-explore-links .platform-explore-link {
      width: 100%;
      margin: 0;

      .platform-explore-text {
        width: 180px;
      }

      &:first-child,
      &:nth-child(2),
      &:last-child {
        padding: 40px 30px;
      }

      &.Engine {
        margin-right: 10px;
        padding: 20px 30px;
      }

      &.Index {
        margin-left: 10px;
        padding: 30px 30px 40px 30px;
      }

      &.Core {
        margin-right: 10px;
        padding: 30px 30px 40px 30px;
      }

      &:nth-child(2) {
        width: 100%;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .platform-explore-links .platform-explore-link {
      .platform-explore-text {
        width: 170px;
      }

      &:first-child,
      &:nth-child(2),
      &:last-child {
        padding: 40px 15px;
      }

      &.Engine {
        padding: 20px 15px;
      }

      &.Index,
      &.Core {
        padding: 30px 15px 40px 15px;
      }
    }
  }
}
