.project-form-window.new-project {
  max-width: 800px;

  .project-form-header .heading-4.project-url {
    font-size: $font-lg;

    span {
      opacity: 0.5;
      color: $gray14;
    }
  }

  .loading-indicator {
    margin: 0 0 0 30px;
  }

  .react-select__control,
  .input {
    min-height: 48px;
    height: auto;
  }

  .secondary-button {
    height: 46px;
    margin-left: 15px;
    margin-top: 1px;
  }

  .owner-and-submit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @include media-breakpoint-down(sm) {
    .owner-and-submit,
    .visibility,
    .project-owner-dropdown {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: 100%;

      .label-with-tooltip {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        text-align: right;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .project-owner-dropdown {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 500px) {
    .secondary-button {
      width: 100%;
      margin: 30px 0 0 0;
    }

    .project-owner-dropdown {
      margin-bottom: 0;
    }
  }
}
