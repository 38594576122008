.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  border: none;
  color: inherit;
  background: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    background: none;
    color: inherit;
  }

  > .text {
    font-size: inherit;
    margin-left: 30px;
    font-weight: 400;
    margin-bottom: 1px;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    position: relative;
    height: 18px;
  }

  .check,
  .outline,
  .exclude {
    position: absolute;
    top: 0;
    left: 0;
  }

  .check svg {
    height: 15px;
    width: 15px;
  }

  .outline svg,
  .exclude svg {
    height: 16px;
    width: 16px;
  }

  &.error #checkbox-blank-outline {
    fill: $red11;
  }

  &.error .text {
    color: $red11;
  }

  .check,
  .exclude {
    display: none;
  }

  .checked {
    .check {
      display: block;
    }

    .outline {
      display: none;
    }
  }

  .excluded {
    .exclude {
      display: block;
    }

    .outline {
      display: none;
    }
  }

  svg {
    height: 18px;
    width: 18px;
    border-radius: 4px;
  }

  #checkbox-marked {
    fill: black;
  }

  #checkbox-blank-outline {
    fill: rgba(0, 0, 0, 0.2);
  }

  &.dark {
    #checkbox-marked {
      fill: rgba(241, 243, 246, 0.2);

      path {
        fill: white;
      }
    }

    #checkbox-blank-outline {
      fill: rgba(219, 224, 227, 0.3);
    }

    #exclude {
      fill: rgba(219, 224, 227, 0.3);

      path {
        fill: white;
      }
    }
  }
}
