.integrations {
  .profile-section-header {
    margin-bottom: 30px;
  }

  .installed-integrations {
    margin-bottom: 60px;
  }

  .available-integrations-list,
  .installed-integrations-list {
    padding-left: 10px;
  }

  .available-integrations-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .available-integration {
      padding: 40px;
      display: flex;
      justify-content: center;
      width: 32%;
      margin-bottom: 20px;

      @include media-breakpoint-down(lg) {
        width: 49%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        padding: 30px;
      }
    }
  }

  .integration-summary {
    display: flex;
    align-items: center;
    justify-content: center;

    .integration-label {
      margin-left: 20px;
      font-weight: 600;

      .integration-sub-label {
        opacity: 0.6;
        font-weight: 300;
        font-size: $font-xs;
      }
    }
  }

  .installed-integration,
  .available-integration {
    cursor: pointer;
    transition: color 0.2s;
    border: 1px solid $gray6;

    svg {
      transition: fill 0.2s;

      path {
        transition: fill 0.2s;
      }
    }

    &:hover {
      color: $blue11;
      transition: color 0.2s;

      svg {
        fill: $blue11;
        transition: fill 0.2s;

        path {
          fill: $blue11;
          transition: fill 0.2s;
        }
      }
    }
  }

  .installed-integration {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;

    .secondary-button {
      font-weight: 600;
    }

    &:not(:first-child) {
      margin-top: 15px;
    }
  }
}

.upsert-integration {
  .source-input,
  .credential-input {
    border: 1px solid $gray6;
    border-radius: 12px;
    padding: 25px;
  }

  .credential-input {
    margin-top: 30px;
  }

  .upsert-integration-subheader {
    font-size: $font-sm;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
  }

  .input-group:last-child {
    margin-bottom: 0;
  }

  .create-integration-button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    button:first-child {
      margin-right: 30px;
    }
  }

  .multi-integration-fields {
    .multi-field {
      display: flex;
      align-items: flex-end;
      width: 100%;
      margin-bottom: 25px;

      > .input-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;

        .input-group {
          width: 100%;
          margin-bottom: 0;

          &:not(:first-child) {
            margin-left: 15px;
          }

          &.number-input {
            max-width: 120px;
          }
        }
      }

      .red-button {
        margin-left: 15px;
        height: 48px;
      }
    }

    > label {
      text-align: center;
    }
  }

  .credential-intro-text {
    font-size: $font-sm;
    line-height: $leading-base;
    margin-bottom: 20px;
    display: block;
  }
}
