.project-form-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 20;
  padding: 175px 30px 0 30px;
  animation-name: fadeIn;
  animation-duration: 0.1s;

  @media (max-width: 400px) {
    padding: 50px 15px 0 15px;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.project-form-window {
  padding: 50px 70px;
  margin: 0 auto 50px auto;
  border-radius: 16px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  background-color: white;
  width: auto;
  max-width: 1000px;

  @include media-breakpoint-down(xs) {
    padding: 50px 30px;
  }
}

.project-form-window {
  .project-form-header {
    margin-bottom: 40px;
    position: relative;

    .project-form-cancel {
      position: absolute;
      right: 0px;
      top: 0px;
      display: flex;
      padding: 0.5rem;
      border-radius: 2px;
      font-size: $font-xs;
      font-weight: bold;
      opacity: 0.5;
      cursor: pointer;
      align-items: center;

      &:hover {
        opacity: 1;
        transition: 0.3s;
      }

      svg {
        height: 12px;
        width: 12px;
        margin-left: 10px;
        margin-top: 1px;
      }

      @media (max-width: 855px) {
        margin-top: 1rem;
        justify-content: flex-start;
      }

      @media (max-width: 414px) {
        position: absolute;
        top: -30px;
      }
    }

    .heading-4 {
      color: $gray7;
      margin: 0;
      font-weight: 600;
    }

    .heading-3 {
      color: #1b1d24;
      margin: 0;
    }
  }

  .loading-indicator {
    margin-top: 30px;
  }
}
.project-form .inputs {
  display: flex;
  justify-content: space-between;

  .left-inputs {
    width: 45%;
  }

  .right-inputs {
    width: 45%;
  }

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;

    .left-inputs,
    .right-inputs {
      width: 100%;
    }
  }
}

.project-form .input-group {
  margin-bottom: 30px;
}

.update-project .project-form,
.version-and-changelog {
  width: 100%;

  .images {
    display: flex;
    flex-wrap: wrap;

    .avatar-header {
      text-align: left;
    }

    .avatar-image {
      margin-right: 40px;
    }

    .cover-image,
    .avatar-image {
      svg {
        border: none;
      }
    }
  }

  .dataset-specific-fields {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input-group {
      width: 30%;
    }

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;

      .input-group {
        width: 100%;
      }
    }
  }

  .subject-and-submit {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .subject {
      width: 40%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  .publishable-items {
    padding: 20px 20px 0 20px;
    border: 1px solid $gray8;
    border-radius: 16px;
    margin-bottom: 30px;
    position: relative;

    .loading-indicator {
      position: absolute;
      top: -10px;
      right: 15px;
    }

    .success {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $green11;
      font-size: $font-sm;
      margin-bottom: 15px;
      margin-left: 3px;

      svg {
        fill: $green10;
        margin-right: 5px;
      }
    }

    .create-dataset-overlay {
      background-color: rgba(0, 0, 0, 0);
      padding: 0;
    }

    .input-group {
      margin-bottom: 20px;
    }

    .publishable-items-title {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      text-transform: uppercase;
      font-size: $font-xs;
      margin-bottom: 15px;
    }

    .dropdown-wrapper {
      width: calc(50% - 70px);
    }

    @include media-breakpoint-down(md) {
      .dropdown-wrapper {
        width: 100%;
      }
    }
  }

  .publishable-item,
  .swap-item-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 20px;
    border-top: 1px solid rgb(230, 230, 230);

    .filename-and-format {
      display: flex;
      justify-content: space-between;
    }

    .dropdown-wrapper {
      width: 90px;
      margin-left: 10px;
    }

    .input-half {
      width: calc(50% - 60px);

      .input-group.filename {
        width: 100%;
      }

      .input.error {
        width: 100%;
      }
    }

    .buttons {
      display: flex;
    }

    .remove-item,
    .add-item {
      width: 50px;
      display: flex;
      justify-content: center;

      button {
        border: 0;
        background: none;
        padding: 5px;

        &:hover {
          svg {
            fill: $red11;

            path {
              fill: $red11;
            }
          }
        }
      }

      svg {
        transition: fill 0.2s;
        fill: $gray7;

        path {
          fill: $gray7;
        }
      }
    }

    .remove-item svg {
      height: 20px;
      width: 20px;
      margin-top: 28px;
    }

    .add-item svg {
      height: 25px;
      width: 25px;
      margin-top: 26px;
    }

    .add-item.swap svg {
      height: 35px;
      width: 35px;
      margin-top: 20px;
    }

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;

      .input-half:first-child {
        width: 100%;
      }

      .input-half:nth-child(2) {
        width: calc(100% - 90px);
      }

      .remove-item,
      .add-item {
        width: 35px;
      }
    }
  }

  .swap-item-form .secondary-button {
    margin-top: 21px;
    height: 46px;
    width: 100px;

    @include media-breakpoint-down(md) {
      width: auto;
    }
  }

  .new-version {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
  }

  .submit-and-publisher {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    button {
      font-weight: 500;
      margin-right: 15px;
      text-transform: uppercase;

      svg {
        height: 18px;
        width: 20px;
        margin-left: 10px;
      }
    }

    @media (max-width: 500px) {
      .secondary-button,
      .submit {
        width: 100%;
      }

      .submit {
        margin-bottom: 20px;
      }
    }
  }

  .visibility-and-delete {
    margin-top: 20px;
    padding-top: 20px;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: space-between;

    .project-visibility {
      color: rgba(0, 0, 0, 0.8);
      text-align: right;

      .error-text {
        margin-top: 10px;
      }

      button {
        padding: 0;
        border: 0;
        background: none;
        color: rgba(0, 0, 0, 0.8);
        font-weight: 600;
        transition: color 0.2s;
      }

      button:hover {
        color: $blue11;
      }
    }

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      justify-content: flex-end;

      .project-visibility {
        width: 100%;
        margin-bottom: 40px;
        order: 1;
      }

      .delete-project {
        order: 2;
      }
    }
  }
}
