$base-font-url: "https://cdn-us1.hash.ai/assets/fonts/";

// ------------------------ INTER -----------------------------

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("#{$base-font-url}Inter-Thin-BETA.woff2") format("woff2"),
    url("#{$base-font-url}Inter-Thin-BETA.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("#{$base-font-url}Inter-ThinItalic-BETA.woff2") format("woff2"),
    url("#{$base-font-url}Inter-ThinItalic-BETA.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("#{$base-font-url}Inter-ExtraLight-BETA.woff2") format("woff2"),
    url("#{$base-font-url}Inter-ExtraLight-BETA.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("#{$base-font-url}Inter-ExtraLightItalic-BETA.woff2") format("woff2"),
    url("#{$base-font-url}Inter-ExtraLightItalic-BETA.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("#{$base-font-url}Inter-Light-BETA.woff2") format("woff2"),
    url("#{$base-font-url}Inter-Light-BETA.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("#{$base-font-url}Inter-LightItalic-BETA.woff2") format("woff2"),
    url("#{$base-font-url}Inter-LightItalic-BETA.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("#{$base-font-url}Inter-Regular.woff2") format("woff2"),
    url("#{$base-font-url}Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("#{$base-font-url}Inter-Italic.woff2") format("woff2"),
    url("#{$base-font-url}Inter-Italic.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("#{$base-font-url}Inter-Medium.woff2") format("woff2"),
    url("#{$base-font-url}Inter-Medium.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("#{$base-font-url}Inter-MediumItalic.woff2") format("woff2"),
    url("#{$base-font-url}Inter-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("#{$base-font-url}Inter-SemiBold.woff2") format("woff2"),
    url("#{$base-font-url}Inter-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("#{$base-font-url}Inter-SemiBoldItalic.woff2") format("woff2"),
    url("#{$base-font-url}Inter-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("#{$base-font-url}Inter-Bold.woff2") format("woff2"),
    url("#{$base-font-url}Inter-Bold.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("#{$base-font-url}Inter-BoldItalic.woff2") format("woff2"),
    url("#{$base-font-url}Inter-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("#{$base-font-url}Inter-ExtraBold.woff2") format("woff2"),
    url("#{$base-font-url}Inter-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("#{$base-font-url}Inter-ExtraBoldItalic.woff2") format("woff2"),
    url("#{$base-font-url}Inter-ExtraBoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("#{$base-font-url}Inter-Black.woff2") format("woff2"),
    url("#{$base-font-url}Inter-Black.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("#{$base-font-url}Inter-BlackItalic.woff2") format("woff2"),
    url("#{$base-font-url}Inter-BlackItalic.woff") format("woff");
}

// ------------------------ Open Sauce Two -----------------------------

@font-face {
  font-family: "Open Sauce Two";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("#{$base-font-url}OpenSauceTwo-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sauce Two";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("#{$base-font-url}OpenSauceTwo-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sauce Two";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("#{$base-font-url}OpenSauceTwo-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sauce Two";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("#{$base-font-url}OpenSauceTwo-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sauce Two";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("#{$base-font-url}OpenSauceTwo-Medium.ttf") format("truetype"),
    url("#{$base-font-url}OpenSauceTwo-Medium.woff") format("woff");
}
@font-face {
  font-family: "Open Sauce Two";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("#{$base-font-url}OpenSauceTwo-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sauce Two";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("#{$base-font-url}OpenSauceTwo-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sauce Two";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("#{$base-font-url}OpenSauceTwo-SemiBoldItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Open Sauce Two";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("#{$base-font-url}OpenSauceTwo-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sauce Two";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("#{$base-font-url}OpenSauceTwo-BoldItalic.ttf") format("truetype");
}
