.tooltip-info-icon {
  height: 14px;
  width: 14px;
  display: flex;

  svg {
    width: 14px;
    height: 14px;

    path {
      fill: $gray7;
    }
  }
}
