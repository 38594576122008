.index-home-page-wrapper.not-found {
  text-align: center;

  .homepage-content.container {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .searchbar-input {
    display: flex;
    justify-content: center;
  }

  .homepage-explore {
    padding: 0 15px 120px 15px;
  }
}
