.user-group {
  margin-top: 40px;
}

.admin-create-user {
  margin-left: 5px;

  > div {
    height: 14px;
    margin-left: 5px;
  }
}

// USERS MAIN -->>
.admin-users-main {
  .menu-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 1.5rem;

      span {
        font-size: 1.5rem;
        opacity: 0.5;
        margin-left: 1rem;
      }
    }
    input {
      background: $white;
      padding: 0.75rem;
      border-radius: 5px;
    }
    form {
      display: flex;

      input {
        margin: 0 0.25rem;
        padding: 0 0.75rem;
      }
    }
    .admin-menu-bar-input-and-button {
      display: flex;

      input {
        margin-right: 1rem;
      }

      button {
        height: 46px;
      }
    }
  }

  .user-table {
    width: 100%;
    border-collapse: collapse;
    background: $white;
    padding: 1rem;
    border-radius: 5px;

    table {
      border-collapse: collapse;
      width: 100%;
    }

    tr {
      &.deleted {
        text-decoration: line-through;
        color: red;
      }

      th,
      td {
        padding: 0.5rem 1rem;
        font-size: $font-sm;

        &.sim-count,
        &.listing-count,
        &.role {
          text-align: center;
        }

        &.user-name {
          width: 200px;
        }

        &.last-login,
        &.added {
          text-align: center;
          min-width: 110px;

          button {
            width: 100%;
          }
        }
      }

      th {
        background-color: $gray14;
        color: $white;
        text-align: left;
        text-transform: uppercase;
        font-weight: 400;
        cursor: pointer;

        &:hover {
          div svg {
            opacity: 1;
          }
        }

        div {
          display: flex;
          align-items: center;

          svg {
            margin-left: 0.5rem;
            padding: 0.2rem;
            width: 1rem;
            height: 1rem;
            fill: #fff;
            opacity: 0.5;
            transition: opacity 0.3s;
          }
          &.active-col :not(.icon) svg {
            fill: #5f47ff;
            opacity: 1;
          }
          &.Asc svg {
            transform: scaleY(-1);
          }

          .icon svg {
            height: 30px;
            width: 30px;
            margin-left: 0;
            opacity: 1;

            g {
              fill: white;
            }
          }
        }
      }

      td {
        padding: 0.25rem;
        background-color: $white;
      }

      td.user-name {
        width: 100%;
        font-weight: 600;
      }

      .delete-user button {
        background: 0;
        border: 0;

        svg {
          height: 16px;
          width: 16px;
          transition: fill 0.2s;
        }

        &:hover {
          svg {
            fill: $red11;
          }
        }
      }

      .delete-form-submission {
        display: flex;

        button {
          margin-left: 20px;
          height: 46px;
        }
      }
    }
  }
}
// <<-- USERS MAIN END
