.dropzone {
  width: 100%;
}

.dropzone:focus {
  outline: 0;
}

.file-dropzone-landing {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  justify-content: center;
  width: 100%;
  border: 2px dashed $gray3;
  padding: 20px;

  .dropzone-text {
    width: 100%;
    font-size: $font-sm;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
  }

  svg {
    margin: 20px 0;
    width: 56px;
    height: 56px;

    path {
      transition: fill 0.1s;
    }
  }

  &:hover {
    color: $blue11;
    transition: color 0.2s;

    svg path {
      fill: $blue11;
      transition: fill 0.1s;
    }
  }
}
