.wp-block-quote {
  background-color: $gray2;
  padding: 5px 30px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.7);
  border-left: 6px solid $blue11;
  margin: 30px;
}

.wp-block-separator {
  border: none;
  border-top: 1px solid #ededed;
  margin: 30px 0;
}

.prev-and-next-links {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 20px;

  &.prev-only {
    .prev {
      width: 100%;
    }

    .next {
      width: 0%;
    }
  }

  &.next-only {
    .prev,
    .prev-placeholder {
      width: 0%;
    }

    .next {
      width: 100%;
    }
  }

  a,
  .prev-placeholder {
    width: 48%;
  }

  a {
    width: 48%;

    --nav-link-color: #72767f;
    text-align: right;

    svg {
      margin-right: 15px;
    }

    &.next {
      text-align: left;

      svg {
        margin-left: 15px;
        margin-right: 0;
      }
    }

    @include media-breakpoint-up(sm) {
      &:hover {
        .prev-next-link-title {
          color: $blue11;
        }

        svg path {
          fill: $blue11;
        }
      }
    }

    button {
      width: 100%;
      background: none;
      color: var(--nav-link-color);
      border: 1px solid rgb(233, 233, 233);
      font-weight: 300;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      text-align: inherit;
      font-size: $font-xs;
      white-space: normal;

      > div {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .prev-next-link-title {
        color: black;
        font-size: $font-sm;
        font-weight: 500;
        margin-top: 4px;
        transition: color 0.1s;
      }

      svg {
        min-height: 15px;
        min-width: 15px;
        margin-bottom: 1px;
        margin-right: 15px;

        path {
          transition: fill 0.1s;
          fill: var(--nav-link-color);
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    flex-wrap: wrap;

    .prev-placeholder {
      display: none;
    }

    a {
      width: 100%;

      &:nth-child(2) {
        margin-top: 20px;
      }
    }
  }
}

.wp-page {
  .wp-content {
    width: 100%;

    ul,
    ol {
      padding-inline-start: 20px;
      margin-block-start: 15px;

      li:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    p,
    li {
      font-size: $font-base;
      line-height: $leading-loose;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-top: 40px;
      margin-bottom: 10px;
      font-weight: 700;

      &:first-child {
        margin-top: 0;
      }

      strong {
        font-weight: 800;
      }

      code {
        text-transform: none;
        line-height: 175%;
      }
    }

    h1 {
      font-size: 28px;
    }

    h2 {
      font-size: $font-lg;
    }

    h3 {
      font-size: $font-md;
    }

    h4 {
      font-size: $font-base;
    }

    h5 {
      font-size: $font-sm;
    }

    .mdx-heading {
      padding-right: 37.5px;
    }

    @include media-breakpoint-down(sm) {
      .MuiSnackbar-root {
        padding-bottom: 4rem;
      }
    }

    .skeleton-loader {
      width: 100%;

      .MuiSkeleton-text {
        transform: inherit;
      }

      .skeleton-h2 {
        margin-bottom: 20px;
        width: 60%;
      }
    }

    .code-copy {
      position: absolute;
      top: -12px;
      right: 1em;

      .copy-button {
        opacity: 0.8;
        transition: opacity 0.2s;

        &:hover {
          filter: none;
          opacity: 1;
        }

        &:active {
          opacity: 1;
        }
      }

      img {
        height: 24px;
        width: 24px;
      }
    }

    .mdx-filename {
      border: 1px solid #e7ecef;
      border-radius: 7px;
      padding: 0.1em 0.3em;
      white-space: nowrap;

      .MuiSvgIcon-root {
        font-size: 1em;
        fill: #dbe0e3;
        margin-right: 0.1em;
        margin-bottom: -0.2em;
      }
    }

    .key-concepts {
      border-radius: 8px;
      border: 1px solid #dbe0e3;
      padding: 30px 35px;
      margin: 30px 0;

      h3 {
        font-weight: 400;
      }

      p:last-child,
      ul:last-child,
      ol:last-child {
        margin-bottom: 0;
      }
    }

    .hint-success,
    .hint-info,
    .hint-warning,
    .hint-danger {
      width: 100%;
      margin: 0;
      display: flex;
      padding: 1px 24px;
      padding-left: 0px;
      position: relative;
      margin-top: 32px;
      border-left: 4px solid rgb(38, 203, 124);
      border-radius: 3px;
      margin-bottom: 32px;
      background-color: #f5f7f9;

      svg {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        margin-left: 0.8em;
        margin-right: 0.8em;
        color: rgb(38, 203, 124);
      }
    }

    .hint-info {
      border-left: 4px solid rgb(56, 132, 255);

      svg {
        color: rgb(56, 132, 255);
      }
    }

    .hint-warning {
      border-left: 4px solid rgb(247, 125, 5);

      svg {
        color: rgb(247, 125, 5);
      }
    }

    .hint-danger {
      border-left: 4px solid rgb(255, 70, 66);

      svg {
        color: rgb(255, 70, 66);
      }
    }

    .embed-parent {
      text-align: center;
    }

    .embed-container {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;
    }

    .embed-container iframe,
    .embed-container object,
    .embed-container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .docs-tabs,
    .docs-text-tabs {
      pre {
        margin-top: 0px !important;
        border-top-left-radius: 0px;
      }

      .tabs-headers {
        display: flex;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        font-size: $font-sm;

        max-width: calc(100vw - 30px);
        overflow: auto;
      }

      .tabs-header {
        cursor: pointer;

        color: rgb(157, 170, 182);
        border-width: 1px;
        border-style: solid;
        border-color: rgb(230, 236, 241) rgb(230, 236, 241) rgb(230, 236, 241)
          transparent;

        background-color: rgb(245, 247, 249);
        transition: color 250ms ease-out 0s;
        padding: 8px;

        &:hover {
          color: black;
        }
      }

      .tabs-header.active {
        background-color: #2d2d2d;
        color: white;
        border: 0px;
      }
    }

    .markdown-wrapper.text-tab {
      border: 1px solid rgb(230, 236, 241);
      border-radius: 0px 3px 3px;
      padding: 0px 24px;
    }

    .docs-text-tabs {
      .tabs-header.active {
        background-color: white;
        color: black;
        border: 0px;

        border-width: 1px;
        border-style: solid;
        border-color: rgb(230, 236, 241);

        border-bottom: 0px;
      }
    }
  }

  figcaption,
  .figcaption {
    font-weight: 300;
    font-size: $font-sm;
    line-height: 140%;
    text-align: center;
    margin: 8px auto;

    // in case of empty caption, a margin of 15px is still applied
    min-height: 15px;

    max-width: 800px;
    color: rgb(157, 170, 182);
  }

  .wp-block-code,
  pre {
    border-radius: 2px;
    margin: 2rem 0 !important;

    code {
      background: inherit;
    }

    @include media-breakpoint-down(xs) {
      width: calc(100vw - 30px);
    }
  }

  .wp-block-table {
    margin: 20px 0;
  }

  img {
    // 30px matches exactly with the minimum height the caption .figcaption can have
    margin: 30px 0;

    // margin-bottom is 0 to allow captions to stick to their image
    margin-bottom: 0px;

    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(xl) {
      display: block;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    @include media-breakpoint-down(xs) {
      width: calc(100vw - 30px);
      max-width: 100%;
    }
  }

  figure {
    margin: 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    padding: 2rem;

    tr {
      border-bottom: 0.1rem solid #dfdfdf;
    }

    th {
      padding: 8px;
      padding-left: 0px;
    }

    > tbody {
      width: fit-content;
      > tr {
        width: fit-content;
        &:first-child {
          border-bottom: 0.1rem solid #dfdfdf;
          > td {
            padding-bottom: 0.75rem;
            padding-right: 0.75rem;
          }
        }
        > td {
          width: fit-content;
          padding: 0.75rem 0.75rem 0.75rem 0;
        }
        &:last-child {
          border-bottom: 0.1rem solid #dfdfdf;
        }
      }
    }
  }

  .wp-sidebar-section {
    margin-bottom: 20px;

    &.welcome-guide {
      margin-top: -5px;
    }

    h3 {
      font-weight: 600;
      margin-top: 5px;
      margin-bottom: 10px;
      text-transform: uppercase;
      font-size: $font-base;
    }

    p {
      font-size: $font-sm;
      font-weight: 300;
      line-height: 140%;
      margin: 0;
    }

    .MuiSkeleton-text {
      transform: inherit;
    }
  }

  .scrolly-right {
    display: flex;
    justify-content: space-between;

    p,
    .hint-success svg,
    .hint-info svg,
    .hint-warning svg,
    .hint-danger svg {
      margin-top: 15px;
      margin-block-end: 15px;
    }

    .learn-mdx-wrapper > p:first-child {
      margin-top: 0px;
    }

    blockquote {
      border-left: 4px solid;
      border-color: #e6ecf1;
    }

    .section {
      position: relative;

      &:not(:first-child) {
        margin-top: 30px;
      }

      h1,
      h2,
      h3,
      h4,
      h5 {
        display: inline-block;
      }

      p:first-child {
        margin-top: 0;
      }

      .MuiSnackbar-root {
        border-radius: 7px;

        .MuiPaper-root {
          border-radius: 7px;
          border: 1px solid rgba(42, 185, 103, 0.4);
        }
      }

      .heading-right {
        display: inline-flex;
        align-items: center;
        flex-wrap: nowrap;
        padding-right: 37.6px;
      }

      .pad-links {
        position: relative;

        .links-wrapper {
          position: absolute;
          display: inline-flex;
          top: 3px;
        }
      }

      .triangle {
        display: none;
        border-left: 0.5rem solid transparent;
        border-right: 0.5rem solid transparent;
        border-top: 0.5rem solid transparent;
        transition: border-color 0.2s;
        transform: rotate(-135deg);
        margin-bottom: 0.6em;
        margin-left: 0.1em;
      }

      .link,
      .link-h3 {
        width: 19px;
        height: 19px;

        opacity: 0;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        &:active {
          opacity: 1;
        }
      }

      &:hover {
        .heading-right {
          padding-right: 18.6px;
        }

        .link,
        .link-h3 {
          display: inherit;
          margin-left: 0.2em;
        }
      }

      &.current {
        .heading-right {
          padding-right: 0px;
        }

        .link,
        .link-h3 {
          opacity: 0.6;
          margin-left: 0px;
        }

        .triangle {
          border-top: 0.5rem solid $blue11;
          transition: border-color 0.1s;
          display: inline-block;
        }

        &.solo {
          .triangle {
            display: none;
          }

          .link,
          .link-h3 {
            margin-left: 0.2em;
          }
        }
      }

      &.key-concepts .triangle {
        top: 1.5rem;
      }
    }

    .quickjump-parent {
      margin-bottom: 25px;
    }

    .github-link {
      display: flex;
      align-items: center;
      font-weight: 400;
      margin-bottom: 60px;
      color: $gray10;

      &:hover {
        color: $gray11;
        transition: all 0.1s ease-in-out;

        svg {
          path {
            fill: rgba(184, 190, 193, 1);
          }
        }
      }

      svg {
        margin-right: 10.5px;

        path {
          color: $gray11;
        }
      }
    }

    .github-link-bottom {
      justify-content: flex-end;
      margin-bottom: 0px;
      padding-top: 10px;

      @include media-breakpoint-up(xl) {
        display: none;
      }
    }

    .left-col {
      padding-top: 5rem;
      overflow: hidden;
    }

    .right-col {
      width: 220px;
      min-width: 220px;
      margin-left: 5rem;
      position: relative;
      padding-top: 5rem;

      @include media-breakpoint-down(lg) {
        display: none;
      }
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .scrolly-navigation {
        max-width: 220px;

        &.reversed {
          display: flex;
          flex-direction: column-reverse;
        }

        h5 {
          margin: 0;
          padding-bottom: 6px;
        }

        .getting-started-menu {
          h5 {
            margin: 0;
            margin-top: 2rem;
          }
        }

        div,
        div strong {
          font-weight: 300;
        }

        .heading {
          margin: 8px 0;
          padding: 3px 0;
          cursor: pointer;
          opacity: 1;
          transition: color 0.2s;
          line-height: 130%;

          &.current {
            font-weight: 600;
            strong {
              font-weight: 600;
            }

            &:hover {
              opacity: 0.6;
              transition: opacity 0.2s;
            }
          }
          &:not(.current):hover {
            color: $blue11;
            transition: color 0.2s;
          }

          &.heading-h3,
          &.heading-h4,
          &.heading-5 {
            padding-left: 12px;
            border-left: 1px solid $gray6;
          }
        }
      }
    }
  }
}

.get-started-box,
.contact-box {
  button {
    margin-top: 15px;

    &.discord svg {
      height: 14px;
      width: 14px;
    }
  }
}
