.searchbar {
  display: flex;
  width: 100%;

  .searchbar-input {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .input {
      padding: $space3;
      width: 100%;
      max-width: 800px;
      font-size: $font-base;
      padding-right: 40px;
      background-color: white;
      border: 1px solid #f1f1f1;
    }

    button {
      margin-left: -45px;
      background: 0;
      border: 0;
      padding: 0;
      height: 35px;
      width: 35px;
      padding: 8px;

      &:hover img {
        transform: scale(1.3);
      }

      img {
        height: 100%;
        width: 100%;
        transition: transform 0.2s;
        transition-timing-function: ease-out;
      }
    }
  }
}
