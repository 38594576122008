.with-navbar {
  @include media-breakpoint-up(sm) {
    .mainsite-header.with-breadcrumb {
      padding-top: 40px;
    }
  }
}

.use-case-page {
  .wp-block-quote,
  blockquote {
    background: white;
    margin: 30px 0;
  }

  .mainsite-breadcrumb {
    strong {
      font-weight: 700;
      margin-left: 7px;
    }

    .breadcrumb-word-span {
      margin: 0 7px;
      color: black;
    }
  }
}
