.user-organizations {
  width: 100%;
  background: white;
  position: relative;

  .loading-indicator {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }

  .no-orgs {
    margin-bottom: 30px;
  }

  table.user-orgs-table {
    width: 100%;
    border-radius: 6px;
    border-collapse: separate;
    border-spacing: 0;
    padding: 0;

    th {
      text-align: left;
    }

    th,
    td {
      max-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 25px 15px;

      @include media-breakpoint-down(lg) {
        &.org-member-count {
          display: none;
        }

        &.org-name {
          width: 25%;
        }
      }

      @include media-breakpoint-down(md) {
        padding: 15px;

        &.org-user-title {
          display: none;
        }

        &.leave-org,
        &.org-user-permissions {
          max-width: 80px;
        }
      }

      @media (max-width: 500px) {
        &.org-logo {
          width: 8%;
        }

        &.org-user-permissions {
          display: none;
        }

        &.leave-org {
          width: 12%;
          min-width: 0;
        }
      }
    }

    th {
      font-size: $font-xs;
      font-weight: bold;
      color: #86878d;
      background-color: white;

      @media (max-width: 500px) {
        display: none;
      }
    }

    tr {
      border-bottom: 0;
    }

    td {
      font-size: $font-sm;
      font-weight: normal;

      @include media-breakpoint-down(xs) {
        font-size: $font-sm;
      }
    }

    tbody tr:first-child {
      .org-name,
      .org-user-title,
      .org-user-permissions,
      .org-member-count,
      .leave-org {
        border-top: 1px solid $gray6;
      }

      .org-name {
        border-top-left-radius: 6px;
      }

      .leave-org {
        border-top-right-radius: 6px;
      }
    }

    td.org-name {
      border-left: 1px solid $gray6;
      padding-left: 25px;

      @include media-breakpoint-down(sm) {
        padding-left: 15px;
      }
    }

    td.leave-org {
      border-right: 1px solid $gray6;
      padding-right: 25px;

      @include media-breakpoint-down(sm) {
        padding-right: 15px;
      }
    }

    tbody tr:last-child {
      .org-name,
      .org-user-title,
      .org-user-permissions,
      .org-member-count,
      .leave-org {
        border-bottom: 1px solid $gray6;
      }

      .org-name {
        border-bottom-left-radius: 6px;
      }

      .leave-org {
        border-bottom-right-radius: 6px;
      }
    }

    .light-grey-bg {
      .org-name,
      .org-user-title,
      .org-user-permissions,
      .org-member-count,
      .leave-org {
        background-color: $gray1;
      }
    }

    .grey-bg {
      .org-name,
      .org-user-title,
      .org-user-permissions,
      .org-member-count,
      .leave-org {
        background-color: $gray2;
      }
    }

    .org-logo {
      width: 12%;
    }

    .org-name {
      width: 25%;
      padding-left: 25px !important;
    }

    .org-title {
      width: 15%;
    }

    .leave-org,
    .org-user-permissions,
    .org-member-count {
      width: 14%;
      min-width: 100px;
    }

    td.org-name {
      font-weight: bold;
    }

    td.org-user-title input {
      width: 100%;
      height: 35px;
    }

    td.org-logo {
      img,
      svg {
        width: 30px;
        height: 30px;
        object-fit: contain;
      }
    }

    .leave-org button {
      border: 0;
      background: 0;
      padding: 0;
      font-weight: bold;
      color: black;
      display: flex;
      align-items: center;

      svg {
        width: 18px;
        height: 18px;
        margin-left: 3px;
        opacity: 0;
        transition: opacity 0.2s;
        path {
          fill: #ff4b56;
        }
      }

      &:hover {
        color: #ff4b56;
        transition: color 0.2s;

        svg {
          opacity: 1;
          transition: opacity 0.4s;
        }
      }

      @include media-breakpoint-down(xs) {
        font-size: $font-sm;
      }
    }
  }

  .org-name a:hover {
    opacity: 0.9;
    color: $blue11;
    transition: all 0.2s;
  }

  .org-logo img:hover {
    opacity: 0.7;
  }

  .no-orgs {
  }
}

.create-org-link button {
  margin-top: 3px;
  margin-left: 30px;
  border: 0;
  background: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: $font-sm;
  font-weight: bold;
  color: $gray13;

  svg {
    height: 18px;
    width: auto;
    margin-right: 5px;

    path {
      fill: $gray13;
    }
  }

  &:hover {
    opacity: 0.9;
    color: $blue11;
    transition: all 0.2s;

    svg path {
      opacity: 0.9;
      fill: $blue11;
      transition: all 0.2s;
    }
  }

  @include media-breakpoint-down(xs) {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }
}
