.blog-home {
  .mainsite-header {
    position: relative;
    background: linear-gradient(180deg, $gray2 85%, #fff 85%);
    padding-bottom: 0;

    .mainsite-title {
      h1 {
        display: flex;
        align-items: center;

        img {
          margin-right: 20px;
          border-radius: 10px;

          @include media-breakpoint-down(xs) {
            height: 40px;
            width: 40px;
            margin-right: 15px;
            margin-bottom: 2px;
          }
        }
      }
    }

    img.featured-image {
      width: 100%;
      object-fit: cover;
      position: relative;
      margin-top: 40px;
      border-radius: 10px;

      @include media-breakpoint-down(xs) {
        margin-top: 20px;
      }
    }
  }

  .post-list {
    padding-bottom: 50px;
  }

  .blog-post-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    margin-bottom: 80px;

    .blog-image {
      width: 42%;

      img {
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    .blog-summary {
      width: 53%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    .title-and-date {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .post-title {
        width: 100%;
        font-size: 32px;
        font-weight: 800;
        line-height: $leading-tight;
        text-transform: uppercase;
        margin-top: 5px;
        margin-bottom: 25px;
        transition: color 0.2s;
      }

      .post-date {
        font-size: $font-md;
        text-transform: uppercase;
      }
    }

    .post-preview {
      font-size: $font-md;
      font-weight: 300;
      line-height: $leading-base;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      transition: opacity 0.2s;
    }

    &.featured {
      padding-top: 30px;

      .blog-summary {
        width: 100%;

        @include media-breakpoint-up(lg) {
          flex-wrap: nowrap;
        }
      }

      .title-and-date {
        width: auto;
        max-width: 45%;
        margin-right: 40px;

        .post-title {
          order: 1;
          font-size: 56px;
          margin-bottom: 15px;

          @include media-breakpoint-down(xs) {
            font-size: 32px;
          }
        }

        .post-date {
          order: 2;
        }
      }

      .post-preview-wrapper {
        width: 100%;

        .post-preview {
          -webkit-line-clamp: 5;
          margin-top: 8px;
        }
      }
    }

    &:hover {
      .post-title {
        color: $blue11;
        transition: color 0.2s;
      }

      .post-preview {
        opacity: 0.7;
      }
    }

    @include media-breakpoint-down(md) {
      .blog-summary,
      .post-preview-wrapper,
      .blog-image,
      &.featured .title-and-date {
        width: 100%;
        max-width: 100%;
      }

      &:not(.featured) .title-and-date {
        .post-title {
          order: 1;
          margin-top: 25px;
        }

        .post-date {
          order: 2;
        }
      }
    }

    @include media-breakpoint-down(xs) {
      .post-preview-wrapper {
        max-width: calc(100vw - 30px);
      }
    }
  }

  .blog-page-numbers {
    padding-bottom: 60px;

    .page-numbers {
      justify-content: flex-end;
    }
  }
}
