.error_page {
  font-family: Inter, Helvetica, sans-serif;
  text-align: center;
  margin: 10rem 10rem;
  padding: 5rem 5rem;

  @media only screen and (max-width: 767px) {
    padding: 5rem 3rem;
    margin: 0;
  }

  h1 {
    font-size: xx-large;
  }
  span {
    font-size: 0.9rem;
  }
  a {
    color: $blue11;
    font-size: 0.9rem;
    transition: 0.2s;
  }
  a:hover {
    color: $blue11;
  }
}
