.platform-hash-page {
  overflow-x: hidden;
  .container {
    max-width: 1100px;
    padding: 0;
  }

  // TYPOGRAPHY

  .heading-1 {
    font-size: $font-xxxl;
    font-weight: 700;
    margin: 0; // reset for styles in other pages
  }

  .heading-2 {
    font-size: $font-xl;
    font-weight: 600;
    margin: 0; // reset for styles in other pages
  }

  .heading-3 {
    font-size: $font-lg;
    font-weight: 400;
    margin: 0; // reset for styles in other pages
  }

  p {
    color: $gray12;
    font-weight: 400;
    font-size: $font-base;
    line-height: 1.6;
  }

  strong {
    font-weight: 600;
  }

  .gradient-text {
    --background: linear-gradient(149.51deg, #ff6cf9 -26.66%, #ff6868 113.97%);
    background: var(--background);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  // BUTTONS

  button {
    transition: none;
  }

  .dark-button {
    padding: $space3 $space5 $space3;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2a3d4e;
    color: #b8c5d0;
    display: inline-block;
    line-height: 1.25;
    border: none; // overrides styling from external css
    border: 1px solid #36495a;
    border-radius: 6px;
    transition: $transition-base;

    & > * {
      z-index: 2;
    }

    &:focus-visible {
      outline: 3px solid #8b7ffa;
      outline-offset: 3px;
    }

    &:hover {
      background-color: #354b5f;
      border-color: #3e576c;
      color: $gray6;
    }

    svg {
      height: 18px;
      width: auto;
      color: currentColor;
      position: relative;

      &:first-of-type {
        margin-right: $space3;
        top: 3px;
      }

      &:nth-of-type(2) {
        margin-left: $space3;
        top: 2px;
      }
    }
  }

  // COMPONENTS

  // -------------- Sim Redirect Banner –––––––––––––– //
  .sim-banner {
    background: $white;
    box-shadow: $shadow-medium;
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    width: 280px;
    border-radius: 0.35rem;
    transition: $transition-slow;
    z-index: 3;
    .top-bar {
      width: 100%;
      height: 8px;
      border-radius: 0.35rem 0.35rem 0 0;
      background: linear-gradient(160deg, #b47eff 0%, #5b59ee 100%);
    }
    .sim-body {
      padding: 1.25rem;
      border: 1px solid $gray6;
      border-radius: 0 0 0.35rem 0.35rem;
      position: relative;
      top: 0;
      right: 0;
    }
    .sim-header {
      font-size: $font-base;
      color: $gray12;
      margin: $space3 0 0 0;
      font-weight: 600;
    }
    .sim-button {
      position: absolute;
      top: 6px;
      right: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      border: none;
      padding: $space2;
      border-radius: 4px;
      transition: $transition-base;
      &:hover {
        background: $gray4;
      }
      svg {
        fill: $gray12;
      }
    }
    .sim-text {
      font-size: $font-xs;
      color: $gray12;
      padding-right: $space2;
      margin: $space2 0;
    }
    .sim-img {
      width: 180px;
      margin: 0 auto;
      padding-right: 1rem;
    }
    .sim-link {
      font-size: $font-xs;
      color: $purple10;
      font-weight: 600;
      margin: 0;
      display: flex;
      flex-direction: column;
    }
    &:hover {
      transform: translateY(-4px);
      box-shadow: $shadow-high;
      .sim-link {
        color: $purple11;
      }
    }
  }

  .waitlist {
    display: flex;
    flex-direction: column;

    // Form with blue button
    &--blue {
      --btn-background: linear-gradient(
        152.02deg,
        #71bbff -17.81%,
        #4353e4 143.69%
      );
      --copy-color: #798eff;
      --outline-color: #7497e834;
    }

    // Form with purple button
    &--purple {
      --btn-background: linear-gradient(
        159.34deg,
        #958cff -12.61%,
        #6550e7 129.55%
      );
      --copy-color: #7f72cf;
      --outline-color: #8b7ffa43;
    }

    // Form with green button
    &--green {
      --btn-background: linear-gradient(
        325.78deg,
        #019abc -7.14%,
        #45e8de 89.43%
      );
      --copy-color: #45e8de;
      --outline-color: #45e8dd52;
    }

    // Light form
    &--light {
      --input-background: #fefefe;
      --input-border: #eff0f2;
      --input-shadow: 0.3px 2.2px 2.7px hsla(201, 18%, 81%, 0.233),
        0.7px 5.6px 6.9px rgba(199, 209, 216, 0.179),
        1.4px 11.5px 14.2px rgba(196, 208, 214, 0.127);
      --subtext-color: #0090fe;
      --form-border: #0090fe;
      --form-text-color: $gray12;
      --form-placeholder-color: rgb(179, 181, 190);
    }

    // Dark form
    &--dark {
      --input-background: #233544;
      --input-border: #334758;
      --input-shadow: 0.3px 2.2px 2.7px hsla(202, 11%, 15%, 0.432),
        0.7px 5.6px 6.9px rgba(28, 36, 41, 0.437),
        1.4px 11.5px 14.2px rgba(11, 16, 19, 0.597);
      --subtext-color: #ae7bff;
      --form-border: #9b5dff;
      --form-text-color: $gray4;
      --form-placeholder-color: #607788;
    }

    .waitlist__form {
      label {
        display: block;
        color: $gray9;
        font-size: $font-sm;
        line-height: $leading-base;
        // letter-spacing: 0.03rem;
        margin-bottom: $space2;
        // text-transform: uppercase;
        text-align: left;
      }
    }

    .form-input-group {
      display: flex;
      // min-height: 48px;
      border-radius: 6px;
      position: relative;
      margin-bottom: $space2;
      box-shadow: var(--input-shadow);
      border: 1px solid var(--input-border);
    }

    .form-input {
      flex: 1;
      padding: $space3 $space4;
      background: var(--input-background);
      border-radius: $border-radius2;
      color: var(--form-text-color);
      font-size: $font-base;
      line-height: $leading-base;
      font-weight: 400;
      padding-right: $space8;
      border: none;
      outline: none;
      display: flex;
      width: 100%;
      position: relative;
      &:focus {
        outline: 3px solid var(--outline-color);
        outline-offset: 3px;
      }

      &::placeholder {
        color: var(--form-placeholder-color);
      }
    }

    .form-btn-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      min-width: 110px;

      .form-error-icon {
        visibility: hidden;
        opacity: 0;
        height: 20px;
        width: 20px;
        transition: 0.3s ease;

        &--visible {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .form-btn {
      border-radius: 0px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      padding: $space4 $space5;
      font-weight: 600;
      border: 1px solid (--form-border);
      margin-left: auto;
      min-width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: $transition-base;

      svg {
        @keyframes spin {
          100% {
            transform: rotate(360deg);
          }
        }

        height: 18px;
        width: 18px;
        animation: spin 2s linear infinite;
      }

      &:focus-visible {
        outline: 2px solid var(--outline-color);
        outline-offset: 0px;
      }
    }

    &.waitlist--small {
      .form-input,
      .form-btn {
        font-size: $font-sm;
        padding-top: $space3;
        padding-bottom: $space3;
      }
      .form-btn {
        min-width: 50px;
      }
      .form-success {
        font-size: $font-sm;
        line-height: 21px;
      }
    }

    .form-error {
      text-align: left;
      font-size: $font-sm;
      line-height: $leading-base;
      font-weight: 400;
      color: $blue10;
      padding-left: 4px;
    }

    &.waitlist--absolutely-position-error {
      position: relative;

      .form-error {
        position: absolute;
        top: 90%;
        left: 0;
      }
    }

    .form-success {
      position: absolute;
      top: -18px;
      left: 0;
      right: 0;
      bottom: 0;
      background: $blue10;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      padding: 26px;
      color: white;
      font-weight: 500;
      font-size: $font-base;
      line-height: 1.5;
      transition: 0.3s all ease;
      visibility: visible;
      transform: translateX(0);

      // visibility: hidden;
      // transform: translateX(100%);

      // &--visible {
      //   visibility: visible;
      //   transform: translateX(0);
      // }
    }

    .beta-release {
      margin-top: $space3;
      font-size: $font-base;
      line-height: 1.2;
      font-weight: 400;
      color: var(--subtext-color);
      width: 100%;
      padding: $space3 $space5;
      border: 1px solid rgba(110, 153, 255, 0.228);
      border-radius: 30px;
    }
  }

  // SECTIONS

  .header {
    position: relative;
    min-height: 72vh;
    display: flex;
    justify-content: center;
    padding-top: 9rem;

    @include media-breakpoint-down(md) {
      padding-bottom: 2rem;
      padding-top: 4rem;
      min-height: 50vh;
    }

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      @include media-breakpoint-down(md) {
        display: none;
      }

      img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        transform-origin: center;
        width: 100%;
        max-width: 1800px;
        opacity: 0.65;
      }
    }

    &__nav {
      position: fixed;
      background: linear-gradient(
        180deg,
        #222d38 85%,
        rgba(34, 45, 56, 0) 100%
      );
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 90px;
      display: flex;
      z-index: 10;

      @include media-breakpoint-down(lg) {
        background: linear-gradient(
          180deg,
          #222d38 85%,
          rgba(34, 45, 56, 0) 100%
        );
      }

      .container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down(lg) {
          max-width: 90%; // have to use max-width to override bootstraps .container class
          padding: 0;
        }
      }

      .cta-wrapper {
        position: relative;

        .cta {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
          transition: all 0.3s ease;
          min-width: 350px;
          display: flex;
          justify-content: flex-end;

          @include media-breakpoint-down(xs) {
            min-width: 150px;
          }

          &--hidden {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            min-width: 150px;
          }
        }
      }

      .header__nav-btn {
        min-height: 42px;
        padding: 12px 20px;
        --btn-background: linear-gradient(
          145.79deg,
          #91e4ff 20.24%,
          #62c0f5 118.25%
        );
        background: var(--btn-background);
        color: #37434f;
        border: 1px solid transparent;
        position: relative;
        font-size: $font-sm;
        font-weight: 600;
        border-radius: 4px;
        box-shadow: 0px 11px 30px rgba(61, 78, 133, 0.04),
          0px 7.12963px 18.37px rgba(61, 78, 133, 0.05),
          0px 4.23704px 8.1px rgba(61, 78, 133, 0.06),
          0px 0.203704px 0.62963px rgba(61, 78, 133, 0.07);

        &:focus-visible {
          outline: 3px solid #90e4ff;
          outline-offset: 3px;
        }

        &:hover {
          background: linear-gradient(
            145.79deg,
            #b6edff 20.24%,
            #8dd6ff 118.25%
          );
          border-color: #ffffff;

          &:disabled {
            background: var(--btn-background);
            border-color: transparent;
          }
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      position: relative;

      max-width: clamp(320px, 75vw, 900px);
      @include media-breakpoint-down(md) {
        width: 90%;
        padding: 0;
      }
      .heading-1 {
        margin-bottom: $space2;
      }

      .lead {
        max-width: 600px;
        background: linear-gradient(139.58deg, $blue10 8.56%, $blue11 154.83%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: $font-md;
        line-height: $leading-base;
        margin-bottom: $space7;
      }

      .waitlist-container {
        width: 80%;
        max-width: 420px;

        &#waitlist {
          scroll-margin-top: 90px;
        }

        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
    }
  }

  // KNOWLEDGE GRAPH SECTION
  .knowledge-graphs {
    display: flex;
    margin: $space9 auto;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin-bottom: 160px;
      margin: $space8 auto $space6;
    }

    &.container {
      @include media-breakpoint-down(md) {
        padding: 0 $space4;
      }
    }

    & > .column:first-of-type {
      flex: 0.48;

      @include media-breakpoint-down(md) {
        flex: 1;
      }

      .heading-2 {
        background: linear-gradient(
          121.38deg,
          $purple9 31.06%,
          $purple10 139.31%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 1.1;
        margin-bottom: $space5;
        font-weight: 500;
        padding-bottom: $space1;
      }

      div.decorative-line {
        height: 2px;
        width: 120px;
        background: $purple7;
        margin-bottom: $space6;
      }

      .description {
        width: 85%;

        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
    }

    & > .column:last-of-type {
      flex: 0.52;
      display: flex;
      justify-content: center;

      @include media-breakpoint-down(md) {
        flex: 1;
      }

      img {
        display: block;
        width: 100%;
        max-width: 660px;

        @include media-breakpoint-down(md) {
          margin-top: 48px;
        }
      }
    }
  }

  // BLOCKS SECTION
  .blocks {
    background: linear-gradient(0deg, $gray3 0%, $gray1 100%);
    margin: $space9 auto 0;
    padding: $space9 0;
    border-top: 1px solid $gray4;
    box-shadow: inset 1px 4px 8px $gray2;

    @include media-breakpoint-down(md) {
      margin: $space8 auto 0;
      padding: $space8 0;
    }

    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(auto, 3);
      column-gap: $space8;
      row-gap: $space6;
      margin-bottom: $space7;

      @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        row-gap: $space4;
        padding: 0 $space4;

        & > div:first-of-type {
          grid-row: 2/3;
        }
        & > div:nth-of-type(2) {
          grid-row: 1/2;
        }
      }
    }

    .block-item-with-img {
      // @todo should we set a max-width at this point
      @include media-breakpoint-down(md) {
        max-width: 650px;
        margin-left: auto;
        margin-right: auto;
      }

      &__title {
        text-transform: uppercase;
        font-size: $font-sm;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.05rem;
        margin-bottom: $space2;
        color: $gray8;
      }

      &__content {
        position: relative;

        @include media-breakpoint-down(xs) {
          margin-right: -10vw; // makes it stretch to the edge of the screen like in the design
        }
      }

      &__handle {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        height: 30px;
        width: 30px;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &__image {
        display: block;
        width: 100%;
        position: relative;
        top: -8px;
        left: -8px;
        z-index: 2;
        @include media-breakpoint-down(sm) {
          width: 95vw;
        }
      }

      &__image-backdrop-1,
      &__image-backdrop-2 {
        position: absolute;
        height: 90%;
        width: 94%;
        left: 48%;
        transform: translateX(-50%);
        opacity: 0.4;
        border-radius: $border-radius3;
        border: 2px solid white;

        @include media-breakpoint-down(xs) {
          display: none;
        }
      }

      &__image-backdrop-1 {
        top: 20px;
        width: 92%;
        background: hsl(204, 22%, 88%);
        opacity: 0.3;
      }

      &__image-backdrop-2 {
        top: 30px;
        width: 89%;
        background: hsl(204, 46%, 72%);
        opacity: 0.1;
      }
    }

    div.decorative-line {
      height: 2px;
      width: 120px;
      background: $blue8;
      margin: $space5 0 $space6;
    }

    .block-item-text-only {
      &--1 {
        .title {
          background: linear-gradient(165deg, $purple9 10%, $blue9 90%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          padding-bottom: $space2;
          margin: $space7 0 0 0;
          line-height: 1.1;
          @include media-breakpoint-down(md) {
            margin: 0;
          }
        }
      }

      &--2 {
        width: 86%;
        margin: 0 auto;
        @include media-breakpoint-down(md) {
          width: 100%;
        }

        .title {
          background: linear-gradient(149.64deg, $blue9 6.34%, $blue10 114.07%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: $space5;
          line-height: 1.1;
          padding-top: $space7; // accounts for the absolutely positioned backdrops in the blocks above
          @include media-breakpoint-down(md) {
            margin: 0;
          }
        }
      }
    }

    .block-item-img-only {
      padding-top: $space7; // accounts for the absolutely positioned backdrops in the blocks above
      @include media-breakpoint-down(md) {
        padding-top: 0;
      }
      img {
        display: block;
        @include media-breakpoint-down(md) {
          width: 100%;
          max-width: 650px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  // –––––––––– Block Protocol Section ----––––––– //
  .blockprotocol {
    margin-top: -2rem;
    margin-bottom: -4rem;
    background: #f2f3f5;
    z-index: 3;

    .blockprotocol-card {
      max-width: 1200px;
      margin: 0 auto;
      background: linear-gradient(
        180deg,
        #0181f1 59.71%,
        rgba(1, 129, 241, 0) 100%
      );
      position: relative;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      grid-gap: 2rem;
      padding: 3.5rem 4.2rem 3.2rem;
      border-radius: $border-radius4;

      @include media-breakpoint-down(md) {
        padding: 3rem $space4 3rem;
        flex-direction: column;
        margin-bottom: -2rem;
      }

      &__img {
        height: 64px;
        color: #ffffff;
      }

      .content {
        margin: 0 auto $space4;
        overflow: hidden;
        transition: 0.3s ease;
        text-align: center;
        // assigned this a padding left instead of margin to prevent hiding
        // the cta's focus styles due to the overflow style set

        @include media-breakpoint-down(md) {
          width: 100%;
          margin-right: 0;
          align-self: center;
          padding-left: 0;
        }

        &__title {
          font-size: $font-xl;
          color: #fff;
          line-height: $leading-snug;

          @include media-breakpoint-down(md) {
            width: 90%;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
          }
        }

        &__description {
          font-size: $font-base;
          max-width: 64ch;
          @include media-breakpoint-down(md) {
            // font-size: $font-base;
          }

          a {
            border: none;
            color: currentColor;
            text-decoration: underline;
          }

          p {
            font-size: inherit;
            color: #deefff;
          }
        }
      }

      .learn-more {
        text-transform: uppercase;
        font-size: $font-xs;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.05rem;
        display: flex;
        align-items: center;
        color: #0281f1;
        background: white;
        padding: $space2 $space4;
        border: none;
        border-radius: 30px;

        &:hover {
          color: #016ece;
        }

        &:focus-visible {
          outline: 3px solid #0281f1;
          outline-offset: 3px;
          color: #016ece;
        }

        svg {
          margin-left: $space2;
          height: $space4;
          width: $space4;
          color: currentColor;
        }
      }

      &__cta {
        background-color: #0281f1;
        color: white;
        display: inline-flex;
        align-items: center;
        padding: $space3 $space5;
        font-size: $font-base;
        line-height: $leading-base;
        border-radius: $border-radius2;
        position: relative;
        margin: $space3;

        @include media-breakpoint-down(md) {
          padding: 12px 18px;
          font-size: $font-sm;
        }

        &:focus-visible {
          border-color: #0281f1;
          background: #0281f1;
          color: #ffffff;
          outline: 3px solid #0281f1;
          outline-offset: 3px;
        }

        &:hover {
          background: #0173d6;
          border-color: rgb(0, 108, 202);
          color: #fff;
        }

        svg {
          margin-left: $space2;
          color: currentColor;
          height: $space4;
        }
      }
    }
  }

  // –––––––––– Unlimited Entities Section ----––––––– //
  .unlimited-entities {
    display: flex;
    // margin-bottom: 110px;
    margin-bottom: $space10;

    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
      margin-bottom: $space2;
    }

    .illustration {
      margin-left: -2%;
      margin-top: -2%;
      width: 60%;
      filter: drop-shadow(0px 2.89146px 34.6975px rgba(34, 45, 56, 0.3));
      display: none;

      @include media-breakpoint-down(md) {
        width: 95%;
        margin-top: 32px;
      }

      &--desktop {
        display: block;
        margin-right: 50px;
        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &--mobile {
        @include media-breakpoint-down(md) {
          display: block;
        }
      }
    }

    .content {
      max-width: 400px;

      @include media-breakpoint-down(md) {
        padding: 0 5%;
        max-width: none;
      }

      h3 {
        --background: linear-gradient(
          128.45deg,
          #ff6663 -1.03%,
          #ff8468 83.53%
        );
      }
    }
  }

  // –––––––––– Features Section ----––––––– //
  .features-section {
    margin: $space10 auto;
    position: relative;

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 127px;
    }

    .container {
      padding: 0;
      width: 78%;
      margin: 0 auto;
    }

    .content {
      max-width: 255px;

      @include media-breakpoint-down(md) {
        max-width: unset;
      }
    }

    .features__media {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      left: 45%;
      right: 0;

      @include media-breakpoint-down(md) {
        position: static;
        width: 90%;
        height: 262px;
        margin: 0 auto;
        margin-bottom: 32px;
      }

      &__illustration {
        position: absolute;
        display: block;
        right: 0;
        top: -100px;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &__content {
        position: relative;
        background-color: white;
        height: 100%;
        z-index: 2;
        border-top-left-radius: $space2;
        border-bottom-left-radius: $space2;

        @include media-breakpoint-down(md) {
          border-radius: $space2;
        }
      }
    }

    ul {
      padding-inline-start: 0;
      list-style-type: none;
    }

    .features__current {
      li {
        position: "relative";
        display: flex;
        align-items: flex-start;
        margin-bottom: 32px;

        &:before {
          content: url(/assets/hashhome/check-icon.svg);
          position: absolute;
          transform: translateX(calc(-100% - 15px));
        }
      }
    }

    hr {
      margin-bottom: 32px;
      border: 1px solid #4d5c6c;
    }

    .title {
      font-size: $font-xs;
      line-height: 1.2;
      letter-spacing: 0.05rem;
      font-weight: 500;
      margin-bottom: 12px;
      text-transform: uppercase;
    }

    .features__coming-soon {
      margin-bottom: 32px;

      .title {
        color: #afe170;
      }
      li {
        margin-bottom: 12px;
      }
    }

    .features__planned {
      .title {
        color: #48eaf4;
      }
      li {
        margin-bottom: 12px;
      }
    }
  }

  // –––––––––– Open Source Section ----––––––– //
  .open-source {
    margin-bottom: $space8;
    position: relative;
    padding: 0 $space4;

    .title-wrapper {
      margin-bottom: $space7;
      @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        margin-bottom: $space3;
      }
    }

    &__illustration {
      position: absolute;
      left: 0;
      top: -200px;
      display: block;
      width: 12vw;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .title {
      font-family: "Open Sauce Two", sans-serif !important;
      font-size: 8rem;
      line-height: 1.1;
      font-weight: 600;
      position: relative;
      z-index: 2;
      @include media-breakpoint-down(md) {
        font-size: 6rem;
      }
      @include media-breakpoint-down(sm) {
        font-size: 2.6rem;
        word-break: break-all;
      }

      & > span {
        display: block;

        &:nth-of-type(1) {
          color: #dde7f0;
        }
        &:nth-of-type(2) {
          --background: linear-gradient(
            110.58deg,
            #ff6a89 4.31%,
            #ff6c4b 86.94%
          );
        }
        &:nth-of-type(3) {
          --background: linear-gradient(
            108.24deg,
            #ff8e6a 27.21%,
            #ffc977 76.29%
          );
        }
      }
    }

    .columns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $space9;

      @include media-breakpoint-down(md) {
        column-gap: 0;
        row-gap: $space6;
        grid-template-columns: 1fr;
        max-width: 65ch;
      }

      .column {
        &__title {
          margin-bottom: $space4;
        }

        &:nth-of-type(1) {
          .column__title {
            color: #ff8e6a;
          }
        }
        &:nth-of-type(2) {
          .column__title {
            color: #ffc977;
          }
        }
      }
    }
  }

  // –––––––––– Integrations Section ----––––––– //
  .integrations {
    .__label {
      width: 100%;
      display: flex;
      span {
        font-size: $font-base;
        letter-spacing: 0.15rem;
        font-weight: 400;
        text-transform: uppercase;
        color: hsl(36, 99%, 73%);
        margin: 0 auto $space7;
      }
    }
    .__grid {
      max-width: 1100px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
      align-items: center;
      justify-content: center;
      column-gap: $space7;
      row-gap: $space7;
      margin-bottom: 8rem;

      @include media-breakpoint-down(lg) {
        max-width: 100%;
        margin-bottom: $space9;
        grid-template-columns: repeat(4, auto);
        row-gap: $space7;
        justify-items: center;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: $space9;
        grid-template-columns: 1fr;
        row-gap: $space7;
        justify-items: center;
        padding: 0 $space4;
      }
    }
    .integration-logo {
      margin: 0 auto;
    }
  }

  .darkbg {
    background: linear-gradient(0deg, #1f2630 0%, #262f3a 100%);
    z-index: 0;
    p {
      color: #94a4b7;
    }
  }

  // ----------–––––– Usage Section ––––––––––––––––– //
  .usage-section {
    @include media-breakpoint-down(md) {
      margin-bottom: $space7;
    }

    .usecases.container {
      display: flex;
      padding: 9rem 0 8rem;
      @include media-breakpoint-down(md) {
        flex-direction: column;
        padding: 4rem $space4 1rem;
      }
    }

    .column:first-of-type {
      margin-right: $space6;

      @include media-breakpoint-down(md) {
        margin-right: 0;
        margin-bottom: $space3;
      }

      .title {
        font-size: $font-sm;
        line-height: $leading-snug;
        letter-spacing: 0.05rem;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: $space4;
        color: #64778c;

        @include media-breakpoint-down(md) {
          font-size: $font-sm;
          padding-left: 5%;
        }
      }

      .nav {
        width: 220px;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(md) {
          flex-direction: row;
          overflow: scroll;
          padding-left: 5%;
          padding-bottom: $space2;

          &::-webkit-scrollbar {
            height: 0px;
            width: 0px;
          }
        }

        &__item {
          background: transparent;
          padding: 0;
          color: #64778c;
          border: none;
          text-align: left;
          margin-bottom: $space4;
          transition: $transition-base;

          @include media-breakpoint-down(md) {
            margin-bottom: 0;
            margin-right: $space4;
            font-size: $font-md;
          }

          &:hover,
          &:focus-visible {
            color: #91a5ba;
          }

          &--active,
          &--active:focus {
            color: #ffc368;
            font-weight: 600;
          }
        }
      }
    }

    .column:nth-of-type(2) {
      position: relative;

      @include media-breakpoint-down(md) {
        margin: 0 5vw;
        // width: 90vw;
        display: flex;
        flex-direction: column;
      }

      .usecase {
        flex: 1;

        // the first image should be positioned statically
        // to fill the container, while the other images
        // are positioned absolutely
        &:not(:first-of-type) {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
        }

        &__img-wrapper {
          position: relative;
          height: 0;
          top: 0;
          left: 0;
          padding-top: 65%;
          margin-bottom: $space4;

          @include media-breakpoint-down(md) {
            margin-bottom: $space5;
          }
        }

        &__img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &__apps {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }

        &__app {
          font-size: $font-sm;
          line-height: $leading-snug;
          letter-spacing: 0.05rem;
          text-transform: uppercase;
          font-weight: 500;
          margin-right: $space5;
          color: #64778c;
          margin-bottom: $space2;

          @include media-breakpoint-down(md) {
            margin-right: $space4;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }

  // –––––––––– Benefits Section ----––––––– //
  .benefits-section {
    max-width: 1100px;
    margin: 0 auto;
    margin-bottom: 8rem;
    padding: 0 $space4;
  }

  @include media-breakpoint-down(md) {
    .benefits-section {
      max-width: 300px;
      margin-bottom: 4rem;
    }
  }

  .benefits {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: $space7;

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr 1fr;
      column-gap: 0;
      row-gap: $space7;
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
    }

    .benefit {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-down(md) {
        align-items: center;
      }

      &:nth-of-type(1) {
        color: hsl(34, 100%, 73%);
      }

      &:nth-of-type(2) {
        color: hsl(23, 100%, 70%);
      }

      &:nth-of-type(3) {
        color: hsl(18, 100%, 70%);
      }

      svg {
        height: 32px;
        width: 32px;
        color: currentColor;
        @include media-breakpoint-down(md) {
          height: 48px;
          width: 48px;
        }
      }

      &__description {
        font-size: $font-lg;
        line-height: 1.2;
        font-family: "Open Sauce Two";
        font-weight: 400;
        color: currentColor;
        @include media-breakpoint-down(md) {
          text-align: center;
        }
      }
    }
  }

  // MID CTA SECTION
  .mid-cta-section {
    background-color: hsl(210, 32%, 14%);
    padding-top: 8rem;
    padding-bottom: 6.5rem;
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: $space9;

    @include media-breakpoint-down(md) {
      padding-top: 120px;
      padding-bottom: 100px;
      background-color: unset;
    }

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(/assets/hashhome/mid-cta-section-bg.svg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      @include media-breakpoint-down(md) {
        background-image: url(/assets/hashhome/mid-cta-section-bg-mobile.svg);
      }
    }

    .container {
      position: relative;
      z-index: 2;
      width: 90%;
      max-width: 460px;
      text-align: center;

      @include media-breakpoint-down(md) {
        padding: 0;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-family: "Open Sauce Two" !important;
        font-size: 55px;
        line-height: 120%;
        color: #45e8de;
        margin-bottom: 24px;
      }

      .waitlist-container {
        width: 100%;
        margin-bottom: 48px;
      }
    }
  }

  // ROADMAP
  .roadmap-section {
    background: linear-gradient(0deg, $white 0%, $gray3 100%);
    margin: 0 auto;
    padding: $space7 0;
    border-top: 8px solid $blue9;
    box-shadow: inset 1px 4px 8px $gray2;

    .container {
      padding: 0 $space4;
      @include media-breakpoint-down(md) {
        width: 90%;
        padding: 0 $space4;
      }
    }

    .title {
      font-size: $font-xxl;
      font-family: "Open Sauce Two";
      font-weight: 400;
      line-height: 1.2;
      color: #0081f1;
      margin-bottom: $space4;
    }

    .subtitle {
      margin-bottom: 50px;
      color: $gray12;
      font-size: $font-base;
    }

    .roadmap__img {
      display: block;
      margin-bottom: $space9;
      width: 100%;
      max-width: 980px;

      @include media-breakpoint-down(md) {
        max-width: 280px;
        margin-bottom: $space8;
      }

      &--desktop {
        display: block;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &--mobile {
        display: none;
        @include media-breakpoint-down(md) {
          display: block;
        }
      }
    }
  }

  // WHY HASH
  .why-hash {
    margin-bottom: 240px;

    &.container {
      @include media-breakpoint-down(md) {
        width: 90%;
        padding: 0;
      }
    }

    .title {
      font-family: Inter, Helvetica, sans-serif;
      font-size: $font-sm;
      text-transform: uppercase;
      line-height: 130%;
      font-weight: 600;
      letter-spacing: 0.05rem;
      color: #64778c;
      margin-bottom: $space4;
    }

    .subtitle {
      font-size: 54.92px;
      font-family: "Open Sauce Two";
      font-weight: 400;
      line-height: 1.2;
      color: #66c4e7;
      margin-bottom: 30px;
      width: 80%;
      max-width: 700px;

      @include media-breakpoint-down(md) {
        width: 100%;
        font-size: 43px;
      }
    }

    .description {
      max-width: 400px;
      margin-bottom: 80px;

      @include media-breakpoint-down(md) {
        max-width: unset;
      }

      strong {
        color: #66c4e7;
      }
    }

    .media {
      position: relative;
      margin-top: 40px;
      margin-bottom: 40px;

      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 48px;
      }

      &__form {
        box-shadow: 0px 11px 30px rgba(61, 78, 133, 0.04),
          0px 7.12963px 18.37px rgba(61, 78, 133, 0.05),
          0px 4.23704px 8.1px rgba(61, 78, 133, 0.06),
          0px 0.203704px 0.62963px rgba(61, 78, 133, 0.07);
        display: block;
        max-width: 270px;

        @include media-breakpoint-down(md) {
          width: 80%;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 32px;
        }
      }

      &__graph {
        position: absolute;
        left: 60%;
        top: 50%;
        transform: translateY(-50%);

        &--desktop {
          width: 70vw;
          max-width: 890px;
          display: block;

          @include media-breakpoint-down(md) {
            display: none;
          }
        }

        &--mobile {
          display: none;

          @include media-breakpoint-down(md) {
            display: block;
            position: static;
            transform: none;
            width: 100%;
            max-width: 360px;
          }
        }
      }
    }

    .tools {
      margin-top: 24px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 6%;

      @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        grid-gap: 0;
        row-gap: 32px;
      }
    }

    .tool {
      &__title {
        display: flex;
        margin-bottom: $space4;
        font-size: 28px;
        line-height: 130%;
        font-family: "Open Sauce Two";
        font-weight: 400;

        &:nth-of-type(1) {
          color: #6880fb;
        }

        &:nth-of-type(2) {
          color: #716fff;
        }

        &:nth-of-type(3) {
          color: #a56fff;
        }

        svg {
          color: #64778c;
          border-radius: 4px;
          margin-left: $space2;
        }

        &:hover {
          svg {
            background-color: #0e1114;
          }
        }

        &:focus {
          outline: none;

          svg {
            background-color: #0e1114;
            outline: 3px solid #8b7ffa;
            outline-offset: 3px;
          }
        }
      }
    }
  }

  // FOOTER SECTION
  .footer {
    background: linear-gradient(180deg, #1a2834 50%, rgb(25, 31, 36) 110%);
    padding: 8rem 0;
    position: relative;
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(md) {
      padding: 5rem 0;
    }

    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(/assets/hashhome/footer-bg.svg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      @include media-breakpoint-down(md) {
        background-image: none;
      }
    }

    .container {
      position: relative;
      z-index: 2;
      max-width: 460px;
      text-align: center;

      @include media-breakpoint-down(sm) {
        padding: 0 $space4;
        max-width: 100%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-family: "Open Sauce Two" !important;
        font-size: $font-xxl;
        line-height: 120%;
        color: #af6eff;
        margin-bottom: $space5;
      }

      .waitlist-container {
        width: 100%;
        margin-bottom: $space5;
      }

      .copy {
        margin: $space3 auto;

        p:first-of-type {
          text-transform: uppercase;
          color: #a786ff;
          margin-bottom: 0;
          font-size: $font-sm;
          letter-spacing: 0.05rem;
          font-weight: 600;
          line-height: 120%;
        }

        p:last-of-type {
          font-weight: 400;
          color: #91a5ba;
          margin: $space3 auto $space2;
        }
      }
    }
  }
}
