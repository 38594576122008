.docs-table {
  line-height: 1.625;

  a {
    color: inherit;
    text-decoration: inherit;
    border-bottom: 0.2rem solid $gray7;
    transition: color 0.2s, border-color 0.3s;
    font-weight: 600;

    &:hover {
      color: $blue11;
      border-color: $blue11;
      text-decoration: inherit;
      cursor: pointer;
    }

    &.no-underline {
      border-bottom: none;

      &:hover {
        border-bottom: none;
      }
    }
  }

  .text-left {
    text-align: left;
  }

  tbody > tr:first-child {
    font-weight: 400 !important;
  }

  tr {
    border-bottom: 0.1rem solid #dfdfdf;
  }

  th {
    padding-bottom: 0.75rem;
  }
}

.glossary-term-page {
  .glossary-post-main-content {
    > .container {
      display: flex;
      justify-content: space-between;

      .tags-list {
        margin-top: 40px;
      }

      .glossary-post-content,
      .right-col {
        padding-bottom: 5rem;
      }

      .right-col {
        padding-top: calc(5rem - 20px);
      }

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;

        .glossary-post-list-menu {
          display: none;
        }

        .docs-list-sidebar {
          display: none;
        }

        .glossary-list-sidebar {
          display: none;
          padding-top: 3rem;
          width: 300px;
          margin-right: 0;
        }

        .glossary-post-content {
          padding-top: 1rem;
        }
      }
    }

    form {
      margin-top: 3rem;
    }
  }

  .scrolly-navigation {
    padding-top: 20px;
  }

  .cases-sidebar,
  .glossary-list-sidebar,
  .docs-list-sidebar {
    width: 240px;
    min-width: 190px;
    flex-shrink: 0;
    line-height: 120%;
    margin-right: 60px;
    padding-bottom: 5rem;
    padding-top: calc(5rem - 20px);

    .MuiSkeleton-text {
      transform: inherit;
    }

    a {
      opacity: 0.8;
      width: 100%;

      &:hover {
        opacity: 1;
        color: $blue11;
        transition: all 0.1s;
      }
    }

    h4 {
      margin-top: 0px;
      margin-bottom: 10px;
      font-size: $font-base;
    }

    .learn-list-sticky {
      position: sticky;
      top: -1px;
      height: 100vh;
      display: flex;
      flex-direction: column;
      padding-top: 20px;
    }

    .learn-list-overflow {
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;

      .glossary-header {
        margin-top: 25px;
      }
    }

    .sidebar-header {
      padding-right: 7.5px;

      a {
        opacity: 1;
      }
    }

    .sidebar-header-title {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      h4 {
        text-transform: uppercase;
        margin-bottom: 20px;
        max-width: 190px;
      }
    }

    .glossary-post-summary.active {
      padding-left: 15px;
      border-left: 3px solid #2482ff;
      font-weight: 500;
    }

    .sidebar-header {
      padding-top: 25px;
      padding-bottom: 21px;
      padding-right: 7.5px;

      .sidebar-header-title {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
      }

      .sidebar-overview {
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: $font-sm;
      }

      .sidebar-overview.active {
        padding-left: 1em;
        border-left: 3px solid #2482ff;
      }

      .sidebar-level1-title {
        text-transform: uppercase;
        margin-top: 30px;
        font-size: $font-sm;
        font-weight: 700;
      }

      .sidebar-level1 {
        font-size: $font-sm;
        margin-top: 5px;
      }

      .sidebar-level1-header {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .sidebar-level1-header.active {
        padding-left: 1em;
        border-left: 3px solid #2482ff;
        font-weight: 600;
      }

      .sidebar-level1-list {
        border-left: 1px solid #f1f3f6;
        margin: 5px 0;
      }

      .sidebar-level2-container {
        padding-top: 2.5px;
        padding-bottom: 2.5px;

        &:first-child {
          padding-top: 0px;
        }

        &:last-child {
          padding-bottom: 0px;
        }
      }

      .sidebar-level2 {
        padding-left: 15px;

        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .sidebar-level2.active {
        border-left: 3px solid #2482ff;
        font-weight: 600;
        padding-left: 12px;
      }

      .sidebar-level2-list {
        margin: 5px 0;
        margin-left: 15px;
        border-left: 1px solid #f1f3f6;
      }

      .sidebar-level3-container {
        padding-top: 2.5px;
        padding-bottom: 2.5px;

        &:first-child {
          padding-top: 0px;
        }

        &:last-child {
          padding-bottom: 0px;
        }
      }

      .sidebar-level3 {
        padding-left: 15px;
      }

      .sidebar-level3.active {
        border-left: 3px solid #2482ff;
        font-weight: 600;
        padding-left: 12px;
      }
    }

    .glossary-post-list-menu {
      .glossary-tabs-header {
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
        justify-content: space-between;
        align-items: center;

        .tabs-header {
          font-weight: bold;
          font-size: $font-md;
        }

        .tabs-parent {
          font-weight: bold;
          font-size: $font-xs;
          margin-right: 10px;

          .tab {
            padding: 5px 10px;
            cursor: pointer;
            border-radius: 7px;
            transition: all 0.2s ease-in-out;

            &.alphabetic {
              margin-right: 5px;
            }

            &:hover {
              background-color: rgba(232, 239, 245, 0.6);
            }

            &.active {
              background-color: #e8eff5;
              color: #2482ff;
            }
          }
        }
      }

      a {
        display: block;
        font-size: $font-sm;
        margin-bottom: 12px;
        font-weight: 300;
        transition: color 0.2s;

        &:hover {
          color: $blue11;
        }
      }
    }
  }

  .glossary-list-sidebar,
  .docs-list-sidebar {
    border-right: 1px solid #f1f3f6;
  }
}

.search-bar {
  padding-bottom: 35px;
  position: relative;

  form {
    margin-top: 0px !important;
  }

  &.desktop {
    padding-right: 3em;
    border-bottom: 1px solid #f1f3f6;

    .search-icon {
      right: calc(3em + 18px);
    }
  }

  &.mobile {
    padding-bottom: 20px;
    max-width: calc(100% - 20px);

    .search-list-container {
      width: auto;
    }
  }

  &.glossary-homepage {
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &.md-desktop {
    margin-bottom: 20px;
    margin-top: 20px;
    display: none;

    .search-list-container {
      width: 100%;
    }

    @include media-breakpoint-between(md, md) {
      display: block;
    }
  }

  input {
    background: #ffffff;
    border: 1px solid #d6d6d6;
    border-radius: 6px;
    padding: 12px 18px;
    padding-right: 36px;
    width: 100%;
  }

  .search-icon {
    top: 0.9em;
    right: 18px;
    position: absolute;
    height: 14px;
    width: 14px;
    cursor: pointer;

    button {
      padding: 0px;
      background-color: transparent;
      border: 0px;
      transition: none;
    }

    path {
      fill: #1b1d24;
    }

    .loader {
      color: #c3c3c3;
    }
  }

  .search-list-container {
    width: 300px;
    max-height: 550px;
    position: absolute;
    overflow: auto;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    z-index: 1;

    .search-list-info {
      padding: 1em;
      font-size: $font-xs;
      line-height: 180%;
    }

    .search-list {
      background-color: white;

      .search-list-item {
        cursor: pointer;
        margin-bottom: 0px;
        display: block;
        transition: all none;

        &:not(:last-child) {
          border-bottom: 1px solid #f1f3f6;
        }

        .search-list-item-container {
          padding: 1em;
        }

        transition: all none;

        &.active {
          background: #2482ff;
          opacity: 1;

          color: white;
          transition: all none;

          .search-content-highlight {
            color: white;
            transition: all none;

            .highlight-text {
              background-color: rgba(256, 256, 256, 0.3);
            }
          }
        }

        .search-content-title {
          font-size: $font-xs;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          svg {
            width: 0.8em;
            margin-bottom: -1px;
            path {
              fill: #ccc;
            }
          }
        }

        .search-content-highlight {
          font-size: $font-xs;
          color: rgb(116, 129, 141);

          .highlight-text {
            background-color: rgba(56, 132, 255, 0.3);
          }
        }
      }
    }
  }
}
