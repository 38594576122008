.new-note {
  margin-top: 50px;

  .new-discussion-form {
    width: 100%;

    .input-group {
      margin-bottom: 15px;
    }
  }

  .buttons {
    display: flex;
    align-items: flex-end;
    width: 100%;

    &.align-right {
      justify-content: flex-end;
    }

    button:nth-child(2) {
      margin-left: 20px;
    }
  }
}