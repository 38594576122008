.project-overview {
  max-width: calc(100vw - 30px);
  width: 100%;

  .cover-image {
    width: 100%;
    margin: 0 auto;

    img {
      width: 540px;
      height: 283px;
      object-fit: cover;

      @include media-breakpoint-down(xs) {
        max-width: 100%;
      }
    }
  }

  .label {
    margin-bottom: 10px;

    @include media-breakpoint-down(xs) {
      padding-bottom: 5px;
    }
  }

  .value {
    width: 100%;
    word-break: break-word;
  }
}
