.search-results-page-wrapper {
  .mobile-sidedrawer,
  .filter-drawer-toggle {
    display: none;
  }

  .search-results-content {
    font-family: Inter, Helvetica, sans-serif;
    display: flex;

    .search-results-main {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      justify-content: space-between;
      align-content: flex-start;
      width: 100%;
      padding: 30px 0;
      border-left: 1px solid $gray8;

      .results-list {
        width: 100%;
        margin-top: 30px;
      }

      .paginate {
        width: 100%;
        padding-left: 30px;
        padding-top: 20px;
      }

      .count-and-sort {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $gray13;
        font-size: $font-xs;
        font-weight: 600;
        letter-spacing: 0.05rem;
        margin-left: 30px;
        position: relative;
        min-height: 40px;

        .loading-indicator {
          position: absolute;
          right: 30px;
        }

        .result-count {
          text-align: right;
          margin-right: 40px;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .mobile-sidedrawer {
      display: block;

      .search-results-sidebar {
        padding: 15px 0;
      }
    }

    .search-results-content .search-results-main {
      padding: 30px 0;
      border: none;

      .results-list {
        margin-top: 20px;
      }

      .count-and-sort {
        justify-content: space-between;
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin-left: 0;

        .result-count {
          width: auto;
        }
      }

      button.filter-drawer-toggle {
        display: inline-block;
        border: 1px solid $gray8;
        border-radius: 6px;
        background: none;
        color: $gray13;
        font-size: $font-xs;
        font-weight: 600;
        padding: 12px 18px;

        &:hover {
          color: $blue11;
          border: 1px solid $gray6;
        }
      }
    }

    .desktop-sidedrawer {
      display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    .search-results-main {
      max-width: 767px;
      padding: 25px;
    }

    .results-list {
      max-width: calc(100vw - 30px);
    }

    .search-results-content .search-results-main {
      .paginate {
        padding-left: 0;
      }

      .count-and-sort {
        .sort-dropdown {
          width: 160px;
          margin-top: 0;
        }

        button.filter-drawer-toggle {
          margin-top: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .search-results-content .search-results-main {
      flex-wrap: wrap;
      padding: 30px 0;

      .results-list {
        margin-top: 15px;
      }

      .count-and-sort {
        .result-count {
          margin-bottom: 10px;
        }
      }

      .sort-dropdown {
        margin-right: 15px;
      }
    }
  }

  @media (max-width: 500px) {
    .search-results-content .search-results-main {
      .count-and-sort {
        flex-wrap: wrap;

        .result-count {
          width: auto;
          margin-right: 20px;
        }
      }
    }
  }
}
