.glossary-home {
  .mainsite-header {
    .mainsite-title {
      h1 {
        display: flex;
        align-items: center;

        img {
          margin-right: 20px;
          margin-top: 0;
          margin-bottom: 0;
          border-radius: 10px;

          @include media-breakpoint-down(xs) {
            height: 40px;
            width: 40px;
            margin-right: 15px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }

  .glossary-home-content {
    padding: 4.5rem 0;

    > .container {
      display: flex;
      justify-content: space-between;
    }

    .content-container {
      width: 100%;
      padding-right: 30px;

      @include media-breakpoint-down(sm) {
        padding-right: 0px;
      }
    }

    @include media-breakpoint-down(xs) {
      padding: 3rem 0;
    }
  }

  .search-bar.glossary-homepage,
  .post-list {
    width: 75%;
    max-width: 640px;

    @include media-breakpoint-down(lg) {
      width: 90%;
    }
  }

  .post-list {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    .glossary-post-summary {
      display: block;
      margin-bottom: 40px;

      h2 {
        font-size: $font-lg;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 8px;
        transition: color 0.2s;
      }

      p {
        font-weight: 300;
        line-height: $leading-base;
        margin: 0;
        transition: opacity 0.2s;
      }

      &:hover {
        h2 {
          color: $blue11;
        }

        p {
          opacity: 0.7;
        }
      }
    }

    .glossary-post-summary.active {
      padding-left: 1em;
      border-left: 3px solid #2482ff;
      font-weight: 500;
    }
  }

  .glossary-home-sidebar {
    width: 250px;
    flex-shrink: 0;
  }

  @include media-breakpoint-down(sm) {
    .glossary-home-content > .container {
      flex-wrap: wrap;
    }

    .glossary-home-sidebar {
      width: 100%;
      order: 1;
      border-bottom: 1px solid $gray6;
      padding-bottom: 50px;

      .wp-sidebar-section {
        max-width: 400px;
        margin-bottom: 0;

        &:first-child {
          margin-bottom: 40px;
        }
      }
    }

    .post-list {
      margin-top: 50px;
      order: 2;
      width: 100%;
      max-width: 100%;
      margin-right: 0;
    }
  }
}
