.input-popup {
  @include media-breakpoint-down(sm) {
    position: relative;
    span > .bubble {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 0.5rem;
      margin-bottom: 1rem;

      .triangle {
        &:after,
        &:before {
          transform: rotate(-90deg);
        }
        &:after {
          top: -1.2rem;
          left: 13.8rem;
        }
        &:before {
          top: -1.4rem;
          left: 13.8rem;
        }
      }
    }
  }
  .bubble {
    .triangle {
      &:after,
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        transform: rotate(45deg);
        border-width: 0.7rem;
      }

      &:after {
        top: -0.4rem;
        left: calc(100% - 0.75rem);
        border-color: transparent transparent transparent $white;
      }
      &:before {
        top: -0.7rem;
        left: calc(100% - 0.6rem);
        border-color: transparent transparent transparent #f3efef;
      }
    }
    background-color: $white;
    border: 0.1rem solid #f3efef;
    border-radius: 0.5rem 0 0.5rem 0.5rem;
    z-index: 10;
    position: absolute;
    top: 55px;
    left: -168px;
    width: 25rem;
    .content {
      z-index: 10;
      padding: 1rem;
      padding-top: 0.8rem;
      position: relative;
      .flex {
        align-items: flex-end;
        p {
          margin-bottom: 0.5rem;
        }
        textarea {
          z-index: 10;
          width: 100%;
          resize: none;
          height: 1.5rem;
          overflow: hidden;
          border-color: #f3efef;
          border-width: 0.1rem;
          transition: all 300ms;
          opacity: 1;
          &.expanded {
            transition: all 300ms;
            height: 5rem;
          }
          &.submitted {
            transition: all 300ms;
            opacity: 0.8;
          }
        }

        &:last-child span {
          width: 0;
          button {
            background-color: transparent;
            z-index: 10;
            position: relative;
            left: -0.75rem;
            bottom: 0.25rem;
            width: 0.75rem;
            border: none;
            padding: 0;
            .button svg {
              transform: rotate(0deg);
              width: 0.75rem;
            }
          }
          .button {
            z-index: 10;
            position: relative;
          }
        }

        .submit-button-enter {
          opacity: 0.01;
        }

        .submit-button-enter.submit-button-enter-active {
          opacity: 1;
          transition: opacity 200ms ease-in;
        }

        .submit-button-leave {
          opacity: 1;
        }

        .submit-button-leave.submit-button-leave-active {
          opacity: 0.01;
          transition: opacity 200ms ease-in;
        }
      }
    }
  }
}
