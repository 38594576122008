.sources-admin-section {
  background: #1b1d24;
  color: white;
  height: 100%;
  margin: 0;
}

.sources-list {
  .new-scraper-button {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }

  table {
    border-spacing: 0;
    font-size: $font-xs;
    font-weight: 400;
    width: 100%;

    th,
    td {
      padding: 12px 15px;
      border-top: 1px solid #484949;
      text-align: left;

      &:first-child {
        padding-left: 40px;
      }

      .text-link {
        border-bottom: none;
      }
    }

    thead {
      background: black;
    }

    .scraper-status {
      display: flex;
      align-items: center;
      text-transform: uppercase;

      .circle {
        border-radius: 50%;
        height: 8px;
        width: 8px;
        margin-left: 5px;
      }

      &.active {
        color: #0fac33;

        .circle {
          background: #0fac33;
        }
      }

      &.paused {
        color: #72757d;

        .circle {
          background: #72757d;
        }
      }

      &.error {
        color: $red11;

        .circle {
          background: $red11;
        }
      }
    }
  }
}
