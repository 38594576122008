.create-org {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    justify-content: center;
  }

  button[type="submit"] {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0;
  }

  .org-avatar {
    width: 160px;
    height: 160px;
    position: relative;

    .file-picker {
      display: none;
    }

    img,
    .upload-avatar {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    img {
      z-index: 2;
      object-fit: contain;
    }

    .upload-avatar {
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: $font-sm;
      line-height: $leading-snug;
      color: $gray7;
      padding: 0 20px;
      text-transform: none;
      cursor: pointer;
      border: 1px solid $gray8;

      svg {
        height: 55px;
        width: 55px;

        path {
          fill: $gray7;
        }
      }

      &:hover {
        opacity: 0.9;
        color: $purple11;
        border-color: $purple11;
        transition: all 0.2s;
        background-color: rgba(123, 104, 255, 0.07);

        svg path {
          opacity: 0.9;
          fill: $purple11;
          transition: all 0.2s;
        }
      }
    }
  }

  form {
    width: calc(100% - 220px);
    margin-left: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-top: 30px;
      margin-left: 0;
      flex-wrap: wrap;
    }

    .display-name,
    .account-name {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;

      &:first-child {
        margin-bottom: 20px;
      }

      label {
        width: 100%;
        text-transform: none;
        font-size: $font-sm;
        padding-bottom: 6px;
      }

      input {
        width: 100%;
        height: 42px;
      }

      .warning {
        font-weight: 200;
        font-style: italic;
        font-size: $font-sm;
        padding-top: 2px;
      }

      .url-preview {
        margin-top: 5px;
        font-weight: 400;
        font-size: $font-xs;
      }
    }

    .submit-button {
      margin-left: 25px;

      button {
        width: 20px;
        border: 0;
        background: 0;

        svg {
          height: 18px;
          width: 18px;
          fill: $blue11;
        }

        &:hover {
          svg {
            opacity: 0.8;
            transition: 0.2s all;
          }
        }
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-left: 0;
        display: flex;
        justify-content: center;
      }
    }
  }

  .error-text {
    font-size: $font-xs;
    padding-top: 10px;
    height: 13px;
  }
}
