.merge-request-notification {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  flex-wrap: wrap;

  &.border-bottom {
    border-bottom: 1px solid $gray8;
  }

  .text-container {
    flex: 1;
  }

  .text-container > h4 {
    margin: 0;
    margin-bottom: 0.25rem;
    font-size: 1.15rem;
  }

  .text-container > p {
    margin: 0;
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 5rem;

    > svg {
      height: 3rem;
      width: 3rem;
    }
  }

  @media screen and (max-width: 470px) {
    .icon-container {
      display: none;
    } 
    .text-container {
      width: 100%;
    }

    .button-container {
      width: 100%;
      justify-content: center !important;
      margin-top: 1rem;

      a, button {
        width: 100%;
        text-align: center;
      }
    }
  }

  .button-container {
    min-width: 8rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a, button {
      background: $blue10;
      border: 0;
      border-radius: 8px;
      padding: 1rem;
      color: $white;
      font-weight: 600;
    }

    a:hover, button:hover {
      background: $blue9;
      color: #000;
    }
  }

  &.standalone-notification {
    margin: 4rem 0 2rem 0;
    border: 1px solid $gray8;
    border-radius: 0.5rem;
    padding: 1rem;
  }

  &.merge-request-open-changes-in-h-core {

    .icon-container > .preview-icon {
      fill: $gray10;
    }
  }

  &.merge-request-has-conflicts, &.merge-request-no-changes {

    .close-icon {
      height: 2.5rem;
      width: 2.5rem;
    }

    .close-icon path {
      fill: $red11;
    }
  }

  &.merge-request-is-draft-mark-ready-for-review {

    .alert-circle-icon {
      height: 4rem;
      width: 4rem;
    }

    .alert-circle-icon path {
      fill: $gray10;
    }
  }

}

.merge-request-error-notification {
  .alert-circle-icon {
    width: 7rem;
    height: 7rem;
    margin: 0 1rem 0 0;
  }
  .alert-circle-icon path {
    fill: $red11;
  }
}