.careers-page {
  > div {
    &.top {
      padding-top: 5rem;
      @include media-breakpoint-down(sm) {
        padding-top: 1rem;
      }
    }
    &.bottom {
      padding-bottom: 5rem;
      @include media-breakpoint-down(sm) {
        padding-bottom: 3rem;
      }
    }
  }
  h3 {
    font-size: 1.8rem;
  }
  .white-container-wrapper {
    background-color: $white;
    border-top: 0.05rem solid #f3efef;
    box-shadow: 0 -1rem 1rem rgba(0, 0, 0, 0.01);
    margin-top: 3rem;
    padding-top: 2rem;
    padding-bottom: 4rem;
  }
  .working-at-HASH {
    margin-top: 2.5rem;
    @include media-breakpoint-up(lg) {
      display: flex;
      margin-left: calc((100% - 1140px) / 2);
      @include media-breakpoint-down(lg) {
        margin-left: calc((100% - 950px) / 2);
      }
    }
    @include media-breakpoint-down(md) {
      h3 {
        margin-top: 0;
      }
      h4 {
        margin-bottom: 0.5rem;
      }
      p {
        margin-top: 0;
      }
    }
    .left-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 15px;
      width: 240px;
      @include media-breakpoint-down(lg) {
        width: 210px;
      }
    }
    .right-content-wrapper {
      flex-grow: 2;
      border-top: 0.05rem solid #f3efef;
      border-left: 0.05rem solid #f3efef;
      box-shadow: -1rem -1rem 1rem rgba(0, 0, 0, 0.01);
      padding-top: 4rem;
      padding-left: 4rem;
      padding-bottom: 4rem;
      border-top-left-radius: 0.5rem;
      > .right-content {
        max-width: calc(1140px - 240px - 4rem - 30px);
        @include media-breakpoint-down(lg) {
          max-width: calc(950px - 210px - 4rem - 30px);
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > div {
          display: flex;
          &:not(:last-child) {
            margin-bottom: 4rem;
          }
          > div {
            h4 {
              margin-top: 0;
              margin-bottom: 0.25rem;
            }
            p {
              margin-top: 0;
              margin-bottom: 4rem;
            }
            > :last-child {
              margin-bottom: 0;
            }
            > :first-child {
              margin-top: 0;
            }
            &.left {
              width: 360px;
              padding-right: calc(1.5rem);
            }
            &.right {
              width: 450px;
              padding-left: calc(50px + 1.5rem);
              @include media-breakpoint-down(lg) {
                padding-left: 1.5rem;
              }
            }
          }
        }
      }
    }
  }

  .job-col-1 {
    padding-left: 50px;
    @include media-breakpoint-down(lg) {
      padding-left: 50px;
      padding-right: 15px;
    }
    @include media-breakpoint-down(md) {
      padding-left: 15px;
    }
    > :not(:first-child) {
      margin-top: 30px;
    }
  }

  .job-col-2 {
    padding-left: 50px;
    @include media-breakpoint-down(lg) {
      padding-left: 15px;
    }
    > :not(:first-child) {
      margin-top: 30px;
    }
    .text {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      h5 {
        margin-bottom: 0.5rem;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    h1.heading-1 {
      font-size: 42px;
    }
    .job-col-1 {
      > :not(:first-child) {
        margin-top: 15px;
      }
    }
    .job-col-2 {
      padding-top: 15px;
      padding-bottom: 15px;
      > :not(:first-child) {
        margin-top: 15px;
      }
    }
  }
}
