.index-profile-main-content {
  flex-grow: 1;
  height: 100%;
  padding: 50px 15px 10px 15px;

  @include media-breakpoint-down(sm) {
    padding: 30px 15px;
    flex-wrap: wrap;
  }

  .title-and-new {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    .MuiSkeleton-text {
      transform: inherit;
    }

    h2 {
      margin: 0;
      font-weight: 300;
    }

    button {
      font-size: $font-xs;
      font-weight: 600;
    }
  }

  .index-profile-empty-text {
    padding: 50px 0;
    margin: 0;
  }
}

.no-readme-editor-container {
  text-align: center;

  h3 {
    font-size: $font-lg !important;
    margin: 1.5rem 0 !important;
  }
}

.no-profile-results {
  font-weight: 300;
  width: 100%;
  border: 1px solid #e6e6e6;
  padding: 50px 30px 70px 30px;
  text-align: center;

  @include media-breakpoint-down(sm) {
    padding: 40px 15px;
  }

  .no-profile-results-header {
    font-size: $font-lg;
  }

  .no-profile-results-message {
    margin: 15px 0 30px 0;
  }
}
