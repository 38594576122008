.avatar-edit {
  margin-bottom: 20px;

  .avatar-header {
    font-size: $font-xs;
    font-weight: 600;
    color: $gray13;
    margin-bottom: 10px;

    span {
      font-weight: 400;
      text-transform: uppercase;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  &.org,
  &.listing {
    .avatar.square img,
    .avatar.square video {
      object-fit: contain;
    }
  }

  .avatar {
    position: relative;

    &.square {
      width: 160px;
      height: 160px;
    }

    &.wide {
      width: 305.6px;
      height: 160px;
    }

    svg,
    img,
    video,
    .upload-avatar {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 7px;
      border: 1px solid $gray8;
    }

    img,
    video {
      z-index: 2;
      object-fit: cover;
      background-color: white;
      border: 1px solid transparent;
    }

    .upload-avatar {
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: $font-sm;
      line-height: $leading-snug;
      color: $gray7;
      padding: 0 20px;
      text-transform: none;
      cursor: pointer;
      border: 1px solid $gray8;

      svg {
        height: 55px;
        width: 55px;
        position: relative;

        path {
          fill: $gray7;
        }
      }

      &:hover {
        opacity: 0.9;
        color: $purple11;
        border-color: $purple11;
        transition: all 0.2s;
        background-color: rgba(123, 104, 255, 0.07);

        svg path {
          opacity: 0.9;
          fill: $purple11;
          transition: all 0.2s;
        }
      }
    }
  }

  .file-picker {
    display: none;
  }

  label.replace-avatar {
    border: 0;
    background: 0;
    padding-right: 4px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    width: 125px;
    color: black;
    font-weight: 600;
    margin-top: 6px;
    font-size: $font-xs;
    text-transform: none;

    @include media-breakpoint-down(md) {
      justify-content: center;
    }

    svg {
      height: 16px;
      margin-top: 1px;
    }

    &:hover {
      opacity: 0.9;
      color: $blue11;
      transition: all 0.2s;

      svg path {
        opacity: 0.9;
        fill: $blue11;
        transition: all 0.2s;
      }
    }
  }
}
