.version-and-changelog {
  .intro-text {
    font-size: $font-base;
    margin-bottom: 30px;
  }

  .semver-and-note {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .input-group {
    margin-top: 20px;
    width: 45%;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .radio-select-label {
    span.option-explainer {
      font-weight: 200;
      margin-left: 6px;
    }
  }

  .submit {
    display: flex;
    justify-content: flex-end;

    button {
      font-weight: 600;
    }
  }
}
