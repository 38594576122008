table.invoice-table {
  width: 100%;
  font-size: $font-xs;
  font-weight: 400;
  text-align: left;
  margin-left: -4px;

  th {
    font-weight: 500;
    padding: 4px 6px;
  }

  th:not(:first-child) {
    border-left: 1px solid rgb(230, 230, 230);
    padding: 0 10px;
  }

  tr.divider td {
    border-top: 1px solid rgb(210, 210, 210);
    padding: 0;
  }

  tr.spacer {
    height: 2px;
  }

  td {
    padding: 10px;
    border-bottom: 1px solid rgb(240, 240, 240);
  }

  td:first-child {
    padding: 8px 6px;
  }

  td.date,
  td.period {
    color: rgb(81, 127, 187);
  }

  td.status span {
    border-radius: 8px;
    padding: 4px 8px;
    background-color: $orange7;
    text-transform: uppercase;
    color: white;
    font-weight: 500;
    font-size: $font-xs;

    &.paid {
      background-color: $green;
    }

    &.open {
      background-color: $red11;
    }
  }

  td.total.open {
    display: flex;
    align-items: center;
    padding: 4px 10px;

    button {
      height: 24px;
      font-size: $font-xs;
      font-weight: 600;
      padding: 0 15px;
      margin-left: 8px;
    }
  }

  @include media-breakpoint-down(xs) {
    .period {
      display: none;
    }
  }
}
