.discord-widget {
  position: fixed;
  bottom: 0;
  right: 125px;
  height: 46px;
  width: 46px;
  z-index: 99999;
  margin-bottom: 12px;
  margin-right: 12px;
  padding: 8px;
  background: #5865f28c;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;

  &.mobile {
    display: none;
  }

  svg {
    height: 100%;
    width: 100%;
  }

  &:hover {
    background: #5865f2;
    transition: background-color 0.3s;
  }
}
