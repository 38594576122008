.footer {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  width: 100%;
  // z-index: 2;
  background-color: $gray13;
  padding: $space8 0;
  color: $gray5;
  border-top: 1px solid $gray12;


  .links-and-legal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: $space7 0;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    font-size: $font-xs;
    font-weight: 300;

    .main-group {
      display: grid; 
      grid-template-columns: 1fr 1fr 1fr; 
      grid-template-rows: 1fr 4fr; 
      grid-template-areas: 
        "platform simulations company"
        "bp simulations company"; 
      @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 3fr;
        grid-template-areas: 
          "platform"
          "bp"
          "simulations"
          "company"
      }
    }

    .platform { grid-area: platform; }
    .bp { grid-area: bp; }
    .simulations { grid-area: simulations; }
    .company { grid-area: company; }


    .section-header {
      display: flex;
      flex-direction: column;
      grid-gap: $space2;
    }

    .header-text {
      font-size: $font-sm;
      font-weight: 500;
      color: $gray7;
      letter-spacing: 0.03rem;
      transition: all 0.3s;
      &:hover {
        color: $blue9;
        cursor: pointer;
      }
    }

    .header-description {
      font-size: $font-xs;
      font-weight: 300;
      color: $gray9;
    }

  

    .link-group {
      margin-bottom: $space6;

      @include media-breakpoint-down(xs) {
        width: 45%;
      }

      &:not(:last-child) {
        margin-right: 100px;

        @include media-breakpoint-down(sm) {
          margin-right: 60px;
        }

        @include media-breakpoint-down(xs) {
          margin-right: $space3;
        }
      }

      &:last-child {
        @include media-breakpoint-down(xs) {
          margin-right: $space3;
        }
      }

      .footer-link {
        margin-top: $space4;
        transition: all 0.3s;
        cursor: pointer;
        color: $gray9;

        a {
          display: flex;
          align-items: center;
          letter-spacing: 0.02rem;
        }

        &:hover {
          color: $blue10;
        }
      }
    }
  }

  .socials-and-legal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    font-size: $font-xs;
    color: $gray11;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    .socials {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: $space4;

      a {
        margin-left: $space4;

        svg path {
          fill: $gray11;
          transition: all 0.3s;
        }

        &:hover svg path {
          fill: white;
        }
      }
    }

    .legal-links {
      text-align: right;
      margin-bottom: $space4;

      a {
        font-weight: 500;
        margin-left: $space4;
        transition: all 0.3s;
        letter-spacing: 0.05rem;
        &:hover {
          color: white;
        }
      }
    }

    .status-copyright {
      display: flex;
      justify-content: flex-end;

      .status {
        display: flex;
        align-items: center;
        color: white;
        text-transform: uppercase;
        font-weight: 600;

        .online {
          background-color: #57b72e;
          border-radius: 50%;
          height: 10px;
          width: 10px;
          margin-right: $space2;
        }

        a {
          transition: color 0.2s;
        }

        a:hover {
          color: #57b72e;
        }
      }

      .copyright {
        margin-left: $space4;
        color: $gray11;
        opacity: 0.5;
      }
    }
  }
}
