.input {
  border-radius: 6px;
  height: 48px;
  padding: 15px 20px;
  font-size: $font-sm;
  line-height: $leading-snug;
  color: $gray13;
  border: 1px solid $gray6;
  background: $gray2;
  transition: color 0.2s, background-color 0.2s, box-shadow 0.2s, border 0.2s;

  &:disabled {
    cursor: not-allowed;
  }

  &::placeholder {
    color: rgba(27, 29, 36, 0.5);
    font-weight: 300;
    opacity: 1;
  }

  &:not([disabled]):hover {
    background-color: #fff;
  }

  &:not([disabled]):focus {
    box-shadow: 0px 5px 20px rgba(95, 71, 255, 0.05);
    background-color: #fff;
  }

  &.dark {
    border: 1px solid rgba(219, 224, 227, 0.3);
    color: white;
    background: rgba(241, 243, 246, 0.1);

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }

    &:not([disabled]):hover {
      background-color: rgba(201, 213, 230, 0.144);
    }

    &:not([disabled]):focus {
      background-color: rgba(201, 213, 230, 0.144);
      box-shadow: none;
    }
  }

  &.error {
    border: 1px solid $red11;
    background: url("/assets/alert.svg") no-repeat scroll 97%;
    padding-right: 32px;
  }
}

select.input {
  padding-top: 0;
  padding-bottom: 0;
}

.error-text {
  color: $red11;
  font-size: $font-xs;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  font-weight: 600;
}

.label-with-tooltip,
.input-group .label-with-tooltip {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  height: 18px;

  svg {
    width: 12px;
    height: 12px;
    margin-left: 6px;

    path {
      fill: #a8a8a8;
    }

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  label {
    margin-bottom: 0;
  }
}

.input-group {
  .react-select__control {
    min-height: 48px;
    height: auto;
  }

  .react-select__menu,
  .react-select__menu-list {
    max-height: 200px;
  }

  input,
  textarea {
    width: 100%;

    &.error {
      width: calc(100% - 100px);
    }
  }

  .input-with-message {
    display: flex;
    align-items: center;
  }

  input#title {
    width: calc(100% - 120px);
  }

  textarea {
    height: 140px;
    resize: none;
  }

  label {
    display: block;
    margin-bottom: 8px;
    font-size: $font-xs;
    font-weight: 600;
    text-transform: uppercase;
  }

  span.optional-indicator {
    color: rgba(0, 0, 0, 0.4);
    font-weight: 300;
    margin-left: 7px;
  }

  .input-error {
    font-size: $font-sm;
    margin-left: 15px;
  }
}
