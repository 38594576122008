.project-header {
  background-color: $gray2;
  width: 100%;
  max-width: 100vw;

  .forked-from {
    width: 100%;
    font-size: $font-xs;
    margin-top: 30px;
  }

  .forked-from,
  .project-path {
    opacity: 0.66;
    font-weight: 700;

    :not(a) {
      font-weight: 400;
    }

    a {
      transition: color 0.2s;
      &:hover {
        color: $blue11;
      }
    }
  }

  .project-header-content {
    padding: 70px 15px;

    @include media-breakpoint-down(sm) {
      padding: 30px 15px;

      .project-actions {
        width: 100%;
      }
    }

    .info-and-action-bar-loading {
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      height: 26px;
    }

    @include media-breakpoint-down(sm) {
      .info-and-action-bar-loading {
        grid-template-columns: 1fr 2fr;
      }
    }

    .info-and-action-bar {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 20px;

      .project-info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: $font-xs;
        font-weight: 600;
        text-transform: uppercase;
      }

      .project-path {
        margin-right: 20px;
        margin-bottom: 15px;
      }

      .project-meta {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 15px;
        margin-right: 25px;

        @include media-breakpoint-down(xs) {
          > span,
          > div {
            margin-top: 5px;
          }
        }
      }

      .project-type {
        margin-right: 20px;
      }

      .badge {
        display: flex;
        align-items: center;
        background-color: white;
        border: 1px solid $gray6;
        border-radius: 5px;
        color: $blue11;
        padding: 4px 16px 4px 12px;
        margin-right: 20px;

        svg {
          height: 17px;
          width: 17px;

          path {
            fill: $blue11;
          }
        }

        &.dependent-count {
          padding: 1px 16px 3px 12px;
          svg {
            height: 19px;
            width: 19px;
            margin-top: 2px;
          }
        }

        &.star-badge {
          padding: 2px 16px 4px 12px;
          svg {
            height: 19px;
            width: 19px;
          }
        }
      }

      .project-actions {
        display: flex;
        flex-wrap: wrap;
        width: auto;

        .open-project {
          position: relative;
          width: 140px;
          height: 40px;
        }

        button {
          margin: 5px 0;
        }

        > :nth-child(2) {
          margin-left: 30px;
        }
      }
    }

    .project-avatar-and-title {
      display: flex;
      margin-top: 30px;

      .project-avatar {
        margin-right: 50px;
      }

      .title-and-description-loading {
        width: 100%;

        .project-title {
          width: 30%;
          margin-bottom: 20px;

          .heading-3 {
            margin: 0;
          }
        }

        .project-description {
          font-weight: 400;
          width: 60%;
        }

        @include media-breakpoint-down(sm) {
          .project-title {
            width: 60%;
          }

          .project-description {
            width: 100%;
          }
        }
      }

      .title-and-description {
        .project-title {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          .heading-3 {
            margin: 0;
          }
        }

        .project-description {
          font-weight: 400;
          width: 100%;
        }
      }

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;

        .title-and-description {
          width: 100%;
        }

        .project-avatar {
          margin-bottom: 20px;
          margin-right: 0;
        }
      }
    }

    .edit-project .blue-button {
      padding: 8px 15px;
      height: 40px;

      &:hover {
        opacity: 0.8;
        background-color: $blue11;
        color: white;

        svg path {
          fill: white;
        }
      }

      svg {
        height: 22px;
        width: 22px;
        margin-left: 5px;
      }
    }
  }
}
