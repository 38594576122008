/**
 * Everything in this file must be supported on older browsers 
 */
.unsupported-page-wrapper {
  background-color: #1b1d24;
  text-align: center;
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  position: absolute;
  width: 100%;

  .unsupported-second-wrapper,
  .unsupported-content-vertical-center {
    display: inline-block;
    vertical-align: middle;
  }

  .unsupported-content-vertical-center {
    width: 0;
    height: 100%;
  }
}

.unsupported-second-wrapper {
  overflow: hidden;
  width: 100%;
}

.unsupported-content {
  width: 860px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  color: white;
  line-height: 1.3em;
  padding: 30px;
  text-align: left;
  box-sizing: border-box;

  h2,
  h3,
  p {
    margin: 0;
  }

  h2,
  h3 {
    font-size: $font-lg;
  }

  h2 {
    font-weight: bold;
    margin-bottom: 10px;
  }

  h3 {
    opacity: 0.5;
    font-weight: normal;
  }

  p {
    margin: 26px 0 25px;
    font-size: $font-base;
  }

  .bg-cover {
    position: absolute;
    z-index: -1;
    top: -8%;
    left: 80%;
    height: 116%;
    width: auto;
    pointer-events: none;
    opacity: 0.2;
  }

  .browser-icons {
    font-size: 0;
    min-height: 86px;

    > * {
      vertical-align: middle;
    }

    &.browser-icons--hidden {
      opacity: 0;
    }
  }

  .browser-link {
    display: inline-block;
    text-align: center;
    margin-left: 22px;

    &:first-child {
      margin-left: 0;
    }
  }

  .browser-image {
    width: 56px;
    height: 56px;
    margin-bottom: -5px;
  }

  .browser-label {
    font-size: $font-xs;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
  }

  .or-label {
    font-size: $font-base;
    color: #5b5f61;
    margin: 0 24px;
    display: inline-block;
  }

  .native-button {
    padding: 15px 20px;
    padding-left: 26px;
    background-color: white;
    border-radius: 10px;
    display: inline-block;

    .label {
      font-size: $font-base;
      color: black;
      margin-right: 8px;
      font-weight: bold;
      vertical-align: middle;
    }

    img {
      vertical-align: middle;
      position: relative;
      width: 24px;

      .native-button.mac & {
        top: -1px;
      }
    }
  }
}
