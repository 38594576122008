.contributor-agreement-page {
  iframe {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 15px;
    height: 440px;
    width: 100%;

    @include media-breakpoint-down(xs) {
      height: 500px;
    }
  }
}
