.create-dataset-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 20;
  padding: 50px 30px 0 30px;

  @media (max-width: 400px) {
    padding: 50px 15px 0 15px;
  }
}

.create-dataset-window {
  margin: 0 auto;
  border-radius: 16px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  background-color: white;
  max-width: 700px;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

  .uploading-dataset {
    width: 400px;
    max-width: 100%;
    padding: 15px;

    .loading-indicator {
      left: calc(50% - 16px);
      position: absolute;
    }

    .progress-indicator {
      margin-top: 10px;
    }
  }

  .create-dataset-dropzone {
    padding: 120px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      padding: 40px;
    }

    span {
      color: #d2d6d9;
      margin: 0 25px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        text-align: center;
        margin: 15px 0;
      }
    }

    .dataset-dashed-box {
      padding: 25px 15px 40px 15px;
      border: 2px dashed $gray7;
      border-radius: 10px;
      color: #d2d6d9;
      text-align: center;
      width: 250px;

      svg {
        height: 80px;
        width: 80px;

        path {
          fill: $gray7;
        }
      }
    }
  }

  .loading-indicator {
    margin-top: 30px;
  }

  button {
    font-weight: 500;
    font-size: $font-xs;
    text-transform: uppercase;
    border-radius: 3px;

    svg {
      height: 20px;
      width: 24px;
      margin-left: 10px;
      margin-top: 1px;

      path {
        fill: white;
      }
    }
  }

  #close-svg {
    position: absolute;
    top: 1rem;
    right: 2rem;
    height: 1rem;
    width: 1rem;

    &:before {
      background-color: transparent;
      position: absolute;
      transform: scale(0);
      transform-origin: center;
      top: -50%;
      left: -50%;
      content: "";
      width: 200%;
      height: 200%;
      border-radius: 100%;
      transition: background-color, transform 0.2s;
    }

    svg {
      height: 1rem;
      width: 1rem;
      fill: grey;
      position: relative;
    }

    &:hover {
      cursor: pointer;
      &:before {
        background-color: $gray3;
        transform: scale(1);
      }
    }
  }
}
