.language-split-bars {
  font-size: $font-xs;
  text-transform: uppercase;

  .language-bar {
    display: flex;
    align-items: center;

    .bar {
      height: 6px;
      background-color: #a4a5a8;
      margin-right: 5px;
    }
  }
}
