.award-emojis {
  display: flex;
  margin-top: 10px;

  button {
    &:first-child {
      margin-right: 12px;
    }

    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 5px;
    color: #777;
    font-size: $font-sm;

    svg {
      height: 16px;
      width: 16px;
      margin-right: 8px;

      path {
        transition: fill 0.1s;
        fill: #aaa;
      }
    }

    &:not(:disabled):hover {
      cursor: pointer;

      svg path {
        transition: fill 0.1s;
        fill: $blue11;
      }
    }
  }

  &:not(.loading) .active {
    color: $blue11;

    svg path {
      fill: $blue11;
    }
  }

  &.loading {
    button {
      color: $gray7;
    }

    svg path {
      fill: $gray7;
    }
  }
}