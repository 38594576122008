.early-access-overlay {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: black;

  &.lightbox {
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s;
    z-index: 99999999;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100vh;
    width: 100vw;
  }

  .already-signed-up {
    font-size: $font-md;
    width: 100%;
    margin-top: 60px;
  }

  &:not(.lightbox) {
    padding: 80px 30px;

    @include media-breakpoint-down(xs) {
      padding: 60px 15px;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 60px 30px;
  }

  @include media-breakpoint-down(xs) {
    padding: 60px 15px 30px 15px;
  }

  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .early-access-window {
    width: 75%;
    margin: 0 auto;

    .mainsite-header {
      padding: 40px 0;
      background-color: black;
      color: white;

      h1,
      h2 {
        margin-top: 0;
      }
    }

    &.done {
      .mainsite-header {
        color: $green11;
        padding-bottom: 20px;

        h2 {
          display: flex;
          font-size: 60px;
          align-items: center;
          margin-left: -20px;
        }

        svg path {
          fill: $green10;
        }
      }
    }

    &.register {
      .mainsite-header h1 {
        margin-bottom: 0;
      }
    }

    @include media-breakpoint-down(md) {
      width: 90%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      max-width: 100%;
    }

    @include media-breakpoint-down(xs) {
      .mainsite-header {
        padding-bottom: 20px;

        .heading-1,
        .heading-2 {
          font-size: 44px;
        }
      }

      .input,
      button {
        width: 100%;
      }
    }

    .signup-status {
      font-size: $font-sm;
      height: 14px;
      margin-bottom: 8px;
      color: white;
    }

    .early-access-form {
      display: flex;
      flex-wrap: wrap;
    }

    .early-access-form,
    .create-account-form {
      div.normal-text {
        width: 100%;
        margin-bottom: 10px;

        .optional {
          color: rgba(255, 255, 255, 0.4);
          margin-left: 10px;
          font-weight: 300;
        }
      }

      .shortname-section {
        div.normal-text {
          margin-bottom: 4px;
        }
      }

      .warning {
        margin-bottom: 15px;
        font-size: $font-sm;
        font-weight: 300;
      }

      .input,
      div.dropdown-wrapper {
        width: 50%;
        min-width: 250px;
        max-width: 400px;
        margin-right: 20px;
        font-size: $font-base;
      }

      .input.second-input {
        margin-right: 0;
      }

      button {
        padding-left: 30px;
        padding-right: 30px;
      }

      .input,
      button,
      div.react-select__control {
        min-height: 60px;
      }

      .name-section,
      .shortname-section {
        margin-bottom: 50px;
      }

      .error-msg {
        width: 100%;
        font-size: $font-sm;
        min-height: 18px;
        line-height: $leading-snug;
        margin-top: 12px;
        padding-left: 30px;
        color: white;

        &.active {
          background: url("/assets/alert.svg") no-repeat scroll -3px -5px;
        }
      }

      .name-section,
      .button-and-consent {
        width: 100%;
        max-width: 500px;
      }

      .name-inputs,
      .button-and-consent {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .name-inputs .input {
        width: calc(50% - 10px);
        min-width: auto;
      }

      .name-inputs {
        max-width: 100%;
      }

      .white-button {
        margin-right: 30px;
      }

      .consent-disclaimer {
        opacity: 0.66;
        font-weight: 300;
        font-size: $font-sm;
        line-height: $leading-base;

        a {
          font-weight: 600;
        }
      }

      .shortname-section .input {
        width: 100%;
        max-width: 280px;
      }

      @include media-breakpoint-down(xs) {
        .name-inputs {
          width: 100%;
          max-width: 100%;
          flex-wrap: wrap;
        }

        input:not(.second-input),
        .name-inputs .input,
        .shortname-section .input {
          width: 100%;
          max-width: 100%;
          margin-right: 0;
        }

        input.second-input {
          margin-top: 20px;
          margin-right: 0;
        }

        button,
        .white-button {
          margin-top: 20px;
          width: 100%;
          margin-bottom: 0;
          margin-right: 0;

          &:hover {
            color: black;
            background-color: white;
            border: 1px solid transparent;
          }
        }

        .button-and-consent {
          flex-wrap: wrap;
        }

        .consent-disclaimer {
          width: 100%;
          margin-top: 30px;
        }

        .shortname-section {
          margin-bottom: 10px;
        }
      }
    }
  }

  .twitter-and-discord-button {
    margin-top: 20px;

    a {
      display: inline-block;
    }

    button.twitter {
      margin-right: 30px;
      width: auto;
    }

    button.discord {
      margin-top: 30px;
      width: auto;

      svg {
        height: 20px;
        width: 20px;
        margin-left: 12px;
        margin-right: 0;

        path {
          fill: #5865f2;
        }
      }
    }

    button:hover svg path {
      fill: white;
      transition: fill 0.3s;
    }
  }

  .copyright-and-terms-notice {
    font-weight: 200;
    width: 75%;
    opacity: 0.66;
    margin: 60px auto 0 auto;

    a {
      font-weight: 600;
      transition: color 0.2s;

      &:hover {
        color: white;
      }
    }

    span {
      margin-right: 40px;
    }

    @include media-breakpoint-down(md) {
      width: 90%;
    }

    @include media-breakpoint-down(sm) {
      font-size: $font-sm;
      width: 100%;

      span {
        display: block;
        margin-left: 0;
      }
    }
  }

  #close-svg {
    position: fixed;
    top: 2rem;
    right: 2rem;
    height: 1rem;
    width: 1rem;
    fill: white;

    svg {
      height: 1rem;
      width: 1rem;
    }

    &:hover {
      cursor: pointer;

      svg {
        fill: $gray11;
      }
    }
  }
}
