.privacy-container {
  display: flex;

  .section {
    margin-bottom: 50px;
  }

  .bottom {
    margin: 4rem 0;

    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
      margin-bottom: 2.5rem;
    }
    button svg {
      margin-right: 15px;
      transform: rotate(180deg);

      path {
        fill: white;
      }
    }
    .page-review {
      position: relative;
      .thumbs-up,
      .thumbs-down {
        opacity: 1;
        transition: all 0.3s;
        margin-left: 1rem;
        padding: 1rem;
        &.clicked {
          opacity: 0.5;
          transition: all 0.3s;
        }
      }
      .thumbs-up {
        z-index: 20;
        > svg {
          min-height: 18px;
          min-width: 18px;
          position: relative;
          top: -2px;
        }
      }
      .thumbs-down {
        z-index: 20;
        > svg {
          min-height: 18px;
          min-width: 18px;
          position: relative;
          top: -1px;
        }
      }
    }
  }
}
