.index-profile-sidebar {
  width: 100%;
  border-top: 1px solid $gray6;
  background-color: #fdfdfd;
  padding-top: 30px;

  .container {
    display: flex;
    flex-wrap: wrap;

    > *:first-child {
      margin-right: 80px;
    }
  }

  .sidebar-field {
    margin-bottom: 30px;
    font-weight: 300;
    max-width: 45%;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }

    .field-label {
      padding-bottom: 15px;
    }

    &.part-of,
    &.sub-org {
      ul {
        padding-inline-start: 0;
        margin-block-start: 0;
        list-style: none;
        font-size: $font-sm;
      }

      .sub-org-item {
        margin-bottom: 6px;
        max-width: 90vw;
      }

      a,
      .sub-org-item li a {
        text-decoration: none;
        border-bottom: none;
        transition: color 0.2s;
        font-weight: 600;
        color: black;

        &:hover {
          text-decoration: none;
          color: $blue11;
        }
      }
    }
  }

  .org-members,
  .user-org-links {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    a {
      height: 37px;
      width: 37px;
      margin-right: 15px;
      margin-bottom: 15px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
      font-weight: 500;
      font-size: $font-xs;
      cursor: pointer;

      &.no-avatar {
        background-color: $gray7;
      }

      &:hover {
        opacity: 0.8;
      }

      img,
      svg {
        height: 100%;
        width: 100%;
        border-radius: 7px;
        object-fit: cover;
        border: 1px solid #ededed;
      }
    }
  }
}
