.orgs-panel {
  position: relative;

  .loading-indicator:first-child {
    position: absolute;
    left: 1rem;
  }

  .container {
    position: relative;
  }

  .orgs-table {
    border-radius: 5px;
    padding: 1rem;
    margin: auto;
    width: 100%;
    background: $white;
    border-spacing: 0;

    .admin-orgs-row {
      .admin-shortname-link {
        cursor: pointer;
      }

      img {
        cursor: pointer;
      }

      .admin-column-name {
        text-align: center;
      }
    }

    th,
    td {
      padding: 0.5rem 1rem;
    }

    th {
      background-color: $gray14;
      color: $white;
      text-align: left;
      text-transform: uppercase;
      font-weight: 400;
      cursor: pointer;

      :hover {
        div svg {
          opacity: 1;
        }
      }
    }

    .orgs-div-arrow {
      display: flex;

      svg {
        margin-left: 0.5rem;
        padding: 0.2rem;
        width: 1rem;
        height: 1rem;
        fill: #fff;
        opacity: 0.5;
        transition: opacity 0.3s;
      }

      .active-col svg {
        fill: #5f47ff;
        opacity: 1;
      }

      .desc svg {
        transform: scaleY(-1);
      }
    }

    .user-orgs {
      th {
        background: $gray2;
        color: black;
      }
    }
  }

  // ABOVE MAIN TABLE in Orgs -->>
  .admin-org-menu-bar {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    vertical-align: auto;

    h1 {
      font-size: 1.5rem;
      margin: 0;

      span {
        font-size: 1.5rem;
        opacity: 0.5;
        margin-left: 1rem;
      }
    }

    input {
      padding: 0.75rem;
      background: $white;
      border-radius: 5px;
      margin-right: 1rem;
    }

    button {
      width: 120px;
    }

    .org-menu-bar-level1 {
      display: flex;
    }
  }
  // <<-- END ABOVE MAIN TABLE
  // ADD ORG in Orgs -->>
  .admin-create-org {
    position: absolute;
    top: 4rem;
    right: 15px;
    padding: 1rem;
    transform-origin: right top;
    box-shadow: 3px 3px 3px rgb(50, 50, 50);
    background: $gray2;
    border-radius: 10px;
    z-index: 10;
    max-width: 100%;
    width: 500px;

    .admin-orgs-form-loading {
      display: flex;
      margin-top: 2rem;
      justify-content: space-between;

      button {
        text-transform: uppercase;
        font-weight: 600;
      }

      .loading-indicator {
        position: absolute;
        right: 1rem;
        left: none;
        bottom: 1.2rem;
      }
    }

    .avatar-name {
      display: flex;
      justify-content: space-between;

      input {
        margin: 3rem 0;
        padding: 1rem;
        background-color: white;
        border-radius: 5px;
        width: 280px;
      }

      .avatar-upload {
        margin-right: 1rem;
        padding: 2rem;
        text-align: center;
        width: 160px;
        height: 160px;
        position: relative;

        .upload-avatar-button {
          color: $gray11;
        }

        img {
          width: 160px;
          height: 160px;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
  // <<-- END ADD ORG
}
