.maintenance-page .core-ui,
.maintenance-page .core-ui img {
  border-radius: 7px;
}

@include media-breakpoint-down(sm) {
  .core-ui,
  .core-ui img {
    border-radius: 7px;
  }
}

.simulation-signup {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .signup-form {
    width: 40%;

    h1 {
      font-size: 38px;
      margin-top: 0;
      margin-bottom: 20px;
      font-weight: 700;
    }

    .normal-text {
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 15px;
    }

    .input {
      background-color: white;
      border: 1px solid #ececec;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .small-text {
      font-weight: 300;
      color: #868686;

      a {
        color: #555;
        transition: color 0.2s;

        &:hover {
          color: $blue11;
        }
      }
    }
  }

  .core-ui {
    width: 55%;
    position: relative;
    top: 4px;
    height: 360px;
    background: #1d243a;
    border-radius: 7px;

    img {
      width: 100%;
    }
  }

  @include media-breakpoint-only(lg) {
    margin-bottom: 50px;
    align-items: center;

    .signup-form,
    .core-ui {
      width: 47%;
    }

    .core-ui,
    .core-ui img {
      border-radius: 7px;
    }

    .core-ui {
      height: 248px;
    }
  }

  @include media-breakpoint-down(md) {
    .signup-form,
    .core-ui {
      width: 100%;
    }

    .core-ui {
      margin-top: 50px;
      height: auto;
    }
  }

  @include media-breakpoint-down(sm) {
    form.early-access {
      margin-bottom: 20px;
    }
  }
}
