.progress-indicator {
  width: 100%;
  height: 1.5rem;
  background-color: #f9f9f9;
  border: 1px solid $gray8;
  position: relative;
}

.progress-indicator span {
  position: absolute;
  color: black;
  font-size: $font-xs;
  line-height: $leading-base;
  top: 0;
  left: 1%;
}

.progress-indicator-bar {
  display: flex;
  color: white;
  font-size: $font-xs;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: rgb(42, 185, 103);
  height: 100%;
  width: 0;
}

.progress-indicator-bar::after {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: white;
  width: 100%;
  animation: progress 3s ease 2s infinite;
}

@keyframes progress {
  0% {
    opacity: 0.1;
    width: 0;
  }
  60% {
    opacity: 0;
    width: 100%;
  }
}
