.index-home-page-wrapper {
  height: 100%;
  font-family: Inter, Helvetica, sans-serif;

  @include media-breakpoint-up(md) {
    &:not(.search-home) {
      padding-top: 5rem;
    }
  }

  @include media-breakpoint-down(sm) {
    &:not(.search-home) {
      padding-top: 45px;
    }
  }

  .homepage-content {
    padding: 65px 15px;

    @include media-breakpoint-down(md) {
      padding: 70px 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 70px 15px;
    }

    @include media-breakpoint-down(xs) {
      padding: 50px 15px;
    }
  }

  .homepage-mobile-copy {
    margin: 60px auto;

    h2 {
      font-size: $font-lg;
      font-weight: 600;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }

    @include media-breakpoint-up(sm) {
      h2,
      p {
        width: 80%;
      }
    }
  }

  .get-started-button-wrapper {
    margin-left: 45px;

    @include media-breakpoint-down(md) {
      margin-left: 0;
      margin-top: 15px;
      margin-right: 0;
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }

  .get-started-button {
    top: 1px;
    left: 1px;
    border: 1px solid #d9d9d9;
    font-size: $font-xs;
    text-transform: uppercase;
    box-shadow: none;
    border-radius: 100px;
    padding: 10px 25px;
    background: none;
    font-weight: 600;

    span:first-child {
      color: black;
    }

    span:nth-child(2) {
      color: $blue11;
    }

    &:hover {
      box-shadow: 0px 0 12px 0 rgba($blue11, 0.3);
    }
  }

  .index-homepage-header {
    p {
      margin-top: 0;
      margin-bottom: $space3;
      font-size: $font-base;
      color: $gray11;
      font-weight: 400;
      span.h-index {
        font-weight: 700;
        color: $gray13;
        span {
          color: $blue11;
        }
      }
    }

    h1 {
      margin-top: 0;
      font-size: 38px;
      margin-bottom: 8px;
      font-weight: 600;

      .light {
        font-weight: 200;
      }

      @include media-breakpoint-down(md) {
        font-size: 37px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 28px;
      }
    }

    h2 {
      margin-top: 0;
      font-size: $font-lg;
      line-height: $leading-base;
      font-weight: 300;
    }

    .read-more-link {
      display: inline-block;
      color: $blue11;
      font-weight: 600;

      span {
        position: relative;
        transition: left 0.2s;
        left: 0;
      }

      &:hover span {
        left: 6px;
      }
    }
  }

  .homepage-searchbar {
    margin-top: 20px;

    .searchbar-input {
      display: flex;
    }
  }

  .popular-searches {
    font-weight: 300;

    svg {
      margin: 0 15px 0 5px;
      path {
        fill: $blue11;
      }
    }

    span.popular-searches-header {
      text-transform: uppercase;
      font-size: $font-xs;
    }

    margin-top: 45px;
    line-height: $leading-base;

    a {
      white-space: nowrap;
      margin-right: 10px;
      font-weight: 600;
      cursor: pointer;
      transition: color 0.2s;

      &:hover {
        color: $blue11;
      }
    }

    @include media-breakpoint-down(xs) {
      text-align: center;

      .popular-searches-header {
        display: block;
      }

      a {
        line-height: $leading-loose;
      }
    }
  }

  .homepage-explore {
    padding-top: $space8;
    padding-bottom: $space8;
    text-align: left;

    .browse-header {
      font-size: $font-md;
    }

    .row {
      .get-started-button-wrapper {
        margin-left: 0;
        .get-started-button {
          margin-top: 15px;
          border-radius: 10px;
          transition: color 0.2s, outline 0.2s, box-shadow 0.2s;
          border-radius: 10px;
          position: relative;
          background-color: white;
          border: 1px solid #f1f1f1;
          outline-width: 0px;
          outline-color: $blue11;
          box-shadow: 0px 0px 0px #2483ff25;
          outline-style: solid;
          &:hover {
            color: $blue11;
            box-shadow: 0px 0px 20px #2483ff25;
          }
        }
      }
      > :first-child {
        padding-right: 30px;

        @include media-breakpoint-down(md) {
          padding-left: 15px;
          padding-bottom: 3rem;
        }

        @include media-breakpoint-up(sm) {
          width: 80%;
        }
        p {
          margin-bottom: 0;
          line-height: $leading-loose;
        }
        .get-started-button-wrapper {
          display: block;
        }
      }
      > :last-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        padding-left: 30px;
        @include media-breakpoint-down(md) {
          padding-left: 15px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      padding-top: 40px;
      padding-bottom: 45px;
    }

    h4 {
      margin: 0 0 $space5;
      font-size: $font-lg;
      font-weight: 600;
    }
  }

  .index-home-banners {
    position: relative;
    background-color: white;
  }

  .explainer-wrapper {
    background: linear-gradient(90deg, $blue11 50%, #dda11b 50%);

    @include media-breakpoint-down(md) {
      background: linear-gradient(
        $blue11 33.33%,
        #0fac33 33.33%,
        #0fac33 66.66%,
        #dda11b 66.66%
      );
    }
  }

  .explainer-text {
    display: flex;
    justify-content: space-between;
    color: white;

    .explainer-para {
      font-weight: 300;
      line-height: 130%;
    }

    .explainer-para:first-child {
      padding: 120px 75px 120px 0;
    }

    .explainer-para:nth-child(2) {
      padding: 120px 75px;
      background-color: #0fac33;
    }

    .explainer-para:last-child {
      padding: 120px 0 120px 75px;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;

      .explainer-para {
        width: 100%;
        padding: 50px 15px !important;
      }

      .explainer-para:nth-child(2) {
        background-color: transparent;
      }
    }
  }
}

.homepage-type-cards {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @include media-breakpoint-down(sm) {
    justify-content: space-between;
  }
}

.homepage-type-card {
  transition: color 0.2s, outline 0.2s, box-shadow 0.2s;
  border-radius: 10px;
  position: relative;
  background-color: white;
  border: 1px solid #f1f1f1;
  outline-width: 0px;
  outline-color: $blue11;
  box-shadow: 0px 0px 0px #2483ff25;
  outline-style: solid;
  width: 130px;
  height: 130px;
  margin-top: 15px;
  cursor: pointer;
  > a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  svg {
    transition: fill 0.2s;

    path,
    polygon {
      transition: fill 0.2s;
    }
  }

  &:not(:last-child) {
    margin-right: 30px;
  }

  @include media-breakpoint-down(lg) {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-breakpoint-down(sm) {
    height: 110px;
    font-size: $font-sm;
    width: calc(25% - 15px);

    &:not(:last-child) {
      margin-right: 0;
    }
  }

  @media (max-width: 500px) {
    width: calc(50% - 7.5px);
    font-size: $font-md;

    &:not(:nth-child(2n)) {
      margin-right: 15px;
    }
  }

  &:hover {
    color: $blue11;
    box-shadow: 0px 0px 20px #2483ff25;
    transition: color 0.2s;

    svg {
      fill: $blue11;
      transition: fill 0.2s;

      path,
      polygon {
        fill: $blue11;
        transition: fill 0.2s;
      }
    }
  }

  .type-card-label {
    width: 100%;
    text-align: center;
    font-weight: 300;
  }

  .type-card-icon {
    text-align: center;
    height: 40px;
    display: flex;
    align-items: flex-end;
    @media (max-width: 500px) {
      margin-right: 8px;
      align-items: center;
    }
  }

  &.Simulation {
    svg {
      width: 37px;
      height: 37px;
      margin-bottom: 4px;
    }
  }

  &.Dataset {
    svg {
      width: 35px;
      height: 35px;
      margin-bottom: 4px;
    }
  }

  &.Behavior {
    svg {
      margin-bottom: 8px;
    }
  }

  &.Schema {
    svg {
      width: 35px;
      height: 35px;
      margin-bottom: 4px;
    }
  }
}

.search-home-header {
  padding: 5rem 0;
  background-color: $gray2;

  .image-and-searchbar {
    display: flex;
    align-items: center;

    .header-image {
      min-width: 126px;
      margin-right: 40px;
      height: 200px;

      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 3.5rem 0;

    .image-and-searchbar {
      flex-wrap: wrap;
      justify-content: center;

      .header-image {
        margin-bottom: 20px;
        margin-right: 0;
        height: 160px;
        min-width: 111px;
      }

      h1 {
        text-align: center;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .search-home .get-started-banner {
    margin-top: 60px;
  }
}
