.solutions-page {
  .solutions-content {
    background-color: white;
    color: black;
    padding: 75px 0;

    @include media-breakpoint-down(xs) {
      padding: 40px 0 60px 0;
    }

    h2 {
      margin-bottom: 45px;
    }

    .solutions-cards {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .skip-the-queue-card {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        width: calc(25% - 15px);
        margin-bottom: 20px;
        background-color: $gray7;
        padding: 30px;

        @include media-breakpoint-between(md, lg) {
          width: 100%;
          height: 160px;
          padding: 40px;
        }

        @include media-breakpoint-down(sm) {
          width: calc(50% - 5px);
          height: 45vw;
          max-height: 270px;
          margin-bottom: 10px;
        }

        @media (max-width: 510px) {
          width: 100%;
          height: calc(100vw - 100px);
          margin-bottom: 10px;
        }

        @media (max-width: 360px) {
          width: calc(100%);
        }

        &:hover {
          background-color: $blue11;
          color: white;

          .normal-text {
            color: white;

            @include media-breakpoint-up(sm) {
              transition: color 1.5s;
            }

            .skip {
              font-style: oblique;
            }
          }
        }

        .heading-3 {
          line-height: 100%;
          font-weight: 600;
        }

        .normal-text {
          color: $blue11;
        }
      }

      .solutions-card {
        position: relative;
        width: calc(25% - 15px);
        padding-top: calc(25% - 15px);
        margin-bottom: 20px;

        @include media-breakpoint-down(lg) {
          width: calc(33.3% - 10px);
          padding-top: calc(33.3% - 10px);
          margin-bottom: 15px;
        }

        @include media-breakpoint-down(sm) {
          width: calc(50% - 5px);
          padding-top: calc(50% - 5px);
          margin-bottom: 10px;
        }

        @media (max-width: 510px) {
          width: calc(100%);
          margin-bottom: 15px;
          height: calc(100vw - 100px);
        }

        img,
        svg {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          right: 0;
        }

        .card-label {
          position: absolute;
          color: white;
          display: flex;
          align-items: center;
          bottom: 0;
          left: 0;
          width: 100%;
          z-index: 2;
          text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.4);
          background-color: rgba(0, 0, 0, 0.66);
          padding: 0 20px;
          height: 70px;
        }

        .hover-label {
          opacity: 0;
          position: absolute;
          background-color: $blue11;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          color: white;
          height: 100%;
          width: 100%;
          padding: 25px 30px;
          font-weight: 400;
          letter-spacing: 0.05rem;
          z-index: 2;

          strong {
            font-weight: 700;
          }

          &:hover {
            opacity: 1;
          }

          @include media-breakpoint-up(sm) {
            transition: opacity 0.2s;
          }

          @include media-breakpoint-down(xs) {
            justify-content: center;
          }
        }
      }
    }
  }

  .request-early-access {
    text-align: center;
    background-color: $gray2;
    padding: 60px 0 70px 0;

    @include media-breakpoint-down(xs) {
      padding: 30px 0 70px 0;
    }

    button {
      margin: 0 auto;
    }
  }
}
