.no-matches {
  font-family: Inter, Helvetica, sans-serif;
  font-size: $font-lg;
  font-weight: 400;
  color: #a0a0a0;
  padding-left: 50px;

  @media (max-width: 1350px) {
    padding-left: 30px;
  }

  @include media-breakpoint-down(md) {
    padding-left: 0;
    margin: 30px 0;
  }
}

.no-matches-header {
  font-size: 32px;
  color: $gray13;
  font-weight: 500;
  margin-bottom: 5px;
}
