.project-issue {

  .issue-header {
    padding-bottom: 15px;
    margin-bottom: 25px;
    border-bottom: 1px solid #ededed;
  }

  .issue-title {
    font-size: $font-lg;
    font-weight: 400;
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    .issue-id {
      font-weight: 300;
      color: #888;
      margin-left: 10px;
    }
  }

  .issue-status {
    display: flex;
    align-items: center;
    font-size: $font-sm;
    color: #888;
  }

  .issue-author-link { 
    margin-right: 5px;
    font-weight: 600;
    transition: color 0.2s;

    a {
      transition: color 0.2s;

      &:hover {
        color: $blue11;
      }
    }
  }

  .issue-state-badge {
    display: flex;
    align-items: center;
    padding: 3px 10px 3px 5px;
    border-radius: 12px;
    color: white;
    font-size: $font-xs;
    margin-right: 10px;
    
    &.opened {
      background-color: $green;
    }
    &.closed {
      background-color: #888;
    }
    &.merged {
      background-color: #6f42c1;
    }

    svg {
      height: 20px;
      width: 20px;

      margin-right: 2px;
      path {
        fill: white;
      }
    }
  }
}

@media screen and (max-width: 470px) {

  .project-issue {
    .issue-status {
      flex-wrap: wrap;

      .issue-author-link {
        margin-right: 0;
      }

      a {
        margin: 0;
      }

      div:nth-child(2) {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
  
}