.project-issues {
  margin-bottom: 30px;

  .issue-buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .dropdown-wrapper {
      width: 130px;
      height: 40px;
    }

    button {
      font-size: $font-sm;
      font-weight: 600;
    }
  }
}

.issue-label-badge {
   border-radius: 12px;
   margin-left: 8px;
   color: white !important;
   padding: 2px 7px;
   font-size: $font-xs;
   font-weight: 500;

  &.bug {
    background-color: $red11;
  }

  &.question {
    background-color: $blue8;
  }

  &.request {
    background-color: $gray13;
  }

  &.comment {
    background-color: $green;
  }
}