// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap-grid.scss";

// diff2html
@import "../../node_modules/diff2html/bundles/css/diff2html.min.css";

// Fonts
@import "./scss/_fonts";

// Normalise
@import "./scss/normalise";

// SCSS Variables
@import "./scss/variables.module.scss";

// CSS Reset
body {
  margin: 0;
  height: 100%;
}

.container {
  max-width: 1170px;
}

@include media-breakpoint-down(lg) {
  .container {
    max-width: 950px;
  }
}

@include media-breakpoint-down(md) {
  .container {
    max-width: 767px;
  }
}

@include media-breakpoint-down(sm) {
  .container {
    max-width: 570px;
  }
}

@include media-breakpoint-down(xs) {
  .container {
    max-width: 100%;
  }
}

.error-message {
  text-align: center;
  background: red;
  color: $white;
  border-radius: 5px;
}
.success-message {
  text-align: center;
  background: green;
  color: $white;
  border-radius: 5px;
}
.testautoprefix {
  display: grid;
  display: flex;
}

// Ensure that nextjs content is 100% of page

html {
  height: 100%;
  scroll-behavior: smooth;
}
body {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
}

.page {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
}

#__next {
  position: relative;
  // min-height: 100vh;
  min-width: 100%;
  // padding-bottom: 600px;

  @include media-breakpoint-down(md) {
    // padding-bottom: 685px;
  }

  @include media-breakpoint-down(xs) {
    // padding-bottom: 910px;
  }
}

.content-min-height {
  min-height: calc(100vh - 574px - 95px);

  @include media-breakpoint-down(md) {
    min-height: calc(100vh - 658px - 95px);
  }

  @include media-breakpoint-down(xs) {
    min-height: calc(100vh - 883px - 95px);
  }
}

.main-content {
  > :first-child {
    padding-top: 2rem;
  }
  > :last-child {
    padding-bottom: 2rem;
  }
}

// Main
@import "./scss/General";

// New Design System
@import "./scss/Typography";
@import "./scss/Inputs";
@import "./scss/Buttons";

// Components
@import "./scss/components/Banners";
@import "./scss/components/Card";
@import "./scss/components/Checkbox";
@import "./scss/components/ContactForm";
@import "./scss/components/CopyButton";
@import "./scss/components/DataTable";
@import "./scss/components/FeaturedProjectsCarousel";
@import "./scss/components/FileDropzone";
@import "./scss/components/SimulationSignup";
@import "./scss/components/InputPopup";
@import "./scss/components/LoadingIndicator";
@import "./scss/components/Logo";
@import "./scss/components/Overlay";
@import "./scss/components/ProgressIndicator";
@import "./scss/components/RadioSelect";
@import "./scss/components/SimpleDropdown";

// hSite Pages
@import "./scss/components/sales/CompetitorCallout";
@import "./scss/components/sales/CompetitorComparison";
@import "./scss/components/sales/CompetitorFooterBanner";
@import "./scss/components/sales/EarlyAccessForm";
@import "./scss/components/sales/EarlyAccessWindow";
@import "./scss/components/sales/PlatformCTA";
@import "./scss/components/sales/PlatformExploreBanner";
@import "./scss/components/sales/UsingHASHBanner";
@import "./scss/pages/AboutPage";
@import "./scss/pages/BlogHome";
@import "./scss/pages/CareersPage";
@import "./scss/pages/CompetitorPage";
@import "./scss/pages/Contributors";
@import "./scss/pages/ContactPage";
@import "./scss/pages/CoronavirusPage";
@import "./scss/pages/JobListingPage";
@import "./scss/pages/LeadershipBioPage";
@import "./scss/pages/LoginPage";
@import "./scss/pages/NotFoundPage";
@import "./scss/pages/Platform";
@import "./scss/pages/PostPage";
@import "./scss/pages/Pricing";
@import "./scss/pages/PrivacyPage";
@import "./scss/pages/RoadmapPage";
@import "./scss/pages/Solutions";
@import "./scss/pages/UnsupportedPage";
@import "./scss/pages/UpdatesPage";
@import "./scss/pages/UseCase";
@import "./scss/pages/GlossaryHome";
@import "./scss/pages/GlossaryTerm";
@import "./scss/pages/wpPage";

// hIndex
@import "./scss//components/index/NewProject";
@import "./scss/components/index/CreateDataset";
@import "./scss/components/index/CreateRelease";
@import "./scss/components/index/GetStartedBanner";
@import "./scss/components/index/LanguageSplitBars";
@import "./scss/components/index/NoMatches";
@import "./scss/components/index/Profile";
@import "./scss/components/index/ProfileHeader";
@import "./scss/components/index/ProfileListingCard";
@import "./scss/components/index/ProfileListingCards";
@import "./scss/components/index/ProfileMain";
@import "./scss/components/index/ProfileSidebar";
@import "./scss/components/index/ProjectChangelog";
@import "./scss/components/index/ProjectDatasetPreview";
@import "./scss/components/index/ProjectHeader";
@import "./scss/components/index/ProjectItemTable";
@import "./scss/components/index/ProjectLicense";
@import "./scss/components/index/ProjectMain";
@import "./scss/components/index/ProjectMetadata";
@import "./scss/components/index/ProjectOverview";
@import "./scss/components/index/ProjectOwnerDropdown";
@import "./scss/components/index/ProjectPage";
@import "./scss/components/index/ProjectSource";
@import "./scss/components/index/_ProjectContributors.scss";
@import "./scss/components/index/Schemas";
@import "./scss/components/index/SearchBar";
@import "./scss/components/index/SearchResultCard";
@import "./scss/components/index/SearchResults";
@import "./scss/components/index/SearchResultsSidebar";
@import "./scss/components/index/SortDropdown";
@import "./scss/components/index/UpdateProject";
@import "./scss/pages/IndexHome";

// Issues
@import "./scss/components/index/issues/Issue";
@import "./scss/components/index/issues/Issues";
@import "./scss/components/index/issues/IssueLink";

// Merge Requests
@import "./scss/components/index/mergeRequests/_MergeRequest";
@import "./scss/components/index/mergeRequests/_MergeRequestActions";
@import "./scss/components/index/mergeRequests/_MergeRequestActivity";
@import "./scss/components/index/mergeRequests/_MergeRequestLink";
@import "./scss/components/index/mergeRequests/_MergeRequestNotifications";

// Discussions
@import "./scss/components/index/discussions/AwardEmoji";
@import "./scss/components/index/discussions/Note";
@import "./scss/components/index/discussions/NewNote";

// hAccount
@import "./scss/components/account/AvatarEdit";
@import "./scss/components/account/Billing";
@import "./scss/components/account/CreateOrg";
@import "./scss//components//account/Integrations";
@import "./scss/components/account/InvoiceTable";
@import "./scss/components/account/MakePayment";
@import "./scss/components/account/OrgProfileEdit";
@import "./scss/components/account/OrgUsersEdit";
@import "./scss/components/account/PaymentMethod";
@import "./scss/components/account/PrivateUserInfoEdit";
@import "./scss/components/account/PublicUserInfoEdit";
@import "./scss/components/account/ShortnameEdit";
@import "./scss/components/account/UserOrgs";
@import "./scss/components/account/UserProfileEdit";
@import "./scss/pages/AccountPage";

// hAdmin Pages
@import "./scss/components/admin/AddGitHubScraper";
@import "./scss/components/admin/Datasets";
@import "./scss/components/admin/Orgs";
@import "./scss/components/admin/Sources";
@import "./scss/components/admin/ScrapedDataset";
@import "./scss/components/admin/Users";
@import "./scss/pages/AdminPage";

// Layouts

@import "./scss/layout/AccountDropdown";
@import "./scss/layout/Footer";
@import "./scss/layout/NavHeader";
@import "./scss/layout/SideBar";

// Misc
@import "./scss/Animations";
@import "./scss/_Prism";
@import "./scss/Markdown";
@import "./scss/components/PhoneInput";
@import "./scss/components/ReactSelect";
@import "./scss/components/DiscordWidget";
@import "./scss/components/Tooltip";
@import "./scss/components/TooltipInfoIcon";
@import "./scss/nprogress";
@import "./scss/pages/ErrorsPage";

// hash
@import "./scss/pages/HashHome";
