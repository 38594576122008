.subject-dropdown {
  div.react-select-container div.react-select__menu {
    font-size: $font-xs;
    width: 340px;
    min-width: 100%;
  }
}

.search-results-sidebar .subject-dropdown {
  div.react-select-container div.react-select__menu {
    width: 240px;
  }
}

div.react-select-container {
  font-family: Inter, Helvetica, sans-serif;
  width: 100%;

  div.react-select__single-value {
    display: flex;
    align-items: center;
  }

  div.react-select__control {
    color: $gray13;
    border: 1px solid $gray8;
    background-color: $gray2;
    transition: background-color 0.3s;

    &:hover {
      background-color: white;
    }
  }

  div.react-select__control--is-focused {
    box-shadow: 0px 5px 20px rgba(95, 71, 255, 0.05);
    transition: box-shadow 0.3s;
    background-color: white;

    &:hover {
      background-color: white;
    }
  }

  div.react_select__value-container {
    padding: 0 17px;
  }

  &.dark {
    div.react-select__control {
      border: 1px solid rgba(219, 224, 227, 0.3);
      color: white;
      background-color: rgba(241, 243, 246, 0.1);

      &:hover {
        background-color: rgba(201, 213, 230, 0.144);
      }

      div.react-select__placeholder {
        color: rgba(255, 255, 255, 0.5);
      }

      div.react-select__single-value {
        color: white;
      }

      div.react-select__input {
        color: white;
      }
    }

    div.react-select__control--is-focuesd {
      box-shadow: none;
    }

    div.react-select__multi-value {
      background-color: black;
    }

    div.react-select__multi-value__label {
      color: white;
    }

    div.react-select__menu {
      background-color: black;
      color: rgb(220, 220, 220);
      border: 1px solid rgba(219, 224, 227, 0.3);
    }

    div.react-select__option--is-focused {
      background-color: $gray13;
    }
  }
}

div.react-select-container-noborder {
  width: 100%;

  div.react-select__control {
    border: none;
    background: none;
  }

  div.react-select__control--is-focused {
    box-shadow: none;
  }

  div.react_select__value-container {
    padding: 0 17px 0 0;
  }
}

div.react-select__control {
  border-radius: 6px;
  font-size: $font-sm;
  line-height: $leading-base;
  text-transform: none;
  height: auto;
  transition: all 0.2s;

  div.react-select__placeholder {
    font-weight: 300;
    padding-left: 2px;
    color: rgba(27, 29, 36, 0.5);
  }
}

div.react-select__menu {
  z-index: 3;
  font-size: $font-sm;

  .sub-label {
    font-size: $font-xs;
    color: rgb(140, 140, 140);
    margin-top: 2px;
    line-height: $leading-snug;
  }

  div.react-select__option {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  div.react-select__option--is-selected .sub-label {
    color: rgb(230, 230, 230);
  }

  div.react-select__option--is-selected {
    background-color: $blue11;
  }
}

div.react-select__value-container {
  align-items: flex-start;
  padding: 2px 8px 2px 16px;
}

div.react-select__placeholder {
  color: $gray5;
}

span.react-select__indicator-separator {
  display: none;
}

.dropdown-indicator {
  margin-right: 15px;

  svg {
    margin-bottom: 2px;
    width: 10px;
    height: 5px;
    fill: #86888d;
  }
}

div.react-select__multi-value__remove {
  transition: all 0.2s;

  &:hover {
    background: red;

    svg {
      fill: white;
      transition: fill 0.2s;
    }
  }
}

div.react-select__input {
  padding-left: 3px;

  input {
    width: 100% !important;
    box-shadow: none !important;
  }
}
