.search-result-link {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .search-result-avatar {
    height: 135px;
    width: 135px;
    min-width: 135px;
    margin-right: 40px;

    .MuiSkeleton-text {
      transform: inherit;
    }

    @include media-breakpoint-down(sm) {
      height: 100px;
      width: 100px;
      min-width: 100px;
      margin-right: 25px;
    }

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
}

.search-result-card {
  width: 100%;
  padding: 20px 30px;
  border: none;
  font-family: Inter, Helvetica, sans-serif;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 0;
  display: flex;
  align-items: center;
  user-select: none;

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.1);
    }
  }

  @include media-breakpoint-up(sm) {
    &:hover {
      .search-result-title {
        color: #5c5c5c;
        transition: color 0.2s;
      }

      .search-result-desc {
        opacity: 0.7;
      }

      .search-result-category {
        color: #5c5c5c;
        transition: color 0.2s;
      }
    }
  }

  .search-result-metadata {
    font-weight: 400;
    font-size: $font-xs;
    transition: color 0.2s;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 4px;

    .search-result-shortname {
      font-weight: 600;
      color: $blue11;
      font-size: $font-xs;
    }

    .type {
      margin-left: 10px;
      text-transform: uppercase;
    }

    .fork-count,
    .dependents-count,
    .private-indicator,
    .star-badge {
      display: flex;
      align-items: center;
      margin-left: 8px;
    }

    .fork-count,
    .dependents-count,
    .star-badge {
      svg {
        margin-top: 1px;
        opacity: 0.35;
        height: 18px;
        width: 18px;
      }
    }

    .star-badge svg {
      margin-top: -1px;
    }

    .private-indicator {
      font-weight: 600;
      color: $blue11;

      svg {
        height: 18px;
        width: 18px;

        path {
          fill: $blue11;
        }
      }
    }
  }

  .search-result-title {
    font-weight: 600;
    font-size: $font-base;
    line-height: $leading-snug;
  }

  .search-result-desc {
    font-weight: 300;
    width: 100%;
    margin-top: 10px;
    transition: opacity 0.2s;
  }

  @include media-breakpoint-only(sm) {
    padding: 25px;

    .search-result-category {
      margin-top: 4px;
    }

    .search-result-desc {
      margin-top: 10px;
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 30px;
    min-height: 120px;
    margin-top: 15px;
    border: 1px solid #e6e6e6;

    .search-result-metadata {
      .search-result-shortname {
        width: 100%;
      }

      .type {
        margin: 10px 0;
      }
    }
  }
}

.search-result-card-loading {
  width: 100%;
  padding: 20px 30px;
  border: none;
  font-family: Inter, Helvetica, sans-serif;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 0;
  display: flex;
  align-items: center;
  user-select: none;

  .search-result-metadata {
    font-weight: 400;
    font-size: $font-xs;
    transition: color 0.2s;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 4px;

    .search-result-shortname-loading {
      width: 60%;
    }

    .type {
      margin-left: 10px;
      text-transform: uppercase;
    }

    .fork-count,
    .dependents-count,
    .private-indicator,
    .star-badge {
      display: flex;
      align-items: center;
      margin-left: 8px;
    }

    .fork-count,
    .dependents-count,
    .star-badge {
      svg {
        margin-top: 1px;
        opacity: 0.35;
        height: 18px;
        width: 18px;
      }
    }

    .star-badge svg {
      margin-top: -1px;
    }

    .private-indicator {
      font-weight: 600;
      color: $blue11;

      svg {
        height: 18px;
        width: 18px;

        path {
          fill: $blue11;
        }
      }
    }
  }

  .search-result-title-loading {
    font-weight: 600;
    font-size: $font-base;
    line-height: $leading-snug;
    width: 30%;
  }

  .search-result-desc {
    font-weight: 300;
    width: 60%;
    margin-top: 10px;
    transition: opacity 0.2s;
  }

  @include media-breakpoint-only(sm) {
    padding: 25px;

    .search-result-category {
      margin-top: 4px;
    }

    .search-result-desc {
      margin-top: 10px;
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 30px;
    min-height: 120px;
    margin-top: 15px;
    border: 1px solid #e6e6e6;

    .search-result-metadata {
      .search-result-shortname {
        width: 100%;
      }

      .type {
        margin: 10px 0;
      }
    }
  }
}

.search-result-card-content-loading {
  width: 100%;
}
