.account-page-wrapper {
  position: relative;

  .left-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background-color: $gray2;
  }

  .right-bg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: white;
  }

  @include media-breakpoint-down(sm) {
    .left-bg {
      display: none;
    }
  }
}

.account-page-content,
#admin-page {
  display: flex;
  font-family: Inter, Helvetica, sans-serif;

  .account-section {
    flex-grow: 1;
    padding: 20px 0 60px 50px;
    background-color: white;
    z-index: 1;

    @include media-breakpoint-down(md) {
      padding: 15px 0 60px 40px;
    }

    @include media-breakpoint-down(sm) {
      padding: 15px 0 50px 0;
    }
  }

  .account-section-header {
    font-size: $font-lg;
    font-weight: 700;
    line-height: $leading-snug;
    color: black;
    margin-left: 10px;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .account-header-link {
      &:hover {
        color: $blue11;
        transition: color 0.2s;
      }
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 30px;
      display: block;
    }

    .light-title {
      font-size: $font-lg;
      letter-spacing: 0.05rem;
      font-weight: 400;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }

    svg,
    img {
      width: 21px;
      height: 21px;
      margin-left: 12px;
      object-fit: cover;
    }

    @include media-breakpoint-down(xs) {
      svg {
        margin-left: 0;
      }

      .light-title svg {
        margin-left: 12px;
      }
    }
  }

  .account-section-tab-header {
    margin-left: 10px;
  }

  .account-section-tab-header span {
    padding: 16px;
    color: $gray11;
    text-transform: uppercase;
    font-size: $font-xs;
    font-weight: 600;
    display: inline-block;
    background-color: white;
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-bottom: 1px solid $gray6;

    &.active {
      background-color: $gray1;
      border: 1px solid $gray6;
      border-bottom: 1px solid $gray6;
      border-radius: 6px 6px 0 0;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.9s;
      color: $blue11;
      transition: all 0.2s;
    }
  }

  .account-section-box {
    width: 100%;
    border: 1px solid $gray6;
    background: $gray1;
    border-radius: 16px;
    padding: 50px;

    @include media-breakpoint-down(md) {
      padding: 30px;
    }
  }

  .input-with-label {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;

    label,
    input,
    textarea {
      width: 100%;
    }

    input,
    textarea {
      margin-top: 5px;
    }

    textarea {
      resize: none;
    }
  }
}

.account-page-content label {
  color: $color-primary;
  text-transform: uppercase;
  font-size: $font-xs;
  font-weight: 600;

  span.constraint {
    color: rgb(134, 135, 141);
    font-size: $font-xs;
    font-weight: 400;
    padding-left: 5px;
  }
}

.profile-section-header {
  margin-bottom: 10px;
  margin-left: 10px;

  span.light-text {
    font-weight: 300;
    font-size: $font-sm;
    color: #6c6e78;
    margin-left: 10px;
  }
}
