#admin-page {
  margin-left: 0;
  margin-right: 0;
  display: flex;
  background: black;
  padding-left: 13rem;
  transition: all 0.4s ease 0s;

  #admin-sidebar {
    position: absolute;
    left: 0;
    width: 13rem;
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 2rem;

    h5,
    p {
      color: $white;
    }

    a {
      display: flex;
      align-items: center;
      opacity: 0.5;
      transition: opacity 0.2s;
      margin: 8px 0;

      svg {
        height: 22px;
        width: 22px;
        margin-right: 1rem;
        fill: $white;

        path,
        polygon {
          fill: $white;
        }
      }

      p {
        font-weight: 600;
        margin: 0;
      }

      &:hover {
        opacity: 0.75;
        transition: opacity 0.2s;
      }
    }
  }

  #panel {
    position: relative;
    background-color: $gray3;
    width: 100%;
    min-height: 400px;

    .orgs-panel,
    .users-panel {
      padding: 2rem 0;
    }

    // CSSTransition for Create User in Admin Page -->>
    .admin-create-user {
      position: absolute;
      top: 4rem;
      right: 15px;
      padding: 1rem;
      transform-origin: right top;
      box-shadow: 3px 3px 3px rgb(50, 50, 50);
      background: $gray2;
      border-radius: 10px;
      z-index: 1;
      .admin-users-form-loading {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;

        .loading-indicator {
          position: relative;
        }
        button {
          width: 20%;
          margin-left: 1rem;
          padding: 0.75rem;
          height: 100%;
          svg {
            fill: white;
            margin-top: 3px;
            margin-left: 5px;
          }

          &:hover svg {
            fill: black;
          }
        }
      }
      input {
        background: $white;
        padding: 0.7rem;
        margin-left: 1rem;
        margin-top: 1rem;
        vertical-align: auto;
        border-radius: 5px;
      }
    }
    .admin-create-user-fade-enter {
      opacity: 0;
      transform: scale(0);

      &.admin-create-user-fade-enter-active {
        transition: all 200ms linear;
        transform: scale(1);
        opacity: 1;
      }
    }
    .admin-create-user-fade-enter-done,
    .admin-create-user-fade-exit {
      opacity: 1;
      transform: scale(1);

      &.admin-create-user-fade-exit-active {
        transition: all 200ms linear;
        transform: scale(0);
        opacity: 0;
      }
    }
    // <<-- End

    h2 {
      font-size: 1.25rem;
      font-weight: 400;
    }
  }
  .user-name,
  .open-user-edit-admin {
    cursor: pointer;
  }
  .loading-indicator {
    position: absolute;
  }
}
