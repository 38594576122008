.org-users-edit {
  width: 100%;
  position: relative;

  .loading-indicator {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }

  .react-select__indicator {
    display: none;
  }

  .react-select__control {
    width: 95%;
    max-width: 120px;
  }

  div.react-select__value-container {
    padding: 6px;
    height: 38px;
  }

  table.org-users-table {
    width: 100%;
    border-radius: 6px;
    border-collapse: separate;
    border-spacing: 0;
    padding: 0;
    border: 1px solid $gray6;

    tbody tr:first-child td {
      padding-top: 25px;
    }

    th {
      text-align: left;
    }

    th,
    td {
      max-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 15px;

      @include media-breakpoint-down(lg) {
        &.user-avatar {
          display: none;
        }

        &.remove-user,
        &.org-user-permissions {
          max-width: 110px;
        }
      }

      @include media-breakpoint-down(md) {
        &.user-name {
          width: 40%;
        }

        &.org-user-permissions,
        &.remove-user {
          width: 19%;
        }

        &.user-title {
          display: none;
        }
      }

      @media (max-width: 500px) {
        &.org-user-permissions {
          display: none;
        }

        &.remove-user {
          width: 12%;
          min-width: 0;
        }
      }
    }

    th {
      font-size: $font-xs;
      font-weight: bold;
      color: #86878d;
      background-color: $gray2;
    }

    tr {
      border-bottom: 0;
    }

    td {
      font-size: $font-sm;
      font-weight: normal;
      background-color: white;

      @include media-breakpoint-down(xs) {
        font-size: $font-sm;
      }
    }

    td.org-user-permissions {
      overflow: visible;
    }

    .user-avatar {
      width: 10%;
      text-align: center;
    }

    .user-name {
      width: 25%;
      font-weight: bold;
    }

    .user-title {
      width: 30%;
    }

    .remove-user,
    .org-user-permissions {
      width: 20%;
    }

    td.user-title input {
      width: 90%;
      height: 38px;
      max-width: 200px;
    }

    tr.add-org-user-row {
      .add-org-user {
        position: relative;
        padding-bottom: 30px;

        form {
          display: flex;
        }

        button {
          margin-left: 8px;
          height: 38px;
          font-weight: 800;
        }
      }

      input {
        width: 180px;
        height: 38px;
      }

      .error-text {
        position: absolute;
        bottom: 10px;
        left: 20px;
      }

      @include media-breakpoint-down(xs) {
        .row-spacer {
          display: none;
        }
      }
    }

    td.user-avatar {
      img,
      svg {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }

      @include media-breakpoint-down(xs) {
        height: 35px;
        min-width: 40px;

        img,
        svg {
          width: 35px;
          height: 35px;
        }
      }
    }

    .remove-user button {
      border: 0;
      background: 0;
      padding: 0;
      font-weight: bold;
      color: black;

      &:hover {
        opacity: 0.9;
        color: $red11;
        transition: all 0.2s;
      }

      @include media-breakpoint-down(xs) {
        font-size: $font-sm;
      }
    }
  }

  .user-name a:hover {
    opacity: 0.9;
    color: $blue11;
    transition: all 0.2s;
  }

  .user-avatar img:hover {
    opacity: 0.7;
  }
}
