.contact-page {
  .contact-links {
    padding-top: 4rem;

    @include media-breakpoint-down(xs) {
      padding-top: 3rem;
    }

    p {
      font-size: $font-md;

      &:first-child {
        margin-bottom: 40px;
      }
    }
  }

  .thank-you {
    margin-bottom: calc(4rem + 1em);

    @include media-breakpoint-down(xs) {
      margin-bottom: calc(3rem + 1em);
    }
  }

  .cards {
    display: flex;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
    .card {
      flex-basis: 30%;
      @include media-breakpoint-down(sm) {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
  .form-spacing {
    @include media-breakpoint-down(sm) {
      height: calc(695.78px + 2.5rem);
    }
    transition: all 1500ms;
    &.thank-you {
      height: calc(79px + 2.5rem);
      transition: all 1500ms;
    }
    > span > div {
      &.submitted {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 200ms, transform 200ms;
      }
    }
  }
}
