.user-profile-edit {
  .profile-section {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &:first-child {
      margin-bottom: 60px;
    }
  }
}
