.overlay {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  &.hide {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  &.show {
    transition: all 0.3s;
    visibility: visible;
    opacity: 1;
  }

  .yes-no-card {
    width: fit-content;
    background-color: $white;
    display: relative;
    border-radius: 0.5rem;
    padding: 1rem;

    p {
      margin: 0;
    }

    .buttons {
      width: 100%;
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      button {
        padding: 0.5rem 1rem;
      }
    }
  }

  .single-input-card {
    width: 20rem;
    background-color: $white;
    display: relative;
    border-radius: 0.5rem;
    .top-bar {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      svg {
        padding: 0.5rem 0.5rem 0 0;
        width: 1.5rem;
        height: 1.5rem;
        transition: fill 0.3s;
        &:hover {
          cursor: pointer;
          transition: fill 0.3s;
          fill: $hot-pink;
        }
      }
    }

    .content {
      padding: 0 1rem 1rem 1rem;

      p {
        margin: 0;
      }

      .input-row {
        display: flex;
        align-items: center;
        position: relative;

        input {
          width: 100%;
          transition: border-bottom-color 0.3s;
          border-bottom-color: #f1f1f1;

          &:focus {
            transition: border-bottom-color 0.3s;
            border-bottom-color: $purple10;
          }
        }

        svg {
          opacity: 0;
          width: 1rem;
          height: 1rem;
          transition: opacity 0.3s;
          position: absolute;
          right: 0rem;
          top: 0.25rem;
          fill: $purple10;
        }

        &.invalid {
          input {
            border-bottom-color: $hot-pink;
          }

          svg {
            transition: opacity 0.3s;
            opacity: 0;
          }
        }

        &.valid {
          svg {
            cursor: pointer;
            opacity: 1;
          }
        }
      }
    }
  }
}
