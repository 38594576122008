.org-profile.account-section-box {
  padding: 0;
  margin-bottom: 40px;

  .error-text:not(.shortname-error) {
    font-size: $font-sm;
    position: relative;
    padding: 30px 50px 0 50px;

    @include media-breakpoint-down(md) {
      padding: 30px 30px 0 30px;
    }
  }
}

.org-info-edit {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  padding: 50px;

  @include media-breakpoint-down(md) {
    padding: 30px;
  }

  .loading-indicator {
    position: absolute;
    bottom: 16px;
    left: 16px;
  }

  .file-picker {
    display: none;
  }

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    justify-content: center;

    .loading-indicator {
      position: fixed;
      top: 1rem;
      right: 1rem;
      left: auto;
    }
  }

  .org-avatar {
    width: 160px;

    img {
      background-color: $gray12;
      width: 160px;
      height: 160px;
      object-fit: contain;
      border-radius: 0.25rem;
    }

    label {
      border: 0;
      background: 0;
      padding-right: 4px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 100%;
      color: black;
      font-weight: 600;
      margin-top: 6px;

      svg {
        height: 18px;
        margin-right: 4px;
        margin-top: 3px;
      }

      &:hover {
        opacity: 0.9;
        color: $blue11;
        transition: all 0.2s;

        svg path {
          opacity: 0.9;
          fill: $blue11;
          transition: all 0.2s;
        }
      }
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 160px;
      margin-bottom: 20px;
    }
  }

  .org-profile-text-inputs {
    flex-grow: 1;
    margin-left: 30px;

    @include media-breakpoint-down(md) {
      margin-left: 0;
      width: 100%;
    }

    .org-profile-name-edit {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    textarea {
      height: 110px;
    }

    .checkbox {
      margin-top: 8px;

      .text {
        text-transform: none;
        font-size: $font-sm;
        margin-bottom: 1px;
        font-weight: 300;
      }
    }
    .parentsorg-edit {
      margin-bottom: 2rem;

      .react-select__control {
        height: 48px;
      }

      .parentsorg-label {
        margin-bottom: 0.2rem;
      }
    }
  }
}
