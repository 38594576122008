.platform-page {
  .mainsite-header {
    .large-text {
      font-size: 24px;
    }
    &.with-image > .container {
      display: flex;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }

      .header-image {
        img {
          height: 100%;
          object-fit: contain;
        }
      }

      .header-text {
        width: 100%;
      }
    }
  }

  &.engine {
    .mainsite-header {
      @include media-breakpoint-up(xl) {
        padding: 40px 0 20px 0;
      }

      h1 {
        margin-right: 30px;
      }

      @include media-breakpoint-up(md) {
        > .container {
          padding-left: 5px;
        }
      }
    }

    .header-image {
      height: 180px;
      min-width: 140px;
      margin-right: 10px;
    }

    @include media-breakpoint-down(sm) {
      .header-image {
        height: 150px;
        min-width: 116px;
        margin-right: 0;
      }
    }
  }

  &.cloud {
    .mainsite-header {
      @include media-breakpoint-up(xl) {
        padding: 40px 0 20px 0;
      }
    }
    .header-image {
      min-width: 174px;
      height: 200px;
      margin-right: 20px;
    }
    .header-text {
      position: relative;
      top: 20px;
    }

    @include media-breakpoint-down(lg) {
      .header-text {
        top: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      .header-image {
        min-width: 130px;
        height: 150px;
        margin-right: 0;
      }
    }
  }

  &.index {
    .header-image {
      min-width: 88px;
      height: 140px;
      margin-right: 30px;
    }

    @include media-breakpoint-down(sm) {
      .header-image {
        min-width: 76px;
        height: 120px;
        margin-right: 0;
      }
    }
  }

  &.core {
    .header-image {
      min-width: 126px;
      height: 140px;
      margin-right: 30px;
    }

    @include media-breakpoint-down(sm) {
      .header-image {
        min-width: 108px;
        height: 120px;
        margin-right: 0;
      }
    }
  }

  &.platform-overview .platform-content > .container {
    padding-bottom: 3rem;
  }

  .how-it-works {
    margin-bottom: 5rem;

    h3 {
      display: flex;
      flex-wrap: wrap;
    }

    .platform-diagram {
      img {
        object-fit: contain;
        margin: auto;
        width: 100%;

        &.diagram-full {
          display: block;
          max-width: 1440px;
          padding: 0 30px;

          @include media-breakpoint-down(lg) {
            display: none;
          }
        }

        &.diagram-medium {
          display: none;

          @include media-breakpoint-between(md, lg) {
            display: block;
          }
        }

        &.diagram-responsive {
          display: none;

          @include media-breakpoint-down(sm) {
            display: block;
          }
        }
      }
    }
  }

  h3 {
    margin-top: 0;
    font-size: 26px;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(xs) {
      margin-bottom: 25px;
    }

    span.light {
      font-weight: 300;
    }

    div {
      display: flex;
      align-items: flex-end;
    }

    svg {
      width: 35px;
      height: 35px;
      fill: $gray7;
      margin-left: 8px;

      path {
        fill: $gray7;
      }
    }
  }

  .platform-content {
    color: black;
    background-color: white;
    padding-top: 5rem;

    @include media-breakpoint-down(xs) {
      padding-top: 2.5rem;
    }

    .container-title {
      font-style: italic;
      font-weight: 300;
      font-size: 38px;
      line-height: 100%;
      width: 60%;
      font-weight: 200;
      margin-top: 0px;
      margin-bottom: 5rem;

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: 2.5rem;
      }
    }

    h2.para-title {
      font-size: 42px;
      font-weight: 300;
      margin-top: 0;
    }

    .paras {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > .para {
        font-size: $font-base;
        margin-bottom: 50px;
        width: 30%;

        @include media-breakpoint-down(md) {
          width: 46%;
        }

        @include media-breakpoint-down(sm) {
          width: 100%;
        }

        @include media-breakpoint-down(xs) {
          flex-wrap: wrap;
        }

        h4 {
          margin: 0;
        }
        p {
          margin-top: 0.5rem;
          margin-bottom: 0;
        }
      }
    }

    ul {
      list-style-type: none;
      padding-inline-start: 0;

      li {
        display: flex;
        align-items: center;
        margin: 8px 0;
      }

      .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        margin-right: 10px;

        &.behavior svg {
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  &.platform-overview .paras {
    justify-content: flex-start;

    > .para {
      display: flex;
      align-items: center;
      width: auto;

      &:not(:last-child) {
        margin-bottom: 50px;
      }

      &:first-child,
      &:nth-child(3) {
        margin-right: 50px;

        @include media-breakpoint-down(lg) {
          margin-right: 30px;
        }
      }

      .para-text {
        max-width: 280px;
      }

      .engine-header {
        display: flex;
        align-items: center;

        .open-badge {
          font-size: $font-xs;
          font-weight: 700;
          margin-left: 12px;
          text-transform: uppercase;
          background: linear-gradient(90deg, #2482ff 23.07%, #5f47ff 92.78%);
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      &.engine .para-image {
        min-width: 140px;
        width: 140px;
        margin-right: 30px;
      }

      &.blockprotocol {
        height: 180px;
      }

      &.blockprotocol .para-image svg {
        color: #39444f;
        height: unset;
        min-width: 70px;
        width: 70px;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 15px;
      }

      &.cloud .para-image {
        min-width: 140px;
        width: 140px;
        margin-right: 30px;
      }

      &.core .para-image {
        min-width: 120px;
        width: 120px;
        margin-right: 50px;
      }

      &.index .para-image {
        min-width: 110px;
        width: 110px;
        margin-right: 50px;
        margin-left: 10px;
      }

      img {
        width: 100%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 60px;
        }

        &.index .para-image {
          margin-left: 10px;
        }
      }

      @include media-breakpoint-down(xs) {
        flex-wrap: wrap;

        .para-text {
          width: 100%;
          max-width: 400px;
        }

        &.core .para-image,
        &.engine .para-image,
        &.index .para-image,
        &.cloud .para-image {
          margin: 0 0 15px 0;
        }
      }
    }
  }
}
