.platform-page .page-footer-cta {
  margin-top: 40px;
}

.page-footer-cta {
  padding-bottom: 30px;
  position: relative;
  overflow: hidden;

  &.purple {
    background: url("https://s3.amazonaws.com/cdn-us1.hash.ai/assets/blue-purple-shape-bg.svg")
      top/cover no-repeat;
  }

  &:not(.purple) {
    background: url("https://s3.amazonaws.com/cdn-us1.hash.ai/assets/blue-shape-bg.svg")
      top/cover no-repeat;
  }

  .signup-background-icon {
    position: absolute;
    top: 30px;
    right: 0;
    mix-blend-mode: overlay;
    transform: rotate(12deg);
    height: 100%;

    img {
      height: 100%;
    }

    @include media-breakpoint-down(xs) {
      transform: none;
      max-width: 80vw;
      height: auto;

      img {
        height: auto;
        width: 100%;
      }
    }
  }

  .signup-callout-box {
    z-index: 2;
    background: white;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    position: relative;
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    padding: 70px 30px;
    max-width: 840px;
    margin: 0 auto;

    .top-stripe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 12px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      background: linear-gradient(270deg, #0251bb 0%, #2482ff 100%);
    }

    .signup-title {
      margin: 0;
      font-size: 42px;
      font-weight: 700;
    }

    .signup-subtitle {
      margin-top: 15px;
      font-size: $font-md;
      font-weight: 300;
    }
  }

  .signup-terms {
    text-align: center;
    margin-top: 40px;
    font-weight: 300;
    font-size: $font-sm;
    opacity: 0.8;

    a {
      border: none;

      &:hover {
        color: white;
        border-color: white;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .signup-callout-box {
      padding: 70px 15px;

      form.early-access {
        width: 100%;
      }

      .signup-title {
        font-size: 36px;
      }
    }
  }

  &.index {
    margin-top: 0;

    .signup-background-icon {
      top: 80px;
      right: 60px;
    }

    h3.heading-4 {
      padding-top: 240px;
      color: white;
      margin-bottom: 20px;
    }

    .homepage-type-cards {
      padding-bottom: 120px;

      .homepage-type-card {
        background: linear-gradient(
          180deg,
          rgba(46, 46, 46, 0.795) 0%,
          rgba(88, 44, 66, 0.877) 100%
        );
        box-shadow: 0px 4px 20px rgba(0, 178, 255, 0.28);
        mix-blend-mode: hard-light;
        color: white;
        font-weight: 500;
        border: none;
        transition: none;

        svg {
          fill: white;
          transition: none;

          path {
            fill: white;
            transition: none;
          }
        }

        &:hover {
          background: white;
          color: $blue11;
          transition: background 0.2s, color 0.2s;

          svg {
            fill: $blue11;
            path,
            polygon {
              fill: $blue11;
            }
          }
        }
      }
    }
  }
}
