.sidebar-container {
  width: 220px;
  padding-top: 55px;
  background-color: $gray2;
  display: flex;
  flex-wrap: wrap;
  font-family: Inter, Helvetica, sans-serif;

  &.mobile {
    width: 100%;
    max-width: 100%;
    padding: 20px 0 40px 20px;
  }

  @include media-breakpoint-down(lg) {
    &:not(.mobile) {
      width: 200px;
    }
  }

  @include media-breakpoint-down(sm) {
    width: 260px;
    max-width: 80vw;
    padding-left: 40px;
  }

  .MuiPaper-root {
    width: 100%;
    background-color: $gray2 !important;
  }
}

.desktop-drawer {
  flex-shrink: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.12);

  @include media-breakpoint-down(sm) {
    display: none;
  }

  .MuiDrawer-paper {
    z-index: 5;
  }
}

.drawer-paper {
  top: auto !important;
  height: auto !important;
  position: relative !important;
  min-height: calc(100% - 15rem);
  border-right: 0 !important;
}

.close-icon-wrapper {
  width: 100%;
  max-width: calc(100% - 20px);
  display: flex;
  justify-content: flex-end;

  .close-icon {
    color: #060f2c;
    width: 20px;
    height: 20px;
    margin-bottom: 20px;
  }
}

.sidebar-section {
  margin-bottom: 30px;
  width: 100%;

  .sidebar-section-header {
    margin-top: 0;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    max-width: calc(100% - 20px);

    h3 {
      font-size: $font-xs;
      line-height: $leading-snug;
      font-weight: 600;
      text-transform: uppercase;
      color: #b1b2b4;
      display: inline-block;
      margin: 0;

      display: flex;
      align-items: center;
    }

    .header-badge {
      display: inline-block;
      font-size: $font-xs;
      font-weight: 600;
      margin-left: 10px;
      padding: 4px;
      line-height: $leading-snug;
      border-radius: 4px;
      background-color: black;
      color: white;
    }
  }

  .sidebar-section-link-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-weight: bold;
    font-size: $font-xs;
    line-height: $leading-snug;
    color: $color-primary;
    transition: color 0.2s opacity 0.2s;
    margin-top: 15px;
    margin-bottom: 15px;

    &:nth-child(1) {
      margin-top: 27px;
    }
  }

  .sidebar-divider {
    border-top: 1px solid #b6bcbf;
    margin-right: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .sidebar-link-item,
  .sidebar-link-title {
    margin-bottom: 0.5em;
    margin-right: 1em;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &.active {
      color: $blue11;
      font-weight: 500;
    }
  }

  .sidebar-link-item {
    font-size: $font-xs;
    padding: 10px 0;
    margin-bottom: 0px;
  }

  .sidebar-link-title {
    margin-bottom: 1em;
    justify-content: initial;
  }

  .sidebar-link {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-weight: bold;
    font-size: $font-xs;
    line-height: $leading-snug;
    color: $color-primary;
    transition: color 0.2s opacity 0.2s;
    padding: 12px;

    &.active {
      border-right: 5px solid $blue11;
      background-color: white;
    }

    &:hover {
      opacity: 0.8;
      color: $blue11;

      svg {
        opacity: 0.7;
      }
    }

    svg {
      width: 18px;
      height: 18px;
      margin-right: 17px;
      margin-left: 2px;
      transition: opacity 0.2s;
      margin-top: 2px;
    }

    &.Log-out-link,
    &.Sign-up-link,
    &.Solutions-link {
      padding: 8px 12px 8px 10px;

      svg {
        height: 26px;
        width: 26px;
        margin-right: 13px;
        margin-left: 0;
      }
    }

    &.Account-link {
      svg {
        height: 17px;
        width: 17px;
        margin-left: 2px;
        margin-right: 18px;
      }
    }

    &.Sign-in-link {
      padding: 8px 12px 8px 7px;

      svg {
        height: 26px;
        width: 26px;
        margin-right: 14px;
      }
    }

    &.Drive-link {
      padding: 12px;

      svg {
        height: 18px;
        width: 18px;
        margin-right: 18px;
        margin-left: 2px;
      }
    }

    &.hCore-link,
    &.Platform-link {
      padding: 6px 12px 6px 7px;

      svg {
        height: 31px;
        width: 31px;
        margin-right: 9px;
        margin-top: 1px;
      }
    }

    &.Index-link {
      svg {
        margin-right: 16px;
        height: 20px;
        width: 20px;
      }
    }
  }

  button {
    outline: 0;
    background: none;
    border: 0;
    padding: 0;
  }

  &.logout {
    padding-top: 10px;
  }
}
