table.project-items {
  border: 1px solid $gray6;
  border-spacing: 0;
  table-layout: auto;
  width: auto;
  max-width: 100%;
  font-size: $font-sm;
  margin-bottom: 40px;
  border-radius: 8px;

  thead {
    background-color: $gray3;
  }

  thead,
  thead tr {
    border-radius: 8px 8px 0 0;

    th:first-child {
      border-top-left-radius: 8px;
    }

    th:last-child {
      border-top-right-radius: 8px;
    }
  }

  tr:not(:last-child) td {
    border-bottom: 1px solid $gray6;
  }

  th {
    padding: 15px 20px;
    font-weight: 600;
  }

  td {
    padding: 12px 20px;
    font-size: $font-xs;
    letter-spacing: 0.05rem;
  }

  th,
  td {
    text-align: left;
  }

  th.size-col {
    width: 110px;
  }

  th.item-col {
    width: 130px;
  }

  td.shortname-col {
    display: flex;
    align-items: center;

    button {
      margin-left: 6px;
    }
  }

  @include media-breakpoint-down(md) {
    max-width: calc(100vw - 30px);
  }
}
