#admin-page .scraped-dataset {
  .input-with-label label,
  label {
    color: white;
  }
}

.scraped-dataset {
  margin-bottom: 60px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.25);
  color: white;
  padding: 30px;
  max-width: 1200px;
  font-size: $font-sm;

  button[type="submit"] {
    padding: 0 35px;
  }

  .input-with-label:not(.keywords):not(.subjects) .dropdown-wrapper,
  .input:not(textarea),
  .input-with-label:not(.keywords):not(.subjects) div.react-select__control {
    height: 45px;
  }

  .input-with-label.keywords,
  .input-with-label.subjects {
    div.react-select__control,
    .dropdown-wrapper {
      min-height: 45px;
    }
  }

  .scraped-dataset-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    .input.heading-4 {
      font-size: $font-lg;
      width: 100%;
      height: 60px;
    }

    .scraped-dataset-title {
      width: calc(100% - 400px);
    }

    .status {
      color: $orange7;
      font-size: $font-xs;
      font-weight: 500;
      margin-bottom: 10px;

      &.published .text-link {
        color: $green11;
      }

      .source-url {
        color: white;
      }
    }
  }

  .scraped-dataset-controls {
    display: flex;
    position: relative;

    .loading-indicator {
      right: 0;
    }

    .secondary-button {
      width: 140px;
      font-weight: 600;
    }

    button.ignore {
      background-color: $red11;
      margin-right: 30px;

      &:hover {
        background-color: $red11;
        box-shadow: 0px 10px 15px rgba($red11, 0.25);
      }
    }
  }

  .dates {
    margin: 20px 0;
  }

  .checkbox {
    margin: 20px 0 15px 0;

    .text {
      font-size: $font-xs;
      font-weight: 500;
    }
  }

  .divider {
    width: 100%;
    margin-top: 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }

  textarea {
    height: 200px;
    width: 100%;
    resize: none;
  }

  .info-fields {
    margin-top: 20px;

    div:not(:first-child) {
      margin-top: 15px;
    }
  }

  label {
    font-weight: 500;
  }

  .new-publisher {
    &.spread-elements {
      align-items: flex-start;
    }

    .avatar-edit {
      margin-bottom: 0;

      .avatar-header {
        display: none;
      }

      svg path {
        fill: white;
      }

      label {
        display: flex;
        justify-content: flex-start;
      }
    }
  }

  .input-with-label {
    &:not(:first-child) {
      margin-top: 20px;
    }

    &:not(.textarea) {
      width: 45%;
    }

    .input {
      width: 100%;
    }

    label {
      color: white;
      padding-bottom: 8px;
      display: block;
      font-size: $font-xs;

      &.disabled {
        color: rgba(255, 255, 255, 0.25);
      }
    }
  }

  .spread-elements {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .input-with-label {
      margin: 0;

      &:not(:first-child),
      &:not(:nth-child(2)) {
        margin-top: 20px;
      }
    }
  }

  .dataset-resources {
    margin-top: 20px;
  }

  .dataset-resource {
    margin-bottom: 30px;

    .dataset-resource-details {
      display: flex;
      margin-bottom: 10px;
    }

    .url-input {
      margin-bottom: 20px;

      .input {
        width: 80%;
        margin-left: 10px;
      }
    }

    .input-with-label {
      width: 20%;
      margin-left: 50px;

      &.name {
        width: 40%;
      }
    }

    .input,
    .dropdown-wrapper {
      min-width: 180px;
    }
  }
}
