.note {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  > div {
    width: 100%;
  }

  .note-content {
    width: 100%;
    border: 1px solid $gray6;
    border-radius: 8px;
  }

  .note-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $font-sm;
    background-color: $gray2;
    min-height: 35px;
    padding: 5px 5px 5px 15px;
    border-radius: 8px;
    position: relative;

    .edited {
      color: #888;
      margin-left: 5px;
    }

    .note-small-avatar {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      margin-right: 10px;
      border: 1px solid $gray6;
    }

    &:not(.system) {
      @include media-breakpoint-up(md) {
        .note-small-avatar {
          display: none
        }
      }
    }
    
    .note-summary {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-weight: 300;

      .author-link { 
        font-weight: 600;
        
        a {
          transition: color 0.2s;

          &:hover {
            color: $blue11;
          }
        }
      }

      span .markdown-display {
        margin: 0 0.3rem 0 0;
      }
    }

    button {
      background: none;
      padding: 3px;
      border: none;

      svg {
        path {
          transition: fill 0.2s;
          fill: #888;
        }
      }

      &:hover {
        svg path {
          fill: $blue11;
        }
      }
    }
  }

  .system.note-header {
    width: 100%;
    background: white;
    border: 1px solid $gray6;
  }

  .note-body {
    padding: 15px;
    font-size: $font-sm;
  }
}

.note, .new-note {
  .note-big-avatar {
    height: 40px;
    width: 50px;
    margin-right: 10px;

    img {
      height: 40px;
      width: 40px;
      object-fit: cover;
      border-radius: 50%;
      border: 1px solid $gray6;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

}

.new-note {
  display: flex;
  justify-content: space-between;
}

.new-discussion-form {
  .label-input {
    width: 250px;
  }

  .title-input input#title {
    width: 100%;
  }

  .secondary-button {
    font-weight: 600;
    font-size: $font-sm;
  }
}