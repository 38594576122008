.leadership-bio-page {
  .content {
    padding: 4rem 0;

    @include media-breakpoint-down(xs) {
      padding: 15px 0;
    }

    img {
      width: 20rem;
      height: 20rem;
      margin: 0 2rem 1.5rem 0;
      @include media-breakpoint-down(sm) {
        width: 10rem;
        height: 10rem;
      }
      @media (max-width: 360px) {
        width: 100%;
        height: unset;
        margin: 0 0 15px 0;
      }
    }

    .bio-content {
      > :first-child,
      > :first-child > :first-child,
      > :first-child > :first-child > :first-child {
        margin-top: 0;
      }
    }
  }
}
