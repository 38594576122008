.copy-button {
  background: none;
  border: none;
  position: relative;
  padding: 0;

  &:hover {
    filter: brightness(0.2);
    transition: filter 0.2s;
    background: none;
  }

  img {
    height: 16px;
    width: 16px;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    padding: 0;
    opacity: 0;
    border: none;
    outline: none;
    box-shadow: none;
    background: transparent;
  }
}
