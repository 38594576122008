.updates-page .main {
  padding-top: 90px;
  padding-bottom: 40px;

  &.single {
    padding-top: 60px;
  }

  .with-underline {
    .underline {
      border-bottom: 3px solid transparent;
      transition: border-bottom-color 0.2s ease;
    }

    &:hover .underline {
      border-bottom-color: currentColor;
    }
  }

  .updates-back-link {
    font-weight: bold;
    color: $blue;
    margin-bottom: 3em;
  }

  .update {
    margin-bottom: 50px;

    &:not(:first-child) {
      border-top: 1px solid $gray6;
      padding-top: 50px;
    }
  }

  .update-meta {
    line-height: 1.5;
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
      margin-bottom: 8px;

      .date {
        padding-right: 10px;
      }

      .recency {
        padding-left: 0;
      }
    }

    .date {
      font-weight: bold;
    }

    .recency {
      font-style: italic;
    }
  }

  .update-content {
    > *:not(hr) {
      margin: 0;
    }

    .post-title {
      :first-child {
        margin-top: 0;
      }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: Inter, sans-serif;
      font-weight: bold;
      line-height: 1.3;
      margin-bottom: 0.4em;
      margin-top: 1.25em;
    }

    h2 {
      font-size: 1.5rem;

      &:first-child {
        margin-top: 0;
      }
    }

    h3 {
      font-size: 1.2rem;
    }

    h4 {
      font-size: 1rem;
    }

    h5 {
      font-size: 0.8rem;
    }

    h6 {
      font-size: 0.6rem;
    }

    p,
    .wp-block-code,
    blockquote {
      margin: 1.3em 0;
    }

    .wp-block-code,
    blockquote {
      padding: 1em;
      width: calc(100% + 1em);
      box-sizing: border-box;
    }

    blockquote {
      border-left: 0.3em solid $gray14;
      margin-left: -1.3em;

      :first-child {
        margin-top: 0;
        padding-top: 0;
      }
    }

    .wp-block-code {
      margin-left: -1em;
      background: $gray3;
    }

    ul {
      @extend .check-list;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    .update-heading {
      > * {
        margin: 0;
        padding: 0;
      }
    }
  }
}

.update-page-numbers {
  padding-bottom: 60px;

  .page-numbers {
    justify-content: flex-end;
  }
}
