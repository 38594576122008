.post-page {
  &.with-image .mainsite-header {
    background: linear-gradient(180deg, $gray2 85%, #fff 85%);
    padding-bottom: 0;

    img.featured-image {
      width: 100%;
      object-fit: cover;
      position: relative;
      margin-top: 40px;
      border-radius: 10px;

      @include media-breakpoint-down(xs) {
        margin-top: 20px;
      }
    }
  }

  .blog-post-content {
    display: flex;
    justify-content: space-between;

    .left-col {
      width: 100%;
    }

    .embed-container {
      min-height: 500px;
    }

    .col-placeholder {
      min-width: 200px;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .content {
    padding: 2rem 0 7rem 0;
    @include media-breakpoint-down(sm) {
      padding: 2.5rem 0;
    }
    > :first-child {
      margin-top: 0;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }
}
