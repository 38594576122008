.payment-methods {
  .existing-cards {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    justify-content: space-between;
    padding: 0 0 10px 10px;

    .payment-card {
      margin-bottom: 30px;
    }

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }

  button {
    text-transform: uppercase;
    font-weight: 600;
  }

  .error-text {
    min-height: 16px;
    margin-bottom: 15px;
    margin-top: 4px;
    width: 500px;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }

  .register-card {
    padding: 0 0 0 10px;
    max-width: 340px;

    .small-text {
      margin-bottom: 15px;
    }
  }

  .success {
    padding: 0 10px;

    .normal-text {
      margin-bottom: 15px;
    }
  }
}
