.navheader {
  a.text-link {
    color: $gray13;
    line-height: $leading-base;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    transition: $transition-base;
    &:hover {
      color: $gray14;
      border-bottom: 4px solid $blue5;
    }
  }

  .blue-button {
    border: 2px solid transparent;
    transition: $transition-base;
    padding: $space3 $space4;
    &:hover {
      border: 2px solid $blue11;
    }
  }

  &.IndexHome {
    border-bottom: 0;
  }

  &.Index {
    @include media-breakpoint-up(md) {
      &.user .header-content .searchbar {
        padding-right: 290px;
      }
    }

    .header-content {
      .searchbar {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;

        @include media-breakpoint-down(xs) {
          padding: 0 15px;
        }

        .input:not([disabled]):focus {
          box-shadow: none;
        }
      }

      @include media-breakpoint-down(sm) {
        .left {
          width: auto;
          .text-link {
            display: none;
          }
        }
      }
    }
  }

  &:not(.user) .header-content {
    &.white .image-link {
      height: 26px;
      flex-shrink: 0;
    }

    @include media-breakpoint-up(md) {
      .right {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .image-link .mobile {
    display: none;
  }

  @include media-breakpoint-down(sm) {
    .image-link .mobile {
      display: block;
    }

    .image-link .desktop {
      display: none;
    }

    a.text-link,
    .right {
      display: none;
    }

    button.header-burger {
      display: block;
    }
  }

  button.header-burger {
    outline: 0;
    background: none;
    border: 0;
    padding: 0;
    fill: $gray14;
    transition: $transition-base;
    height: 30px;
    svg {
      height: 20px;
      width: 20px;
      transition: $transition-base;
      &:hover {
        opacity: 0.8;
        fill: $blue11;
      }
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    .hide-below-lg {
      display: none;
    }
  }
}
