.competitor-comparison {
  h2 {
    font-weight: 300;
    font-size: 42px;
  }

  margin-bottom: 100px;
}

.competitor-comparison-table {
  width: 100%;

  .table-row {
    display: flex;
    justify-content: space-between;
    font-size: $font-sm;

    > div {
      padding: 15px 35px;
      width: 33.3%;
      border-left: 1px solid $gray8;

      &:last-child {
        border-right: 1px solid $gray8;
      }
    }
  }

  .header-row,
  .footer-row {
    background: #f5f5f5;
    border-top: 1px solid $gray8;
    border-bottom: 1px solid $gray8;
  }

  .header-row {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    div:first-child {
      border-top-left-radius: 10px;
    }

    div:last-child {
      border-top-right-radius: 10px;
    }
  }

  .footer-row {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    div:first-child {
      border-bottom-left-radius: 10px;
    }

    div:last-child {
      border-bottom-right-radius: 10px;
    }
  }

  .table-row:nth-child(2) > div {
    padding-top: 30px;
  }

  .table-row:nth-last-child(2) .feature-info {
    padding-bottom: 30px;
  }

  .feature-type {
    font-weight: 700;
  }

  .feature-info {
    display: flex;
    align-items: flex-start;

    .feature-icon {
      margin-right: 10px;
    }

    .feature-header {
      font-weight: 700;
      margin: 0 0 2px 0;
      display: flex;
    }

    .feature-description {
      font-size: $font-xs;
      font-weight: 400;
      margin-left: 26px;

      p {
        margin: 0;
        line-height: $leading-base;
      }
    }

    &.included {
      .feature-header {
        color: #2ab967;
      }

      svg path {
        fill: #2ab967;
      }
    }

    &.coming-soon {
      .feature-header {
        color: #0eb3de;
      }

      svg path {
        fill: #0eb3de;
      }
    }

    &.warning {
      .feature-header {
        color: #e49f01;
      }

      svg path {
        fill: #e49f01;
      }
    }

    &.absent {
      .feature-description {
        color: #aaa;
      }

      svg path {
        fill: $gray7;
      }
    }
  }

  button {
    padding-left: 35px;
    padding-right: 25px;
    font-weight: 600;
    font-size: $font-base;
    height: 52px;
  }

  .green-button {
    svg {
      height: 24px;
      width: 24px;
    }
  }

  .book-calendar-button {
    background: #e1e1e1;
    color: black;
    border: 1px solid #d2d2d2;
    font-weight: 700;
    transition: background-color 0.2s;

    svg {
      height: 14px;
      width: 14px;
      margin-left: 12px;
    }

    &:hover {
      background-color: white;
    }
  }

  @include media-breakpoint-down(md) {
    .footer-spacer {
      display: none;
    }

    .footer-row.table-row > div {
      width: auto;
      text-align: center;

      &:last-child {
        border-left: none;
      }
    }
  }

  .mobile-label {
    display: none;
  }

  @include media-breakpoint-down(sm) {
    .mobile-label {
      display: block;
      width: 100%;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .feature-info {
      display: flex;
      flex-wrap: wrap;
    }

    .content-row:nth-child(2) {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      > div:first-child {
        border-top: 1px solid $gray8;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
      }
    }

    .table-row {
      flex-wrap: wrap;

      > div {
        padding: 15px 20px;
        border-right: 1px solid $gray8;
        width: 100%;

        &.feature-type {
          text-align: center;
          border-bottom: 1px solid $gray8;
          background: #f5f5f5;
          font-weight: 400;
        }

        &:nth-child(3) {
          border-bottom: 1px solid $gray8;
        }
      }

      &:nth-last-child(2) .feature-info {
        padding-bottom: 15px;
      }
    }

    .header-row {
      display: none;
    }

    .footer-row {
      border-top: none;
    }

    .footer-row.table-row > div {
      width: 100%;

      &:last-child {
        border-left: 1px solid $gray8;
        border-bottom-left-radius: 10px;
        border-bottom: none;
      }

      button {
        width: 100%;
      }
    }
  }

  @media (max-width: 370px) {
    .desktop-only {
      display: none;
    }
  }
}
